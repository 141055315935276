import React,{ useEffect, useState } from "react";
import {ValidateForgotPassword} from "../../Utils/Validators"
import UseForm from "../../Utils/UserForm";
import { request } from "../../Utils/Request"
import { isAuthenticated } from "../../Utils/Authenticated";
import {Popup, Toast} from "../../Utils/Notifications"
import InputField from "../../components/Inputs/InputField";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import "./Login.css";
import { Link } from "react-router-dom";

const ForgotPassword=(props)=>{
  useEffect(() => {
    if (isAuthenticated()) {
      props.history.push('/')
    }
  }, [])
const {
    handleSubmit,
    handleChange,
    values,
    errors,
    state
  } = UseForm(formSubmit,ValidateForgotPassword)
  const [loader, setLoader] = useState(false)

 async function formSubmit(){
  console.log(values)
try {
  setLoader(true)
const res = await request(
  "post",
  "/forgot_password",
  { email: values.email, base_url: process.env.REACT_APP_FRONT_URL}
)
console.log(res.data)
if(res.data.success=== "yes"){
  await props.history.push('/login')
  return Toast.fire({ title: res.data.message, type:'success' })
}
setLoader(false)
Toast.fire({ title: res.data.message, type:'error' })

}

catch(error){
  console.log(error)
}

  }


  return (
    <div className="ffLogin forgotPswd">
      <div className="ffLoginCnt">
        <Link to="/">
         <img src="/svg/logo.svg" alt="Farm Fayre" height="75" />
        </Link>
        <div className="greetMsg">
          <h2 className="playFair">Forgot Password</h2>
          <p className="playFair">
            Enter your email address <br /> to receive a reset password link
          </p>
        </div>
        <form onSubmit = {handleSubmit}>
          <InputField
            label="Email address"
            placeholder="Enter your email address"
            type = "email"
            name="email"
            error={state}
            helperText={state && errors.email }
            value={values.email}
            onchange= {handleChange}
          />
          <div className="actBtn">
            <PrimaryButton label="SEND RESET PASSWORD LINK"
              type="submit" />
          </div>
        </form>

        <div className="accHelp">
          <p>Already have an account?</p>
          <span>Login</span> <Link to="/login">here</Link>
        </div>
      </div>
      <p className="footerText">© 2023 Farm Fayre. All rights reserved.</p>
    </div>
  );
}
export default ForgotPassword;
