import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "../../components/Inputs/borderedInput";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import { GreenRadio } from "../../components/Inputs/RadioBox";
import CheckBox from "../../components/Inputs/CheckBox";
import Radio from "@material-ui/core/Radio";
import { Player } from "video-react";
import {
  Multiple,
  ValidateMultipleLot,
  ValidatePostSingleLot,
} from "../../Utils/Validators";
import imageCompression from "browser-image-compression";
import {
  Breed,
  IrishCounties,
  Section,
  Type,
  weimethod,
  salemethod,
  NewCounties,
  NumberOnly,
  callNumber,
  dallow,
} from "../../Utils/constant";
import UseForm from "../../Utils/UserForm";
import { request } from "../../Utils/Request";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./post-project.css";
import Upload from "../../components/Upload";
import CustomSelect from "../../components/Inputs/borderedSelect";
import Layout from "../../components/Layout";
import { FormHelperText } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import DatePicker from "../../Utils/DatePicker";
import JwtDecode from "jwt-decode";
import { Toast } from "../../Utils/Notifications";
import AWS from "aws-sdk";
import FFModal from "../../components/Modal";
import { IMAGE_URL } from "../../Utils/constant";
const MultipleItemsStep1 = (props) => {
  let history = useHistory();
 

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_S3_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET,
    region: process.env.REACT_APP_S3_REGION,
  })

  var s3 = new AWS.S3();
   

  const Nextpage = async () => {
    // if (props.history.location.state.page2) {
    //   var data={
    //     page2:props.history.location.state.page2
    //   }
    //   console.log("=======================",data)
    //  return props.history.push({ pathname: "/multiple-items-step2", state:data});

    // }

    if (
      NewCounties.toString().includes(
        values.multiItems_county
          ? values.multiItems_county
          : values.present_county
      )
    ) {
      let location = values.multiItems_county
        ? values.multiItems_county
        : values.present_county;

      const res = await request(
        "post",
        "/getAuctionDetails",
        {
          state: location,
        },
        false,
        true
      );

      if (res.data.success) {
        //setValues({ ...values, auctionlot_id: res.data.data.id});
      } else {
        Toast.fire({
          type: "warning",
          title:
            "Unfortunately you have missed the deadline for listing in this weeks sale. Don't worry " +
            location +
            " sale room will reopen for listings for the next sale. Please list your Lot then. Thank you",
        });
        return false;
      }

      var data = {
        val: values,
        ite:
          props.history.location.state !== undefined
            ? props.history.location.state.arr
            : "",
        page2:
          props.history.location.state !== undefined
            ? props.history.location.state.page2
            : "",
      };

      props.history.push({ pathname: "/multiple-items-step2", state: data });
    } else {
      Toast.fire({
        type: "warning",
        title:
          "Initially, we are only launching in South East, so please hold off for a little while",
      });
    }
  };

  const removeImage = (e) => {
    if (e === "videos") {
      setValues({ ...values, product_videos: "" });
    } else {
      values.product_image.splice(values.product_image.indexOf(e), 1);
      setValues({ ...values });
    }
  };

  const uploadfile = (params) => {
    console.log('uploadfile ', params);
    return  s3.upload(params, function(err, data) {
 
      var progressDiv = document.getElementById("myProgressDiv");
      if (progressDiv){
        progressDiv.style.display="none";
        var progressBar = document.getElementById("myBar");
        progressBar.style.width = "1%";
      }
      if (err) {
        console.log('There was an error uploading your file: ', err);
        removeImage("videos")
        Toast.fire({
          type: "warning",
          title: 'Upload failed! Please try again.',
        });
        return false;
      }
      console.log('Successfully uploaded file.', data);
      return true;
    });
  }

  const onClickVideo = (e) => {
    console.log("==================", e.target.files[0]);
    let ext = ''
    if (e.target.files[0].name.indexOf('.') > 0) {
      ext = '.' + e.target.files[0].name.split('.').slice(-1)[0];
    }
    let file_name = localStorage.userId + '_' + new Date().getTime() + ext;
    
    const params = {
      ACL: 'public-read',
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Key: 'farmfayre/'+file_name,
      Body: e.target.files[0],
    };

    e.target.files[0].s3_name = file_name;
    setValues({ ...values, product_videos: e.target.files[0] });

    var progressDiv = document.getElementById("myProgressDiv");
    progressDiv.style.display="block";
    var progressBar = document.getElementById("myBar");

    let fileUpload = {
      id: "",
      name: file_name,
      nameUpload: file_name,
      size: e.target.files[0].size,
      type: "",
      timeReference: 'Unknown',
      progressStatus: 0,
      displayName: e.target.files[0].name,
      status: 'Uploading..',
    }
    uploadfile(params).on('httpUploadProgress', function(progress) {
      let progressPercentage = Math.round(progress.loaded / progress.total * 100);
      console.log(progressPercentage);
      progressBar.style.width = progressPercentage + "%";
      if (progressPercentage < 100) {
        fileUpload.progressStatus = progressPercentage;

      } else if (progressPercentage == 100) {
        fileUpload.progressStatus = progressPercentage;

        fileUpload.status = "Uploaded";
      }
    })
  };

  var {
    handleChange,
    handleSubmit,
    handleDateChange,
    values,
    errors,
    state,
    setValues,
    setState,
    setErrors,
  } = UseForm(Nextpage, Multiple);

  // var {
  //   handleChange1,
  //   handleSubmit1,
  //   handleDateChange1,
  //   values1,
  //   errors1,
  //   state1,
  //   setValues1,
  // } = UseForm(Nextpage1, Multiple1);

   useEffect(()=>{
     if(values.multiItems_pickup_location === "present_address"){
            let err_new=errors;
            delete err_new.multiItems_bldgname;
            delete err_new.multiItems_stname;
            delete err_new.multiItems_town;
            delete err_new.multiItems_county;
            delete err_new.multiItems_eircode;
            delete err_new.multiItems_country;
            setErrors(err_new)
            setState(false)
     }
   },[values.multiItems_pickup_location])


  useEffect(() => {
    console.log('localStorage1')

    if (!localStorage.getItem("trustapIDToken") && localStorage.getItem("trustapID") != ""){
      window.location.href = "/trustap-redirect-new";
    } else {
      if (localStorage.getItem("trustapID") != "") {
      } else if (localStorage.getItem("trustapIDToken") != ""){
        window.location.href = "/trustap-redirect-new";
      } else {
        window.location.href = "/trustap-redirect";
      }
    }
    const UserProfile = async () => {
      try {
        const res = await request(
          "post",
          "/getUserProfileDetails",
          {
            user_id: localStorage.userId,
          },
          false,
          true
        );
        console.log(res);

        if (res.data.success === "yes") {
          setValues({
            ...values,
            present_bldgname: res.data.data.address1,
            present_stname: res.data.data.address2,
            present_town: res.data.data.city,
            present_country: res.data.data.country,
            present_county: res.data.data.state,
            present_eircode: res.data.data.zip,
            multiIteme_pickup_location: "present_address",
            multiItems_truck_turn: "yes",
            multiItems_resprice_checked: "public"
          });
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    };
    UserProfile();

    if (props.history.location.state) {
      const res = props.history.location.state.values;
      console.log("++++++++++++++", res);
      values = res;
      setValues({ ...res });
      setState(false)
    }
  }, []);

  //console.log("+++++++++++==", errors);
  // const onClickImages = (e) => {
  //   Object.keys(e.target.files).map((value, key) => {
  //     console.log(e.target.files[key]);
  //     // setValues({
  //     //   ...values,
  //     //   product_image: [...values.product_image, e.target.files[key]],
  //     // });
  //     setValues(prevState => ({ ...prevState, product_image: [...prevState.product_image, e.target.files[key]]}));
  //   });
  //   console.log(e.target.files);
  // };
  const [options] = useState({
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  });

  const onClickImages = (e) => {
    console.log(e.target.files);
    Object.keys(e.target.files).map(async(value, key) => {
      var img = await imageCompression(e.target.files[key], options);
      var formData = new FormData();
      formData.append("product_image", img);
      const res = await request("post","/image_upload",formData, false, true)
      console.log("+++++++++++++++++image_upload res",res.data)
      if (res.data && res.data.arrayimages){
        res.data.arrayimages.map((image) => {
          setValues((prevState) => ({
            ...prevState,
            product_image: [...prevState.product_image, image],
          }));
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 380, left: 0, behavior: "smooth" });
  }, []);

  //console.log(values);
  return (
    <Layout>
      <div className="main-container">
        <div className="pp-back">
          <img src="/images/listingSingle.jpg" alt="" />
        </div>
        <div className="pl-white-box">
          <h2 className="pl-head">POST A LISTING</h2>
          <div className="step-wrapper multi-items-step">
            <div className="clearfix step step1 active">
              <div className="step-circle">01</div>
              <div className="step-label">LOT DETAILS</div>
            </div>
            <div className="clearfix step step2">
              <div className="step-circle">02</div>
              <div className="step-label">ITEM DETAILS</div>
            </div>
          </div>
          <div className="pl-form">
            <h4 className="form-ghead">Lot Details</h4>
            <div className="row">
              <div className="col-md-6 form-group">
                <CustomInput
                  id="lot-title"
                  label="Lot title"
                  placeholder="Enter your lot title"
                  tooltiptitle="Keep this short and informative. This is what Buyers will see first."
                  // con={}
                  name="multiItems_title"
                  value={values.multiItems_title}
                  onChange={handleChange}
                  error={state ? !values.multiItems_title && true : false}
                  helperText={state && errors.multiItems_title}
                  InputProps={{
                    onKeyPress: values.multiItems_title
                      ? values.multiItems_title.length === 100
                        ? dallow
                        : ""
                      : "",
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 form-group">
                <CustomInput
                  id="description"
                  label="Description"
                  placeholder="Enter a brief description"
                  tooltiptitle="Here you can get into more detail. Describe your livestock. Don't hold back, a good description can add many &#8364; to your sale price"
                  multiline
                  rows={4}
                  name="multiItems_description"
                  value={values.multiItems_description}
                  onChange={handleChange}
                  error={state ? !values.multiItems_description && true : false}
                  helperText={state && errors.multiItems_description}
                  // InputProps={{
                  //   onKeyPress:(values.multiItems_description ?(values.multiItems_decription.length === 500?dallow:""):"")
                  // }}
                />
              </div>
              <div className="col-12 col-md-6 form-group">
                <CustomInput
                  id="description"
                  label="Disclaimer/Declaration"
                  placeholder="Enter a Disclaimer/Declaration"
                  tooltiptitle=""
                  multiline
                  rows={4}
                  name="multiItems_declaration"
                  value={values.multiItems_declaration}
                  onChange={handleChange}
                  InputProps={{
                    onKeyPress: values.multiItems_declaration
                      ? values.multiItems_declaration.length === 500
                        ? dallow
                        : ""
                      : "",
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-3 form-group">
                <CustomSelect
                  id="type"
                  label="Section"
                  tooltiptitle="Type of an Item"
                  name="multiItems_section"
                  selectMenu={Section}
                  value={values.multiItems_section}
                  error={state && !values.multiItems_section ? true : false}
                  pc_onchange={handleChange}
                  selectMenuHeader={"Select Section"}
                ></CustomSelect>
                {state && (
                  <FormHelperText error>
                    {errors.multiItems_section}
                  </FormHelperText>
                )}
              </div>
              <div className="col-sm-6 col-md-3 form-group">
                <CustomSelect
                  id="breed"
                  label="Type"
                  tooltiptitle="Breed of the animal"
                  name="multiItems_type"
                  value={values.multiItems_type}
                  pc_onchange={handleChange}
                  selectMenu={Type}
                  selectMenuHeader={"Select type"}
                  error={state ? !values.multiItems_type && true : false}
                ></CustomSelect>
                {state && (
                  <FormHelperText error>
                    {errors.multiItems_type}
                  </FormHelperText>
                )}
              </div>
              <div className="col-sm-6 col-md-3 form-group">
                <CustomSelect
                  //id="method"
                  label="Sale Method"
                  tooltiptitle="Sale Method"
                  name="sale_method"
                  selectMenu={salemethod}
                  selectMenuHeader={"Select Sale method"}
                  value={values.sale_method}
                  pc_onchange={handleChange}
                  error={
                    state ? !values.sale_method && true : false
                  }
                ></CustomSelect>
                {state && (
                  <FormHelperText error>
                    {errors.sale_method}
                  </FormHelperText>
                )}
              </div>
              {values.sale_method == "Weighing Method" ? (
              <div className="col-sm-6 col-md-3 form-group">
                <CustomSelect
                  id="weimethod"
                  label="Weighing method"
                  tooltiptitle="Weighing method"
                  name="multiItems_weighing_method"
                  selectMenu={weimethod}
                  selectMenuHeader={"Select Weighing method"}
                  value={values.multiItems_weighing_method}
                  pc_onchange={handleChange}
                  error={
                    state ? !values.multiItems_weighing_method && true : false
                  }
                ></CustomSelect>
                {state && (
                  <FormHelperText error>
                    {errors.multiItems_weighing_method}
                  </FormHelperText>
                )}
              </div>) : ""}

              <div className="col-12 col-md-6 form-group">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Quality assurance farm
                  </FormLabel>
                  <RadioGroup
                    aria-label="truck-turn"
                    className="fd-row"
                    name="multiItems_assurance"
                    value={values.multiItems_assurance}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                  {state && (
                    <FormHelperText error>
                      {errors.multiItems_truck_turn}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>

              <div className="col-12 col-md-6 form-group">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Can a Truck access and turn in your yard?
                  </FormLabel>
                  <RadioGroup
                    aria-label="truck-turn"
                    className="fd-row"
                    name="multiItems_truck_turn"
                    value={values.multiItems_truck_turn}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                  {state && (
                    <FormHelperText error>
                      {errors.multiItems_truck_turn}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>

              <div className="col-12 col-md-3 form-group">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Display Seller name and approximate location
                  </FormLabel>
                  <RadioGroup
                    aria-label="preferential-times"
                    name="multiItems_pref_times"
                    className="fd-row"
                    value={values.multiItems_pref_times}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                  {state && (
                    <FormHelperText error>
                      {errors.multiItems_pref_times}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 form-group">
                <Upload
                  src="/images/insert-photo.png"
                  header="UPLOAD IMAGE"
                  subheader="You can upload multiple images"
                  id="upload-image"
                  title="Images displayed under each item"
                  accept="image/*"
                  onchange={onClickImages}
                  multiple={true}
                />
                {state && (
                  <FormHelperText error>{errors.product_image}</FormHelperText>
                )}
                <div className="row">
                  {values.product_image &&
                    values.product_image.map((m, key) => (
                      <div className="col-md-3 productPostThumbnails" key={key}>
                        <label
                          onClick={() => removeImage(m)}
                          name={key}
                          className="pp-edit material-icons"
                        >
                          close
                        </label>
                        <img
                          src={IMAGE_URL + m}
                          className="pro-img rounded img-fluid"
                        />
                      </div>
                    ))}
                </div>
              </div>

              <div className="col-12 col-md-6 form-group">
                <Upload
                  src="/images/movie.png"
                  header="UPLOAD VIDEO"
                  subheader="You can upload a single video file"
                  accept="video/*"
                  id="upload-image"
                  onchange={onClickVideo}
                  multiple={false}
                  title="Videos displayed under each item"
                />

                {state && (
                  <FormHelperText error>{errors.product_videos}</FormHelperText>
                )}
                <div className="row">
                  {values.product_videos && (
                    <>
                      <div className="col-12 mt-2 productPostThumbnails multVideo">
                        <label
                          onClick={() => removeImage("videos")}
                          className="pp-edit material-icons"
                        >
                          close
                        </label>
                        <Player
                          // ClosedCaptionButton={true}
                          playsInline
                          poster="/assets/poster.png"
                          src={URL.createObjectURL(values.product_videos)}
                        />
                      </div>
                    </>
                  )}
                  <div className="col-12 mt-2" id="myProgressDiv" style={{ "display": "none"}}>
                    <div id="myProgress" className="" style={{  "width": "100%", "backgroundColor": "#CFCFCF"}}>      
                        <div id="myBar" style={{  "width": "1%",  "height": "30px",  "backgroundColor": "#76bc43"}} ></div>    
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h4 className="form-ghead mt-4">Price Details</h4>

            <div className="row">
              <div className="col-12 col-md-6 form-group">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Post this listing under
                  </FormLabel>
                  <div className="listing-under d-flex">
                    <CheckBox
                      label="Sale"
                      name="multiItems_auction_checked"
                      value={true}
                      // onchange={handleChange}
                      // click={() => !values.multiItems_auction_checked}
                    />
                    <CheckBox
                      label="Buynow"
                      name="multiItems_buynow_checked"
                      value={values.multiItems_buynow_checked}
                      onchange={handleChange}
                      click={() => !values.multiItems_buynow_checked}
                    />
                  </div>
                </FormControl>
              </div>
              <div className="col-12 col-md-3 form-group">
                <CustomInput
                  id="start-price"
                  label="Start price"
                  placeholder="Enter a start price"
                  name="multiItems_startprice"
                  value={values.multiItems_startprice}
                  onChange={handleChange}
                  error={state ? !values.multiItems_startprice && true : false}
                  disabled={values.multiItems_auction_checked ? false : true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span className="material-icons">euro</span>
                      </InputAdornment>
                    ),
                    onKeyPress: NumberOnly,
                  }}
                  // InputProps={{

                  // }}
                  tooltiptitle="Starting price of the animal"
                  helperText={state && errors.multiItems_startprice}
                />
              </div>
              <div className="col-12 col-md-3 form-group">
                <CustomInput
                  id="buy-now-price"
                  label="Buy now price"
                  placeholder="Enter buy now price"
                  name="multiItems_buynowprice"
                  disabled={values.multiItems_buynow_checked ? false : true}
                  value={values.multiItems_buynowprice}
                  onChange={handleChange}
                  error={
                    state && errors.multiItems_buynowprice
                      ? !values.multiItems_buynowprice && true
                      : false
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span className="material-icons">euro</span>
                      </InputAdornment>
                    ),
                    onKeyPress: NumberOnly,
                  }}
                  tooltiptitle="Buy Now Price of an animal"
                  helperText={state && errors.multiItems_buynowprice}
                />
              </div>

              {/* <div className="col-12 col-md-3 form-group">
                <DatePicker
                  id="Auction start-date"
                  key="Auction start-date"
                  label="Auction start-date"
                  format={"MM/dd/yyyy hh:mm:ss"}
                  placeholder="Auction start-date"
                  disableFuture={false}
                  name="start_date"
                  value={values.start_date}
                  onchange={handleDateChange}
                  tooltiptitle="Auction start-date"
                  error={state ? !values.start_date && true : false}
                  helperText={state && errors.start_date}
                />
              </div> */}

              {/* <div className="col-12 col-md-3 form-group">
                <DatePicker
                  id="Auction end-date"
                  format={"MM/dd/yyyy hh:mm:ss"}
                  key="Auction end-date"
                  label="Auction end-date"
                  placeholder="Auction end-date"
                  disableFuture={false}
                  name="end_date"
                  value={values.end_date}
                  onchange={handleDateChange}
                  tooltiptitle="Auction end-date"
                  error={state ? !values.end_date && true : false}
                  helperText={state && errors.end_date}
                />
              </div>  */}
            </div>

            <div className="row">
              <div className="col-12 col-md-6 form-group">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Select reserve price type
                  </FormLabel>
                  <RadioGroup
                    aria-label="reserve-price"
                    name="multiItems_resprice_checked"
                    className="reserve-price"
                    className="fd-row"
                    value={values.multiItems_resprice_checked}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="public"
                      control={<GreenRadio />}
                      label="Public"
                    />
                    <FormControlLabel
                      value="private"
                      control={<GreenRadio />}
                      label="Private"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-12 col-md-3 form-group">
                <CustomInput
                  id="lot-title"
                  label="Reserve price"
                  placeholder="Enter reserve price"
                  fullWidth
                  margin="normal"
                  disabled={values.multiItems_auction_checked ? false : true}
                  name="multiItems_resprice"
                  value={values.multiItems_resprice}
                  onChange={handleChange}
                  error={state ? !values.multiItems_resprice && true : false}
                  helperText={state && errors.multiItems_resprice}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span className="material-icons">euro</span>
                      </InputAdornment>
                    ),
                    onKeyPress: NumberOnly,
                  }}
                  tooltiptitle="Reserver price of an product"
                />
              </div>
            </div>

            <h4 className="form-ghead mt-4">Pick-up Location Details</h4>

            <div className="row">
              <div className="col-12 col-md-12 form-group">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Collection location of livestock
                  </FormLabel>
                  <RadioGroup
                    aria-label="reserve-price"
                    name="multiItems_pickup_location"
                    className="reserve-price"
                    value={values.multiItems_pickup_location}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="different_address"
                      control={<GreenRadio />}
                      label="Different address"
                    />
                    <FormControlLabel
                      value="present_address"
                      control={<GreenRadio />}
                      label="Same as my present address"
                    />
                  </RadioGroup>
                </FormControl>
                {/*<p className="CountyLaunchNotice">
                  <span className="material-icons">info</span>Initially, we are only
                  launching in South Leinster, So please hold off for a little
                  while.
                </p>*/}
              </div>
            </div>
            {values.multiItems_pickup_location == "different_address" ? (
              <div className="row">
                <div className="col-sm-6 form-group">
                  <CustomInput
                    id="building-name"
                    label="Address Line 1"
                    // con={false}
                    placeholder="Enter your Address Line 1"
                    tooltiptitle="Enter your Address Line "
                    name="multiItems_bldgname"
                    value={values.multiItems_bldgname}
                    onChange={handleChange}
                    error={state ? !values.multiItems_bldgname && true : false}
                    helperText={state && errors.multiItems_bldgname}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <CustomInput
                    id="street-name"
                    label="Townsland / Address Line 2"
                    con={"sn"}
                    placeholder="Enter your Townsland / Address Line 2"
                    tooltiptitle="Enter your Townsland / Address Line 2"
                    name="multiItems_st"
                    value={values.multiItems_st}
                    onChange={handleChange}
                    error={state ? !values.multiItems_st && true : false}
                    helperText={state && errors.multiItems_stname}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <CustomInput
                    id="town-name"
                    label="Town name"
                    con={false}
                    placeholder="Enter town"
                    tooltiptitle="Enter your town"
                    name="multiItems_town"
                    value={values.multiItems_town}
                    onChange={handleChange}
                    error={state ? !values.multiItems_town && true : false}
                    helperText={state && errors.multiItems_town}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <CustomSelect
                    id="County"
                    label="Select your county"
                    tooltiptitle="County"
                    name="multiItems_county"
                    value={values.multiItems_county}
                    pc_onchange={handleChange}
                    error={state ? !values.multiItems_county && true : false}
                    selectMenuHeader={'Select your county'}
                  >
                    {NewCounties.map((m) => (
                      <option key={m} value={m}>
                        {m}
                      </option>
                    ))}
                  </CustomSelect>
                  {state && (
                    <FormHelperText error>
                      {errors.multiItems_county}
                    </FormHelperText>
                  )}
                  {/* <p style={{color:"black",fontSize:"12px"}}>Initially, we are only launching in South Leinster ,so please hold off for a little while</p> */}
                </div>
                <div className="col-sm-6 form-group">
                  <CustomSelect
                    id="Country"
                    label="Select your country"
                    tooltiptitle="Country"
                    name="multiItems_country"
                    value={values.multiItems_country}
                    pc_onchange={handleChange}
                    error={state ? !values.multiItems_country && true : false}
                    selectMenuHeader={'Select your country'}
                  >
                    <option key="Ireland" value="Ireland">
                      Ireland
                    </option>
                  </CustomSelect>
                  {state && (
                    <FormHelperText error>
                      {errors.multiItems_country}
                    </FormHelperText>
                  )}
                </div>

                <div className="col-sm-6 form-group">
                  <CustomInput
                    id="eir-code"
                    label="EIR code"
                    con={"sn"}
                    placeholder="Enter EIR code"
                    tooltiptitle="EIR code"
                    name="multiItems_eircode"
                    value={values.multiItems_eircode}
                    onChange={handleChange}
                    error={state ? !values.multiItems_eircode && true : false}
                    helperText={state && errors.multiItems_eircode}
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            {/* <div className="row">
              <div className="col-12 col-md-4 form-group">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Do you want to link your lots
                  </FormLabel>
                  <RadioGroup
                    aria-label="reserve-price"
                    name="multiItems_linklots"
                    className="reserve-price"
                    value={values.multiItems_linklots}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div> */}

            <div className="pl-btn pt-4">
              <PrimaryButton label="NEXT" onsubmit={handleSubmit}>
                <span className="material-icons">arrow_forward</span>
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MultipleItemsStep1;
