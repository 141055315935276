import React from "react"; 
import Header from './Header'
import Footer from './Footer' 

const Layout  = (props) => { 

  return ( 
  	<>
	  {!window.location.href.includes("?app_ios_and=1")?
	  <Header/>:""}
	  {props.children}
	  {!window.location.href.includes("?app_ios_and=1")?
	  <Footer />:""}
    </>
  );
}

export default Layout;
