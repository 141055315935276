import React, { useEffect, useRef, useState } from "react";
import SearchFilters from "../../components/SearchFilters";
import "./Search.css";
import Layout from "../../components/Layout";
import GridView from "./GridView";
import ListView from "./ListView";
import CustomDropdown from "../../components/Inputs/CustomDropdown";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FilterList, FlipToBack } from "@material-ui/icons";
import moment from "moment";

const Search = () => {
  console.log("Test");
  const [gridView, setGridView] = useState(true);
  const [filter, setFilter] = useState(false);
  const [searchData, setSearchData] = useState({
    live: [],
    upcomming: [],
  });
  const [filt, setFilt] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [order, setOrder] = useState({
    ord: "",
    row:""
  });
  const [loading,setLoading]=useState(true)
 
 


  const handleClick = (event) => {
    console.log("+++++++++",event.currentTarget)
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event) => {
    console.log("+++++++++",event.currentTarget)
    setAnchorEl1(event.currentTarget);
  };

  const handleClose = (e) => {
   
    setOrder({ ...order, row: e.target.value });
    setAnchorEl(null);
    
  };
  const handleClose1 = (e) => {
    
    setOrder({ ...order, ord: e.target.value });
   
    setAnchorEl1(null)
  };

  const toggleView = () => setGridView(!gridView);
  const toggleFilter = () => setFilter(!filter);

  const fill = (data) => {
    setFilt(data);
  };

  const SearchResults = (data) => {
    var liv = [],
      upcom = [];
    var cdate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    {data.results && data.results.map((val) => {
      if (moment(val.date_added).format("YYYY-MM-DD HH:mm:ss") > cdate) {
        upcom.push(val);
      }
      if (
        val.market_status == "open" &&
        moment(val.date_added).format("YYYY-MM-DD HH:mm:ss") < cdate
      ) {
        liv.push(val);
      }
    });
  }
    setSearchData({ ...searchData, live: liv, upcomming: upcom });
    setLoading(true)
  };

  /* useEffect(()=>{
  SearchResults()
},[]) */

  useEffect(() => {
    window.scrollTo({top: 400, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Layout>
      <div className="ffSearch">
        <div className="searchBanner"></div>
        <a className="search-filter-link" onClick={toggleFilter}>Filter By</a>
        {filter && <a className="sclose-filter" onClick={toggleFilter}><i className="fa fa-times"></i></a> }
        <div className={`searchOptions search-filter-wrapper ${filter ? "show-filter-xs" : "hide-filter-xs"}`}>
          <SearchFilters
            SearchResults={SearchResults}
            filterss={fill}
            ord={order.ord}
            row={order.row}
            setLoading={setLoading}
          />
        </div>
        {loading?
        <>
        <div className="searchResults customContainer">
          <div className="d-flex justify-content-between align-items-center">
            {filt.All || filt.live ? (
              <div className="searchTitle d-flex justify-content-start align-items-center">
                <h2 className="playFair">Live Sales</h2>
                <span>
                  Showing {searchData.live ? searchData.live.length : "0"}{" "}
                  results
                </span>
              </div>
            ) : (
              <div className="searchTitle d-flex justify-content-start align-items-center"></div>
            )}
            <div className="d-flex justify-content-start align-items-center">
              <div
                className={`${
                  gridView ? "searchOptChildren active" : "searchOptChildren"
                }`}
                onClick={toggleView}
              >
                <span class="material-icons">apps</span>
              </div>
              <div
                className={`${
                  gridView ? "searchOptChildren" : "searchOptChildren active"
                }`}
                onClick={toggleView}
              >
                <span class="material-icons">view_list</span>
              </div>
              <div className="searchSorting">
                <Button
                  aria-controls="simple-menu1"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  Results per page
                  <span className="material-icons">expand_more</span>
                </Button>

                <Menu
                  id="simple-menu1"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem value={10} onClick={handleClose}>10</MenuItem>
                  <MenuItem value={20} onClick={handleClose}>20</MenuItem>
                  <MenuItem value={30} onClick={handleClose}>30</MenuItem>
                </Menu>
              </div>

              <div className="searchSorting">
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick1}
                >
                  Sort by<span className="material-icons">expand_more</span>
                </Button>

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl1}
                  keepMounted
                  open={Boolean(anchorEl1)}
                  onClose={handleClose1}
                >
                  <MenuItem value={"2"} onClick={handleClose1}>
                    Newest First
                  </MenuItem>
                  <MenuItem value={"11"} onClick={handleClose1}>
                    Price- High to Low
                  </MenuItem>
                  <MenuItem value={"3"} onClick={handleClose1}>
                    Price- Low to High
                  </MenuItem>
                  {/* <MenuItem onClick={handleClose}>Ending first</MenuItem>
                  <MenuItem onClick={handleClose}>Ending last</MenuItem> */}
                </Menu>
              </div>
            </div>
          </div>
          {filt.All || filt.live ? (
            <div>
              {gridView ? (
                <GridView searchData={searchData.live} />
              ) : (
                <ListView searchData={searchData.live} />
              )}
              {searchData.live.length >= 12 && (
                <div className="customContainer">
                  <div className="viewAllLn">
                    <Button>VIEW ALL</Button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="searchResults customContainer">
          <div className="d-flex justify-content-between align-items-center">
            {filt.All || filt.upcomming ? (
              <div className="searchTitle d-flex justify-content-start align-items-center">
                <h2 style={filt.upcomming && !filt.All?{marginTop:"-170px"}:{}} className="playFair">Upcoming Sales</h2>
                <span style={filt.upcomming && !filt.All?{marginTop:"-170px"}:{}} >
                  Showing{" "}
                  {searchData.upcomming ? searchData.upcomming.length : "0"}{" "}
                  results
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
          {filt.All || filt.upcomming ? (
            <div>
              {gridView ? (
                <GridView searchData={searchData.upcomming} />
              ) : (
                <ListView searchData={searchData.upcomming} />
              )}
              {searchData.upcomming.length >= 12 && (
                <div className="customContainer">
                  <div className="viewAllLn">
                    <Button>VIEW ALL</Button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div></>:<div style={{fontSize:"30px"}}>Loading....</div>}



      </div>
    </Layout>
  );
};

export default Search;
