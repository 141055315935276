import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Sticky from '../Sticky';
import "./catlogue-filters.css";
import { request } from "../../Utils/Request";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: "100px",
    maxWidth: 200,
    width: "100%",
    height: 45,
    marginRight: "15px",
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: "var(--primColor)",
    "&$checked": {
      color: "var(--primColor)",
    },
    MuiFormControlLabelRoot: {
      marginBottom: 0,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const filters = [
  { label: "Section" },
  { label: "Type" },
  { label: "Weight" },
  { label: "Sex" },
  { label: "Age" },
  { label: "Section" },
  { label: "Breed" },
  { label: "Seller rating" },
  { label: "Keyword search" },
];

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const CatlougeFilters = (props) => {

  const classes = useStyles();
  const [personName, setPersonName] = React.useState([]);
  const [initialvalue, setinitialvalue] = useState({
    /* sh_limit: 8, */
    category: [], //Sections filter
    location: [], // Province search
    subcategory: [], //type is covered
    weight:[], //Weight needs to be mapped
    contenthead5: [], //Sex filter
    contenthead2: [], //DOB to be mapped as inventorydate or a new date
    contenthead1: [], //Breed filter
    contenthead3: [],
    orderby: 2,
    fromprice: "",
    toprice: "",
    QA: "", //??
    export: "", //??
    searchbar: "" //Keyword
});
  const handleChange = (event) => {
    setPersonName(event.target.value);
  };
  async function submitSearch (){
    try{
  
      const res =await request(
        "post",
        "/mobilesearch",
        initialvalue
        )
        if(res.data.success=== "yes"){
          props.SearchResults(res.data)
        }
        
    }
    catch(err){
      console.log(err)
    }
  
    
  
    }

  return (
    <Sticky>
      <div className="catlog-filters">
         <div className="filterOptions">
          <h4>Add Filters</h4>
          <div className="d-flex justify-content-between align-items-center">
            {filters.map((key) => (
              <FormControl
                className={classes.formControl}
                variant="outlined"
                size="small"
              >
                <InputLabel id="demo-mutiple-checkbox-label">
                  {key.label}
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={personName}
                  onChange={handleChange}
                  // input={<Input />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={{
                    getContentAnchorEl: () => null,
                  }}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <GreenCheckbox checked={personName.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))}
          </div>
        </div>
      </div>
    </Sticky>
  );
};

export default CatlougeFilters;
