import React from "react";
import InputField from "../../components/Inputs/InputField"; 
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import SelectField from "../../components/Inputs/SelectField"; 
import { Toast } from "../../Utils/Notifications";
import { request } from "../../Utils/Request";
import UseForm from "../../Utils/UserForm";
import { cardvalidate, regcarddetails } from "../../Utils/Validators";
import CustomInput from "../../components/Inputs/borderedInput";
import CustomSelect from "../../components/Inputs/borderedSelect";
import JwtDecode from "jwt-decode";
import { isAuthenticated } from "../../Utils/Authenticated";
import { FormHelperText } from "@material-ui/core";
import { HerdNum } from "../../Utils/constant";



const CardForm = (props) => { 

  
  var {
    handleSubmit,
    handleChange,
    values,
    errors,
    state,
    setValues
  } = UseForm(formSubmit,cardvalidate);

// const submitbutton=(e)=>{
//   setValues({...values,payment_type:""})
//   handleSubmit(e)
// }
  async function formSubmit(e) {

  if(values.type==="debit card"){
    var split = values.date.split('/')
    const formdata = new FormData()
    console.log("+++++",values.payment_type)
    
      const data = {
        cardNumber: values.card_number.split(' ').join(''),
        card_name: values.card_name,
        expiryMonth: split[0],
        expiryYear:split[1].length=="4"?split[1]:20+split[1],
        cvv: values.cvv,
        amount: '0.50',
        isSave: true,
        capture: false,
        mode: process.env.REACT_APP_PAYMENT_MODE,
        currency: "eur",
      }
      try{
        const res = await request('post','/payAmountStripe', data, false, true)
        if (!res.data.success) {
          Toast.fire({ type: 'error', title: res.data.message.error.message })
       }
       else{
         Toast.fire({ type: 'success', title: 'Your Card Added Successfully' })
         props.recall(e)
       } 
      } catch (error) {
        Toast.fire({ type: 'error', title: 'Try again later!' })
        console.log(error)
      }
    }
  
    else{
      var data={
        user_id:JwtDecode(isAuthenticated()).id,
        bank:values.bank,
        iso:values.iso_country_code,
        branch:values.branch_identifier,
        account:values.account_number,
       
      }
      const res=await request("post","/addnewbank",data)
       if(res.data.success==="yes"){
         Toast.fire({type:'success',title:"Bank details added successfully"})
       }
      
    }
      
    }
    return (
	     <div className="card-form"> 
        <div className="ffRegInp card_Form"> 
              {/* <SelectField
                label="Mode of payment"
                name="type"
                selectMenu={["debit card","Bank"]}
                selectMenuHeader={"selct payment type"}
                onchange={handleChange}
                value={values.type}
              />  */}
            

            {values.type==="debit card"?
              <>
              <InputField
                label="Name on card"
                placeholder="Enter the name on card"
                name="card_name"
                values={values.card_name}
                con={false}
                onchange={handleChange}
                error={errors.card_name && state?true:false}
                helperText={state?errors.card_name:""}  
              />
              {console.log("++++++",values.card_number.length)}
              <InputField
                label="Card Number"
                placeholder="Enter your card number"
                name="card_number"
                con={values.card_number.length == "16"?"dallow":true}   
                values={values.card_number}
                onchange={handleChange}
                error={errors.card_number && state?true:false}
                helperText={state?errors.card_number:""}
              />
              <InputField
                label="Expiry date"
                placeholder="Enter expiry date"
                name="date"
                // con={true}
                con={values.date.length =="5"?"dallow":true}  
                value={values.date}
                onchange={handleChange}
                error={errors.date && state?true:false}
                helperText={state?errors.date:""} 
              />
              <InputField
                label="CVV"
                placeholder="Enter CVV"
                name="cvv"
                con={values.cvv.length=="3"?"dallow":true}  
                values={values.cvv}
                onchange={handleChange}
                error={errors.cvv && state?true:false}
                helperText={state?errors.cvv:""} 
              />
              </>
              :
              <>
              <InputField
              label="Account Holder Name*"
              placeholder="Enter account holder name"
              tooltiptitle="Enter account holder name"
              name="branch_identifier"
              con={false}
              onchange={handleChange}
              value={values.branch_identifier}
              error={state && errors.branch_identifier?true:false}
              helperText={state && errors.branch_identifier}
            />
            <InputField
              label="IBAN*"
              // con={true}
              placeholder="Enter your IBAN"
              tooltiptitle="Enter your IBAN"
              name="iso_country_code"
              onchange={handleChange}
              
              value={values.iso_country_code}
              InputProps={{
               
                onKeyPress: HerdNum
              }}
              helperText={state && errors.iso_country_code?errors.iso_country_code:""}
              error={state && errors.iso_country_code?true:false}
              
            />
            <InputField
              placeholder="Enter Bank Name"
              label="Bank Name*"
              con={false}
              tooltiptitle="Bank Name"
              name="bank"
              onchange={handleChange}
              value={values.bank}
             
            
              error={state && errors.bank?true:false}

            ></InputField>
            <FormHelperText error>{state && errors.bank}</FormHelperText>
               
            <InputField
              label="Account Number"
              placeholder="Enter account number"
              tooltiptitle="Enter account number"
              name="account_number"
              con={true}
              onchange={handleChange}
              value={values.account_number}
              // error={state && errors.account_number?errors.account_number:""}
            />

            
            <InputField
              label="Sort Code"
              placeholder="Enter Sort Code"
              tooltiptitle="Enter Sort Code"
              name="sort_code"
              con={true}
              onchange={handleChange}
              value={values.sort_code}
             
            />
            
              </>
              }


            

        </div>
    
        <PrimaryButton onsubmit={handleSubmit} label={values.type==="debit card"?"Add To Card":"Add Bank Account"}  />
      </div>
    );
}

export default CardForm