import React from "react";
import {
  Route,
  BrowserRouter as Router,
  withRouter,
  Switch 
} from "react-router-dom";
import {  HelmetProvider,Helmet } from 'react-helmet-async'
import { Redirect } from 'react-router'
import RegTier_1 from "../views/Registration/Reg_tier_1";
import Login from "../views/Login";
import Reg_tier_2 from "../views/Registration/Reg_tier_2";
import Reg_tier_3 from "../views/Registration/Reg_tier_3";
import Tempdashboard from "../views/tempShowcases/Tempdashboard"
// import ForgotPassword from "../views/Login/ForgotPassword";
import ResetPassword from "../views/Login/ResetPassword";
import Home from "../views/Home";
import PostProjectLotSize from "../views/PostProject/PostProjectLotSize"
import PostProjectStep1 from "../views/PostProject/PostProject_Single"
import MultipleItemsStep1 from "../views/PostProject/MultipleItemsStep1"
import MultipleItemsStep2 from "../views/PostProject/MultipleItemsStep2" 
import Profile from "../views/Profile" 
import Mypurchases from "../views/Mypurchases" 
import MyAuctions from "../views/MyAuctions"
import Favorites from "../views/Favorites" 
import Notifications from "../views/Notifications"
import SavedDetails from "../views/SavedDetails"
import Transactions from "../views/Transactions"
import Referrals from "../views/Referrals" 
import GridView from "../views/Search/GridView";
import Search from "../views/Search"; 
import ProductView from "../views/ProductView"
import RecentAucton from "../views/ProductView/RecentAucton"
import Checkout from "../views/Checkout";
import CatlougeSelect from '../views/Catlouge/CatlougeSelect';
import Catlouge from '../views/Catlouge';
import Term from "../views/Static/terms";
import Faq from "../views/Static/faq";
import Privacy from "../views/Static/policy";
import Declaration from "../views/Static/sellerdeclaration";
import About from "../views/Static/about";
import Howitwork from "../views/Static/howitworks";
import Support from "../views/Static/support";
import HaulierDashboard from  "../views/HaulierDashboard";
import ContactUs from "../views/ContactUs";
import Error404 from "../components/ErrorPages/Error404";
import MultiItem from "../views/ProductView/MultiItem";
import Invoice from "../views/Invoice";
import MobileInvoice from "../views/MobileInvoice";
import ForgotPassword from "../views/Login/ForgotPassword";
import SellerProfile from "../views/SellerProfile"; 
import Sellerfees from "../views/Static/sellerfees";
import Buyerfees from "../views/Static/buyerfees";
import TrustapRedirect from "../views/TrustapRedirect";
import TrustapRedirectNew from "../views/TrustapRedirectNew";
const PrivateRoute = ({ component: Component, ...rest }) => (
  <>
<Route
{...rest}
render={(props) =>
    localStorage.usertoken ? (
        <Component {...props} />
    ) : (
        <Redirect
            to={{
                pathname: '/login',
                state: { from: props.location },
            }}
        />
    )
}
/>
</>
)

const Routes = (props) => { 
  return (
    <>
      <HelmetProvider>
      <Switch>
        <Route exact path="/" component={Home} />
        
        <Route exact path="/login" component={Login} />
        <Route exact path="/login/forgot_password" component={ResetPassword} />
        <Route exact path="/forgotPassword" component={ForgotPassword}/>
        <Route exact path="/Reg_tier_1" component={RegTier_1} />
        <Route exact path="/Reg_tier_2" component={Reg_tier_2} />
        <Route exact path="/Reg_tier_3" component={Reg_tier_3} />
        <Route exact path="/tempdashboard" component={Tempdashboard} />
        <Route exact path="/sellerfees" component={Sellerfees} />
        <Route exact path="/buyerfees" component={Buyerfees} />
        <Route exact path="/resetPassword" component={ResetPassword} /> {/* Can be redirected to profile page to change password */}
        <Route exact path="/post-project-select-lot-size" component={PostProjectLotSize} />
        <Route exact path="/post-project-single" component={PostProjectStep1} />
        <Route exact path="/multiple-items-step1" component={MultipleItemsStep1} />
        <Route exact path="/multiple-items-step2" component={MultipleItemsStep2} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/my-purchases" component={Mypurchases} />
        <PrivateRoute exact path="/my-auctions" component={MyAuctions} />
        <PrivateRoute exact path="/favourites" component={Favorites} /> 
        <PrivateRoute exact path="/notifications" component={Notifications} />
        <PrivateRoute exact path="/saved-details" component={SavedDetails} />
        <PrivateRoute exact path="/transactions" component={Transactions} />
        <PrivateRoute exact path="/refer-and-earn" component={Referrals} /> 
        <Route exact path="/search" component={Search} /> 
        <Route exact path="/product-view/:id" component={ProductView} /> 
        <Route exact path="/multi-items-view/:id" component={ProductView} /> 
        <Route exact path="/recent-items-view/:id" component={RecentAucton} /> 
        <Route exact path="/checkout/:type/:id" component={Checkout} /> 
        <Route exact path="/catlouge-select" component={CatlougeSelect} />
        <Route exact path="/catlouge" component={Catlouge} /> 
        <Route exact path="/contact" component={ContactUs} /> 
        <Route exact path="/haulier-dashboard" component={HaulierDashboard} />
        <Route exact path="/invoice/:type/:id" component={Invoice} />
        <Route exact path="/invoice/:type/:id/:buyer_id" component={Invoice} />
        <Route exact path="/mobile-invoice/:type/:id/:buyer_id" component={MobileInvoice} />
        <Route exact path="/seller-profile" component={SellerProfile} />
        <Route exact path="/trustap-redirect" component={TrustapRedirect} />
        <Route exact path="/trustap-redirect-new" component={TrustapRedirectNew} />
        {/* Static Pages */}
        <Route exact path="/howItWorks" component={Howitwork}/>
        <Route exact path="/support" component={Support} />
        <Route exact path="/terms" component={Term} /> 
        <Route exact path="/faq" component={Faq} /> 
        <Route exact path="/aboutus" component={About} /> 
        <Route exact path="/privacypolicy" component={Privacy} />
        <Route exact path="/sellerdeclaration" component={Declaration} />

        
        <Route exact path="*" component={Error404} /> 
        </Switch>
        </HelmetProvider>
    </>
  );
};

export default withRouter(Routes);
