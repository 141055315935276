import React, { useState, useEffect } from "react";
import "./AuctionCard.css";
import FavoriteCheckbox from "../FavouriteCheck/FavouriteCheckbox";
import { callNumber, IMAGE_URL } from "../../Utils/constant";
import PrimaryButton from "../Inputs/PrimaryButton";
import { TimeLeft, TimetoDate, ItemNum } from "../../Utils/TimerProfile";
import { Link, useHistory } from "react-router-dom";
import FFModal from "../Modal";
import { Button } from "@material-ui/core";
import { isAuthenticated } from "../../Utils/Authenticated";
import { SaleNumber, ItemNumber } from "../../Utils/TimerProfile";
import Timer from "../../Utils/Timer";
import { request } from "../../Utils/Request";
const AuctionList = (props) => {
  let history = useHistory();
  const [modalopen1, setModalopen] = useState(false);
  const [modalopen2, setModalopen1] = useState(false);
  const [productDetails, setproductDetails] = useState();

  useEffect(() => {
    getProductDetails();
  }, []);

  const moveon = (id, count, mn) => (e) => {
    // if (
    //   !isAuthenticated() /*|| localStorage.getItem("userRole") === "tier1" */
    // ) {
    //   setModalopen(!modalopen1);
    // } else 
    if (count > 1) {
      // window.location.href = "/multi-items-view/" + id;
      return history.push({ pathname: "/multi-items-view/" + id, state: mn });
    } else {
      // window.location.href = "/product-view/" + id;
      return history.push({ pathname: "/product-view/" + id, state: mn });
    }
  };

  const getProductDetails = async () => {
    const res = await request(
      "post",
      "/getprodetails",
      { product_id: props.data.id },
      false,
      true
    );

    if (res.data.success === "yes") {
      setproductDetails(res.data.results);
    }
  };

  const toggleModal1 = () => {
    setModalopen(!modalopen1);
  };
  const toggleModal2 = () => {
    setModalopen1(!modalopen2);
  };
  const moveone = (name) => (e) => {
    if (name == "log") {
      window.location.href = "/login";
    } else {
      if (localStorage.getItem("userRole") === "tier1") {
        window.location.href = "/Reg_tier_2";
      } else {
        window.location.href = "/Reg_tier_1";
      }
    }
  };
  const favchange = (e) => {
    if (!isAuthenticated()) {
      setModalopen1(!modalopen2);
    } else {
      props.onchange(e);
    }
  };
  return (
    <div className="media auctionCard listView">
      <div className="media-left acImg">
        <Button
          onClick={moveon(
            props.data.id,
            props.data.lotcount,
            props.data.modelnumber
          )}
        >
          {" "}
          <img src={IMAGE_URL + props.data.file_name}></img>{" "}
        </Button>
        <FavoriteCheckbox
          favIdChild={props.data.id}
          watchlisted={props.data.watchlisted}
          onCall={(e) => favchange(e)}
        />
      </div>
      <div className="media-body">
        <div className="row">
          <div className="col-md-8 col-lg-9">
            <div className="d-flex justify-content-between">
              <div className="listingHead">
                <Button
                  onClick={moveon(
                    props.data.id,
                    props.data.lotcount,
                    props.data.modelnumber
                  )}
                >
                  {" "}
                  <h1 className="listTitle fs-20">{props.data.title}</h1>
                </Button>
                <div className="auction-num">
                  Sale No:{" "}
                  {SaleNumber(
                    props.data.location,
                    props.data.date_added,
                    props.data.date_closed
                  ) +
                    props.data.auctionid
                      .toString()
                      .slice(props.data.auctionid.toString().length - 4)}{" "}
                  <span>|</span> Item ID:
                  {" " +
                    ItemNum(
                      props.data.location,
                      props.data.date_added,
                      props.data.date_closed
                    ) +
                    props.data.id
                      .toString()
                      .slice(props.data.id.toString().length - 4)}
                </div>
              </div>

              {props.data.latestbid?
              <div className="acPrice">
                <p>Current price</p>
                <div>
                  <span className="brother-regular">
                    € {callNumber(props.data.latestbid)}
                  </span>
                </div>
              </div>:<div className="acPrice">
                <p>Starting price</p>
                <div>
                  <span className="brother-regular">
                    € {callNumber(props.data.sprice)}
                  </span>
                </div>
              </div>}


              
            </div>
            <p className="lv-descp">{props.data.description}</p>
            <div className="row">
              <div className="col-6 col-md-3 form-group">
                <div className="sp-pcon-head">Section</div>
                <div className="pcon-txt1">{props.data.category_id}</div>
              </div>
              <div className="col-6 col-md-3 form-group">
                <div className="sp-pcon-head">Type</div>
                <div className="pcon-txt1">{props.data.subcategory}</div>
              </div>
              <div className="col-6 col-md-3 form-group">
                <div className="sp-pcon-head">Breed</div>
                <div className="pcon-txt1">{props.data.content_head1}</div>
              </div>
              <div className="col-6 col-md-3 form-group">
                <div className="sp-pcon-head">Avg. Weight</div>
                <div className="pcon-txt1">
                  {props.data.lotcount === 1
                    ? props.data.weight
                    : callNumber(Math.round(props.data.avgweight))}{" "}
                  Kgs
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-md-3 form-group">
                <div className="sp-pcon-head">Age</div>
                <div className="pcon-txt1">
                  {props.data.content_head2} months
                </div>
              </div>
              <div className="col-6 col-md-3 form-group">
                <div className="sp-pcon-head">Sex</div>
                <div className="pcon-txt1">{props.data.content_head5}</div>
              </div>
              <div className="col-6 col-md-3 form-group">
                <div className="sp-pcon-head">Location</div>
                <div className="pcon-txt1">
                  {productDetails !== undefined && productDetails.state},{" "}
                  {props.data.location}
                </div>
              </div>
              <div className="col-6 col-md-3 form-group">
                <div className="sp-pcon-head">Items</div>
                <div className="pcon-txt1">
                  {props.data.lotcount == 1 ? "1" : props.data.lotcount - 1}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-3">
            <div className="lv-gray-box">
              {/* <div className="ends-txt">ENDS IN</div> */}

              <div className="ends-timer">
                {props.data.date_added === null ||
                props.data.date_closed === null ? (
                  <span>Pending Approval</span>
                ) : (
                  <Timer
                    date_added={props.data.date_added}
                    date_closed={props.data.date_closed}
                    withText={1}
                  ></Timer>
                )}
              </div>

              <PrimaryButton
                label="VIEW LOT"
                onsubmit={moveon(
                  props.data.id,
                  props.data.lotcount,
                  props.data.modelnumber
                )}
              />
            </div>
            <p className="rating-txt">
              <span class="material-icons">star_half</span> 4.5
            </p>
          </div>
        </div>
      </div>
      <FFModal
        open={modalopen1}
        handleClose={toggleModal1}
        modaltitle="Message"
      >
        <div style={{fontWeight:'500'}}>
          <p className="card-text">
            If you wish to participate in the Sales Rooms please register
            {localStorage.getItem("userRole") === "tier1"
              ? " as Buyer."
              : ". Already Registered? Login here."}
          </p>

          <div className="modalCTA d-flex justify-content-end align-items-center">
            {localStorage.getItem("userRole") === "tier1" ? (
              <Button onClick={toggleModal1}>Cancel</Button>
            ) : (
              <Button onClick={moveone("log")}>Login</Button>
            )}

            <PrimaryButton label={"Register"} onsubmit={moveone("Reg")} />
          </div>
        </div>
      </FFModal>
      <FFModal
        open={modalopen2}
        handleClose={toggleModal2}
        modaltitle="Message"
      >
        <div style={{fontWeight:'500'}}>
          <p className="card-text">
            If you want to add Lot in favourite, please register. Already
            Registered? Login here.
          </p>

          <div className="modalCTA d-flex justify-content-end align-items-center">
            <Button onClick={moveone("log")}>Login</Button>

            <PrimaryButton label={"Register"} onsubmit={moveone("Reg")} />
          </div>
        </div>
      </FFModal>
    </div>
  );
};

export default AuctionList;
