import React, { useEffect, useState } from "react";
import "./AuctionCard.css";
import FavoriteCheckbox from "../FavouriteCheck/FavouriteCheckbox";
import { callNumber, IMAGE_URL } from "../../Utils/constant";
import Timer from "../../Utils/Timer";
import { Button } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { isAuthenticated } from "../../Utils/Authenticated";
import { id } from "date-fns/locale";
import FFModal from "../Modal";
import { SaleNumber, ItemNum } from "../../Utils/TimerProfile";
import PrimaryButton from "../Inputs/PrimaryButton";
import { request } from "../../Utils/Request";
import { parse } from "date-fns";

const AuctionCard = (props) => {
  const [modalopen1, setModalopen] = useState(false);
  const [modalopen2, setModalopen1] = useState(false);
  const [productDetails, setproductDetails] = useState();

  useEffect(() => {
    //getProductDetails();
  }, []);

  let history = useHistory();
  const moveon = (id, count, mn) => (e) => {
    // if (
    //   !isAuthenticated() /*|| localStorage.getItem("userRole") === "tier1" */
    // ) {
    //   setModalopen(!modalopen1);
    // } else 
    if (count > 1) {
      // window.location.href = "/multi-items-view/" + id;
      return history.push({ pathname: "/multi-items-view/" + id, state: mn });
    } else {
      // window.location.href = "/product-view/" + id;
      return history.push({ pathname: "/product-view/" + id, state: mn });
    }
  };

  const getProductDetails = async () => {
    const res = await request(
      "post",
      "/getprodetails",
      { product_id: props.data.id },
      false,
      true
    );

    if (res.data.success === "yes") {
      setproductDetails(res.data.results);
    }
  };

  //console.log("Searched Data____________", props.data);
  const favchange = (e) => {
    if (!isAuthenticated()) {
      props.setModalopen(!props.modalopen2);
    } else {
      props.onchange(e);
    }
  };

  const moveone = (name) => (e) => {
    if (name == "log") {
      window.location.href = "/login";
    } else {
      if (localStorage.getItem("userRole") === "tier1") {
        window.location.href = "/Reg_tier_2";
      } else {
        window.location.href = "/Reg_tier_1";
      }
    }
  };
  const toggleModal1 = (e) => {
    // console.log("++++++++++")
    setModalopen1(false);
    setModalopen(false);
  };

  return (
    <div className="auctionCard GridView">
      <div className="acImg">
        <FavoriteCheckbox
          favIdChild={props.data.id}
          onCall={(e) => favchange(e)}
          watchlisted={props.data.watchlisted}
        />
        <Button
          onClick={moveon(
            props.data.id,
            props.data.lotcount,
            props.data.modelnumber
          )}
        >
          <img src={IMAGE_URL + props.data.file_name}></img>
        </Button>
        <span className="no-items">
          {" "}
          {props.data.lotcount == 1 ? "1" : props.data.lotcount - 1} items
        </span>
      </div>
      <div className="acTimer d-flex justify-content-center align-items-center">
        <span class="material-icons">timer</span>
        {props.data.date_added === null || props.data.date_closed === null ? (
          <span>Pending Approval</span>
        ) : (
          <Timer
            date_added={props.data.date_added}
            date_closed={props.data.date_closed}
            withText={1}
          ></Timer>
        )}
      </div>
      <div className="acTitle">
        <Button
          onClick={moveon(
            props.data.id,
            props.data.lotcount,
            props.data.modelnumber
          )}
        >
          {" "}
          <h1 className="">{props.data.title}</h1>{" "}
        </Button>
        <p>
          Sale No:{" "}
          {SaleNumber(
            props.data.location,
            props.data.date_added,
            props.data.date_closed
          ) +
            props.data.auctionid
              .toString()
              .slice(props.data.auctionid.toString().length - 4)}
        </p>
      </div>
      <div className="acPrice d-flex justify-content-between align-items-center">
        <p>
          Bid{" "}
          <span
            style={
              parseInt(props.data.latestbid) < parseInt(props.data.rprice) ||
              props.data.latestbid == null
                ? { color: "rgb(244, 67, 54)" }
                : { color: "var(--primColor)" }
            }
          >
            € {props.data.latestbid ? callNumber(props.data.latestbid) : 0}
          </span>
        </p>
        <p>
          Avg. Weight:{" "}
          {props.data.lotcount == "1"
            ? props.data.weight
            : callNumber(Math.round(props.data.avgweight))}{" "}
          kgs
        </p>
      </div>

      <div className="acMisc d-flex justify-content-between align-items-center">
        <p>
          <span class="material-icons">location_on</span>
          {props.data.state},{" "}
          {props.data.location}
        </p>
        <p>
          <span class="material-icons">star_half</span>4.5
        </p>
      </div>

      <FFModal
        open={modalopen1}
        handleClose={toggleModal1}
        modaltitle="Message"
      >
        <div style={{fontWeight:'500'}}>
          <p className="card-text">
            If you wish to participate in the Sales Rooms please register
            {localStorage.getItem("userRole") === "tier1"
              ? " as Buyer."
              : ". Already Registered? Login here."}
          </p>

          <div className="modalCTA d-flex justify-content-end align-items-center">
            {localStorage.getItem("userRole") === "tier1" ? (
              <Button onClick={toggleModal1}>Cancel</Button>
            ) : (
              <Button onClick={moveone("log")}>Login</Button>
            )}

            <PrimaryButton label={"Register"} onsubmit={moveone("Reg")} />
          </div>
        </div>
      </FFModal>
    </div>
  );
};

export default AuctionCard;
