import React, { useState, useEffect } from "react"; 
import { Link } from "react-router-dom"; 
import FFModal from "../../components/Modal";
import {TextField ,InputAdornment, FormHelperText, makeStyles} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { GreenRadio } from "../../components/Inputs/RadioBox";
import {Toast, Popup} from "../../Utils/Notifications"
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import { Button } from "@material-ui/core";
import { Details, TonalitySharp, TramRounded } from "@material-ui/icons";
import { request } from "../../Utils/Request";
import JwtDecode from "jwt-decode";
import imageCompression from "browser-image-compression";
import { isSameDay, parse, roundToNearestMinutes, setDate, max } from "date-fns";
import { isAuthenticated } from "../../Utils/Authenticated";
import { IMAGE_URL, NumberOnly, AvatarURL, callNumber } from "../../Utils/constant";
import { validatePickup } from "../../Utils/Validators";
import UseForm from "../../Utils/UserForm";
import { TimetoDate, TimetoDay, TimetoDays, TimeDifference } from "../../Utils/TimerProfile";
import moment from 'moment'
import Upload from "../../components/Upload";
import "./HaulierDashboard.css";
import DatePicker from "../../Utils/DatePicker";
import CheckBox from "../../components/Inputs/CheckBox";

const useStyle = makeStyles({
  italic: {
    fontStyle: "italic"
  },
  bold: {
    fontWeight: "bold"
  },
  ellipses: {
    textOverflow: "ellipses",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "inherit"
  }
})




const Pickup = () => {  

  const classes = useStyle();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [pickup, setPickup] = useState(false);
  const [modalopen5, setModal5] = useState(false);
  const [modalopen6, setModal6] = useState(false);
  const [modalopen7, setModal7] = useState(false);
  const [modalopen8, setModal8] = useState(false);


  const [state1,setState1]=useState({
       details:[],
       pick:""
  })
  // const [details,setDetails]=useState([])
  const[dis,setDis]=useState(false)
  const[terms,setTerms]=useState(false)
  const toggleModal = () => setModal2(!modal2);
  
   const toggleModal5=()=>{
    
     setModal5(false)
     setModal6(false)
     setModal(false)
   }
   const toggleModal6=()=>{
    if(!terms){
     

      
      return setValues({...values,ter:"Please accept the Conditions"})
     
   }
     setModal5(false)
     setModal6(false)
    setTerms(false)
    //  setModal(false)
   }
   const toggleModal7=()=>{
    setModal5(false)
    setModal6(false)
    setValues({...values,weight_a:""})
   }
   const toggleModal8=()=>{
  
    setModal7(false)
    
   }
   const toggleModal9=()=>{
  
    setModal8(false)
    setValues({...values,cnumber:""})
    
   }
   const confirmNumberpd=()=>{
    if(!terms){
       return setValues({...values,ter:"Please accept the Conditions"})
    }
    setModal8(false)
    setTerms(false)

   }

   const confirmpopup=()=>{
     console.log("+++++++++++",state1.pick.numberofitems)
    if(state1.pick.numberofitems != values.cnumber && values.cnumber != '')
    {
      setModal8(true)
    }
   }


  const confirmDelivery = (data)=>(e)=>{
     setModal(!modal);
     setState1({...
      state1,pick:data})
     setValues({...values,cnumber:"",weight_b:"",weight_a:"",notes:"",sign:"",documet:"",ter:"",saleMethod:data.content_head3})
     setTerms(false)
     setState(false)
  }

  const formSubmit=()=>{
   toggleModal8()
   toggleModal5()
     if(pickup){
       cpickup()
      //  cdelivery()
     } 
     else{
       cdelivery()
     } 
    //  getdetails() 
  }

  const numberpopup=()=>{
    if (state1.pick.content_head3 == "Not Sold on Weight"){
      return false;
    } else {
    var d="";
    if(parseInt(values.weight_b) < parseInt(values.weight_a)){
      d=parseInt(values.weight_a)-parseInt(values.weight_b)
    }
    else{
      d=parseInt(values.weight_b)-parseInt(values.weight_a)
    }
    var disparity = 0
    if(d < parseInt(state1.pick.totalw)){
      disparity=parseInt(state1.pick.totalw)-d
    }
    else{
      disparity=d-parseInt(state1.pick.totalw)
    }
    
    console.log('numberpopup',disparity , parseInt(state1.pick.totalw*10/100))
    if(disparity > parseInt(state1.pick.totalw*10/100))
    {
        setModal5(true) 
    }
    else{
      //setModal6(true)
    }
    }
  }

  const numberpopup1=()=>{
    var d="",d1="";
    if(parseInt(values.weight_b) < parseInt(values.weight_a)){
      d=parseInt(values.weight_a)-parseInt(values.weight_b)
    }
    else{
      d=parseInt(values.weight_b)-parseInt(values.weight_a)
    }
    if(parseInt(d) < parseInt(state1.pick.totalw)){
      d1=parseInt(state1.pick.totalw)-parseInt(d)
    }
    else{
      d1=parseInt(d)-parseInt(state1.pick.totalw)
    }
    if(parseInt(d1) > parseInt(state1.pick.totalw*10/100)){
          setModal7(true)
    }
    // else{
    //   console.log("===================",d1,d,state1.pick.totalw)
    // }
  }

  var { handleSubmit, handleChange, values, errors,state,setValues,setState } = UseForm(
    formSubmit,
    validatePickup
  );

  const [options] = useState({
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  });
  
   const handleChangedoc=(e)=>{
       console.log("++++++++",e.target.files)
      // setValues({...values,document:e.target.files[0]})
      Object.keys(e.target.files).map(async (value, key) => {
        var img = await imageCompression(e.target.files[key], options);
        setValues((prevState) => ({
          ...prevState,
          documet:img,
        }));
      // setValues({ ...values,documet: [...values.documet, e.target.files]});
   })
  }
  const handleDateChange = async(e,key,id)=>{
    console.log("++++++",e,key,id)
    // const res = await request("post","/updatehauliertime",{vid:id,name:key,time:e})
    // getdetails()
   
    // setValues({...values})
    // var da=e;
    if(key==="sellertime"){
      // da.setHours(da.getHours()+2)
      values.sellertime.splice(id,1,e)
      const m=new Date(e)
      m.setHours(m.getHours()+4)
      values.sellertime_end.splice(id,1,m)
      setValues({...values})
    }
    else if(key==="buyertime"){
      // da.setHours(da.getHours()+2)
      values.buyertime.splice(id,1,e)
      const m=new Date(e)
      m.setHours(m.getHours()+4)
      values.buyertime_end.splice(id,1,m)
      setValues({...values})
    }
    else{
    values[key].splice(id,1,e)
    setValues({...values})
    }

  }
  
  const removeImage=(e)=>{
    setValues({...values,documet:""})
  }

  const confirmPickup = (data)=>(e) => {
    setPickup(!pickup);
    setModal(!modal);
    setState1({...state1,pick:data})
    setValues({...values,cnumber:"",weight_b:"",weight_a:"",notes:"",sign:"",documet:"",ter:"",saleMethod:data.content_head3})
    setTerms(false)
    setState(false)
  }
  const confirmAccept=(id,key)=>async(e)=>{

    console.log("TTTTTTTTTTT",TimetoDays(values.sellertime[key]),TimetoDays(values.sellertime_end[key]),TimetoDays(values.buyertime[key]),TimetoDays(values.buyertime_end[key]))
 
    if(values.sellertime[key]=="" || values.sellertime_end[key]=="" || values.buyertime[key]=="" || values.buyertime_end[key]=="")
    {
      return Popup.fire({type:"warning",title:"Please select Collection time and Delivery time"})
    }
    else if(moment(values.sellertime[key]).format("MM/DD/YYYY")!=moment(values.sellertime_end[key]).format("MM/DD/YYYY") || moment(values.sellertime[key]).format("MM/DD/YYYY")!=moment(values.buyertime[key]).format("MM/DD/YYYY") || moment(values.buyertime_end[key]).format("MM/DD/YYYY")!=moment(values.sellertime_end[key]).format("MM/DD/YYYY") || moment(values.buyertime[key]).format("MM/DD/YYYY")!=moment(values.buyertime_end[key]).format("MM/DD/YYYY"))
    {
      return Popup.fire({type:"warning",title:"The Collection and Delivery are on different days, are you sure you wish to proceed with this?"})
    }
    else if(TimetoDays(values.sellertime[key]) < TimetoDays(values.sellertime_end[key])){
      return Popup.fire({type:"warning",title:"Cannot proceed as collection start time is after collection end time, please amend"})
    }
    else if(TimetoDays(values.sellertime[key]) < TimetoDays(values.buyertime[key]) ||  TimetoDays(values.sellertime_end[key]) < TimetoDays(values.buyertime[key]) || TimetoDays(values.sellertime[key]) < TimetoDays(values.buyertime_end[key]) || TimetoDays(values.sellertime_end[key]) < TimetoDays(values.buyertime_end[key])){
      return Popup.fire({type:"warning",title:"Delivery is within the collection time window, is this correct?",showConfirmButton:true,showCancelButton:true,timer:false,confirmButtonText:"Yes",cancelButtonText:"No",confirmButtonColor:"#76bc43"})
      .then(async(response)=>{
        if(response.value){
          const res=await request("post","/updateaccept",{haulier_id:id, sellertime: values.sellertime[key],sellertime_end: values.sellertime_end[key],buyertime: values.buyertime[key],buyertime_end: values.buyertime_end[key]})
          if(res.data && res.data.success==="yes"){
            getdetails()
            Toast.fire({type:"success",title:"accept updated by admin"})
             
          }
        }
      })
    }
    else if(TimetoDays(values.buyertime[key]) < TimetoDays(values.buyertime_end[key])){
      return Popup.fire({type:"warning",title:"Cannot proceed as delivery start time is after delivery end time, please amend"})
    }
    else if(parseInt(TimeDifference(values.buyertime[key],values.sellertime_end[key])) < 1){
       return Popup.fire({type:"warning",title:"Delivery is less than 1 hour after collection, is this correct?",showConfirmButton:true,showCancelButton:true,timer:false,confirmButtonText:"Yes",cancelButtonText:"No",confirmButtonColor:"#76bc43"})
        .then(async(response)=>{
          if(response.value){
            const res=await request("post","/updateaccept",{haulier_id:id, sellertime: values.sellertime[key],sellertime_end: values.sellertime_end[key],buyertime: values.buyertime[key],buyertime_end: values.buyertime_end[key]})
            if(res.data && res.data.success==="yes"){
              getdetails()
              Toast.fire({type:"success",title:"accept updated by admin"})
               
            }
          }
        })
        
    }
    else if(parseInt(TimeDifference(values.buyertime[key],values.sellertime_end[key])) > 6){
      return Popup.fire({type:"warning",title:"Delivery is greater than 6 hours after collection, is this correct?",showCancelButton:true,showConfirmButton:true,timer:false,confirmButtonText:"Yes",cancelButtonText:"No",confirmButtonColor:"#76bc43"})
      .then(async(response)=>{
        if(response.value){
          const res=await request("post","/updateaccept",{haulier_id:id, sellertime: values.sellertime[key],sellertime_end: values.sellertime_end[key],buyertime: values.buyertime[key],buyertime_end: values.buyertime_end[key]})
          if(res.data && res.data.success==="yes"){
            getdetails()
            Toast.fire({type:"success",title:"accept updated by admin"})
             
          }
        }
      })
    }
             
    const res=await request("post","/updateaccept",{haulier_id:id, sellertime: values.sellertime[key],sellertime_end: values.sellertime_end[key],buyertime: values.buyertime[key],buyertime_end: values.buyertime_end[key]})
     if(res.data && res.data.success==="yes"){
       Toast.fire({type:"success",title:"accept updated by admin"})
        getdetails()
     }
  }

  const popupdata = (data) => (e) => {
    setModal2(!modal2);
    setState1({ ...state1, pick: data });
  };
  
  const getdetails=async()=>{
    var arr=[],t=0;
    
     var data={
      delivered:"delivery",
      user_id:JwtDecode(isAuthenticated()).id
     }
      const res=await request("post","/getHaulierDetails",data,false,true)
       if(res.data && res.data.success=="yes"){
          res.data.data.map((val,key)=>{
            
              values.sellertime.push(val.sellertime?val.sellertime:"")
              values.sellertime_end.push(val.sellertime_end?val.sellertime_end:"")
              values.buyertime.push(val.buyertime?val.buyertime:"")
              values.buyertime_end.push(val.buyertime_end?val.buyertime_end:"")

              arr=val.weight.split(",");
              arr.map((v)=>{
                t=t+parseInt(v)
              })        
              res.data.data[key].totalw=t;
              res.data.data[key].avw=parseFloat(t/arr.length).toFixed(2);
              t=0;
          
            
              var sex=[],m="";
              sex= res.data.data[key].sex.split(",");
               sex.map((val,key)=>{
                    if(sex[0]!==sex[key] && sex[key]!==""){
                       m="Mixed"
                    } 
              })
              if(m=="Mixed")
              {
                  res.data.data[key].sex="Mixed"
              }
              else{
                res.data.data[key].sex=sex[0];
              }

              var breed=[],m="";
              breed=res.data.data[key].breed.split(",");
              breed.map((val,key)=>{
                    if(breed[0]!==breed[key] && breed[key]!==""){
                       m="Mixed"
                    } 
              })
              if(m=="Mixed")
              {
                  res.data.data[key].breed="Mixed"
              }
              else{
                res.data.data[key].breed=breed[0];
              }

            var type = [], m = "";
            if (res.data.data[key].type != "" && res.data.data[key].type != null) {
              type= res.data.data[key].type.split(",");
            }  
              type.map((val,key)=>{
                    if(type[0]!==type[key] && type[key]!==""){
                       m="Mixed"
                    } 
              })
              if(m=="Mixed")
              {
                  res.data.data[key].type="Mixed"
              }
              else{
                res.data.data[key].type=type[0];
              }


              var age=[],age1=[];
              age1= res.data.data[key].dob.split(",");
              age1.map((key)=>{
                    if(key!==""){
                      age.push(parseInt(TimetoDate(key).toString() == 'NaN' ?0:TimetoDate(key)))
                    } 
              })
              var min=age.reduce(function(a,b) {
                return Math.min(a,b)
              })
              var max=age.reduce(function(a,b) {
                return Math.max(a,b)
              })
            
              res.data.data[key].dob=min+"-"+max;

          

            })

         setState1({...state1,details:res.data.data})
         setValues({...values})
          // setDate(res.data.data)

         setDis(true)
       }
    }

   



    const cpickup=async()=>{



     const formData = new FormData()
     formData.set("haulier_id",state1.pick.id)
     formData.set("number_p",values.cnumber)
     formData.set("weight_b_p",values.weight_b)
     formData.set("weight_a_p",values.weight_a)
     formData.set("time_p",moment(new Date()).format("YYYY-MM-DD hh:mm:ss"))
     formData.set("s_notes",values.notes)
     formData.set("s_sign",values.sign)
     formData.set("lotid",state1.pick.lotid)
     formData.set("sellerid",state1.pick.sellerid)
     formData.set("buynowid",state1.pick.invoiceid)
     
    //  values.documet.map((val)=>
       formData.append("document_p",values.documet)
    //  )
 
        
        const res=await request("post","/updatepickup",formData,false,true)
        console.log("+++",res.data.success)
        if(res.data.success)
        {
          Toast.fire({type:"success",title:"Confirm pickuped sucessfully"})
          // confirmPickup("")
          setPickup(!pickup);
          // setModal(!modal);
          // setValues({...values,cnumber:"",weight_b:"",weight_a:"",notes:"",sign:"",documet:""})
          getdetails()
          
        } else {
          Toast.fire({type:"error",title:res.data.message})
        }
      }
      
    const cdelivery=async()=>{
      const formData = new FormData()
     formData.set("haulier_id",state1.pick.id)
     formData.set("number_d",values.cnumber)
     formData.set("weight_b_d",values.weight_b)
     formData.set("weight_a_d",values.weight_a)
     formData.set("time_d",moment(new Date()).format("YYYY-MM-DD hh:mm:ss"))
     formData.set("b_notes",values.notes)
     formData.set("b_sign",values.sign)
     formData.set("buyerid",state1.pick.buyerid)
     formData.set("user_role","haulier")
     formData.set("lotid",state1.pick.lotid)
     formData.set("buynowid",state1.pick.invoiceid)
     
     
    //  values.documet.map((val)=>
       formData.append("document_d",values.documet)
    //  )
      
      
      const res=await request("post","/updatedelivery",formData,false,true)

        if(res.data.success){
          Toast.fire({type:"success",title:"Confirm delivered sucessfully"})
          // confirmPickup("")
          setPickup(!pickup);
          // setModal(!modal);
         
          getdetails()
        } else {
          Toast.fire({type:"error",title:res.data.message})
        }
    }

  useEffect(()=>{
    // setValues({...values,})
    getdetails()
    
  },[])

  return (
    <div>

    <p className="label-txt">
      Pick-Up/Delivery datetime is in 4 hours range.
    </p>
    <div className="ff-resp-table haulierData cstmTblWrp table-responsive">
    <table className="table w-100">
      <thead className="mobile-hide"> 
    
        <tr style={{lineHeight:"16px"}}>
            
          <th >#</th>
          <th >Lot number</th>
          <th >Seller</th>
          <th >Buyer</th>
          <th >
            <div>#Animals,</div>
            <div>Avg. weight,</div>
            <div>Total weight</div>
          </th>
          <th className="wd-175" >
            Action
          </th>
          <th className="wd-100" >
            Status
          </th>
          
        </tr>
        
      
      </thead>
      <tbody> 
        {console.log("++++++++",dis?state1.details:"")}
        {dis && state1.details.map((data,key)=>(
        <tr>
          <td className="resp-td" data-label="#">
           <div className="rtc-wrap">{key+1}</div>
          </td>
          <td className="resp-td ltNbrWrp" data-label="Lot number">
           <div className="rtc-wrap">
            <Link to={data.numberofitems==="1"?"/product-view/"+data.lotid:"/multi-items-view/"+data.lotid} className="green-txt">{"#"+data.lotid}</Link>
           </div>
          </td>
          <td className="resp-td cntxtWrp" data-label="Seller">

            <div className="rtc-wrap">
              <div className={classes.bold}>{data.sellername.charAt(0).toUpperCase() + data.sellername.slice(1)}</div>
              <div className={`${classes.italic} cstmWrpCls`}>{data.selleraddress + ", " + data.sellercity + ", " + data.sellerstate + " " + data.sellerzip}</div>
              <div className="dtWrper">
              
              <DatePicker
                 
                  format={"MM/dd/yyyy HH:mm"}
                  disableFuture={false}
                  name="sellertime"
                  value={values.sellertime[key]}
                  onchange={handleDateChange}
                  id={key}
                  disabled={data.status=="awaiting"?false:true}
                  error={state ? !values.end_date && true : false}
                  helperText={state && errors.end_date}
                />{/* to */}<DatePicker
                 hidden
                format={"MM/dd/yyyy HH:mm"}
                disableFuture={false}
                name="sellertime_end"
                value={values.sellertime_end[key]}
                onchange={handleDateChange}
                id={key}
                disabled={data.status=="awaiting"?false:true}
                error={state ? !values.end_date && true : false}
                helperText={state && errors.end_date}
              /></div>
            </div>
          </td>
          <td className="resp-td cntxtWrp" data-label="Buyer">
            <div className="rtc-wrap">
              <div className={classes.bold}>{data.buyername.charAt(0).toUpperCase() + data.buyername.slice(1)}</div>
              <div className={`${classes.italic} cstmWrpCls`}>{data.buyeraddress + ", " + data.buyercity + ", " + data.buyerstate + " " + data.buyerzip}</div>
              <div className="dtWrper">
              <DatePicker
                 
                  format={"MM/dd/yyyy HH:mm"}
                  disableFuture={false}
                  // name="end_date"
                  value={values.buyertime[key]}
                  onchange={handleDateChange}
                  id={key}
                  name="buyertime"
                  disabled={data.status=="awaiting"?false:true}
                  error={state ? !values.end_date && true : false}
                  helperText={state && errors.end_date}
                />{/* to */}<DatePicker
                hidden
                format={"MM/dd/yyyy HH:mm"}
                disableFuture={false}
                // name="end_date"
                value={values.buyertime_end[key]}
                onchange={handleDateChange}
                id={key}
                name="buyertime_end"
                disabled={data.status=="awaiting"?false:true}
                error={state ? !values.end_date && true : false}
                helperText={state && errors.end_date}
              /></div>
              </div>
          </td>
          <td className="resp-td" data-label="#Animals/Avg/Total Weight">
            <div className="rtc-wrap">
              <div>{data.numberofitems},</div>
              <div>{callNumber(Math.round(data.average_weight))}Kgs,</div>
              <div>{callNumber(Math.round(data.totalw))}Kgs</div>
            </div>
          </td>

         
          <td className="resp-td nwWrp" data-label="Action">
          <div className="rtc-wrap d-flex" style={{flexWrap:"nowrap"}}>
            {data.status=="awaiting"?
                <button className="gray-btn" onClick={confirmAccept(data.id,key)}>
                Accept 
           </button>
             :data.status=="active"? 
                <button className="gray-btn w-100" onClick={confirmPickup(data)} style={{minWidth:"130px"}}>
                     Confirm Pickup
                </button> :
                <button className="gray-btn w-100 d-flex justify-content-center align-items-center" onClick={confirmDelivery(data)} style={{minWidth:"130px"}}>
                     Confirm Delivery
                </button>
            } 
            <Link to={data.numberofitems==="1"?"/product-view/"+data.lotid:"/multi-items-view/"+data.lotid} className="gray-btn">
              <span className="material-icons">visibility</span>
            </Link>
            </div>
          </td>
          <td className="resp-td stsWrpr" data-label="Status">
          {data.status ==="awaiting"?
          <div className="rtc-wrap">
            Awaiting Confirmation
          </div>:data.status ==="active"?<div style={{color:"green"}} className="rtc-wrap">
             Active
          </div>:<div style={{color:"pink"}} className="rtc-wrap">
          In-transit
          </div>}
          </td>
        </tr> 
        ))}  
      </tbody>
    </table> 
    <FFModal 
       open={modal}
       handleClose={pickup ? confirmPickup("") : confirmDelivery("")}
       modaltitle={pickup ? "Confirm pickup" : "Confirm delivery"}
    >

      <div className="hdb-media" style={{fontWeight:'500'}}>
        <div className="hdb-media-left">
          <img src={IMAGE_URL+state1.pick.avatar} alt="Item title" className="dp-img" />
        </div>
        <div className="hdb-media-body">
          <div className="di-title-con">
        <div>{state1.pick.title}</div>
              <Link to={state1.pick.numberofitems==="1"?"/product-view/"+state1.pick.lotid:"/multi-items-view/"+state1.pick.lotid} className="dp-link">View Item</Link>
          </div>
          <div className="di-details">
              <div>
                <div>Sex</div>
        <div>{state1.pick.sex}</div>
              </div>
              <div>
                <div>Age</div>
        <div>{state1.pick.dob} Months</div>
              </div>
              <div>
                <div>Section</div>
        <div>{state1.pick.category_id}</div>
              </div>
              <div>
                <div>Type</div>
        <div>{state1.pick.type}</div>
              </div>
              <div>
                <div>Breed</div>
        <div>{state1.pick.breed}</div>
              </div>
          </div> 
          <div className="dp-con">
            <div>{pickup?"Confirm number pickup":"Confirm number delivered"}</div>
            <div className="cd-input">
              <TextField
                id="confirm-number"  
                variant="outlined"
                size="small"
                name="cnumber"
                onBlur={confirmpopup}
                InputProps={{onKeyPress:NumberOnly}}
                value={values.cnumber} 
                onChange={handleChange}
                error={state && !values.cnumber?true:false}
                helperText={state?errors.cnumber:""}
              /> 
            </div>
          </div>
          <div className="dp-con">
            <div>{pickup?"Tare weight before Loading":"Tare weight before Unloading"}</div>
            <div className="cd-input">
              <TextField
                id="weight-before"  
                variant="outlined"
                size="small"
                value={values.weight_b}
                name="weight_b"
                onChange={handleChange}
                error={values.saleMethod == "Not Sold on Weight" ? false : (state && !values.weight_b? true:false)}
                helperText={state?errors.weight_b:""} 
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" size="small"> 
                      Kg
                    </InputAdornment>
                  ),
                  onKeyPress:(NumberOnly)
                }} 
              /> 
            </div>
          </div>

          <div className="dp-con">
            <div>{pickup?"Tare weight after Loading":"Tare weight after Unloading"}</div>
            <div className="cd-input">
               <TextField
                id="weight-after"  
                variant="outlined"
                size="small"
                value={values.weight_a}
                name="weight_a"
                onChange={handleChange}
                onBlur={numberpopup}
                error={values.saleMethod == "Not Sold on Weight" ? false : (state && !values.weight_a? true:false)}
                helperText={state?errors.weight_a:""}  
               
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" size="small"> 
                      Kg
                    </InputAdornment>
                  ),
                 onKeyPress:(NumberOnly)
                }} 
              /> 
            </div>
          </div>
          <div className="dp-con">
            <div>Expected weight</div>
            <div>{parseInt(state1.pick.totalw)} kg</div>
          </div>
          <div className="dp-con">
            <div>Net weight</div>            
            {pickup?
            <div>{(parseInt(values.weight_a)-parseInt(values.weight_b)).toString()==="NaN"?"0":callNumber(Math.round(parseInt(values.weight_a)-parseInt(values.weight_b)))} kg</div>
             :<div>{(parseInt(values.weight_a)-parseInt(values.weight_b)).toString()==="NaN"?"0":callNumber(Math.round(parseInt(values.weight_b)-parseInt(values.weight_a)))} kg</div>}
            </div>
          <div className="dp-con">
            <div>Weight disparity</div>
            {pickup?
             <div>{(parseInt(state1.pick.totalw)-(parseInt(values.weight_a)-parseInt(values.weight_b))).toString()==="NaN"?"0":callNumber(Math.round((state1.pick.totalw-Math.abs(parseInt(values.weight_a)-parseInt(values.weight_b))))).toString()[0] === '-'?<span style={{color:"red"}}>{callNumber(Math.round((state1.pick.totalw-Math.abs(parseInt(values.weight_a)-parseInt(values.weight_b)))))}</span>:<span>{callNumber(Math.round((state1.pick.totalw-Math.abs(parseInt(values.weight_a)-parseInt(values.weight_b)))))}</span>} kg</div>
             :<div>{(parseInt(state1.pick.totalw)-(parseInt(values.weight_b)-parseInt(values.weight_a))).toString()==="NaN"?"0":callNumber(Math.round((state1.pick.totalw-Math.abs(parseInt(values.weight_b)-parseInt(values.weight_a))))).toString()[0] === '-'?<span style={{color:"red"}}>{callNumber(Math.round((state1.pick.totalw-Math.abs(parseInt(values.weight_b)-parseInt(values.weight_a)))))}</span>:<span>{callNumber(Math.round((state1.pick.totalw-Math.abs(parseInt(values.weight_b)-parseInt(values.weight_a)))))}</span>} kg</div>}
            </div>

          <div className="dp-con">
            <div>Average animal weight</div>
            <div>{callNumber(Math.round(state1.pick.average_weight))} kg</div>
          </div>
          <div className="custom-control custom-switch mt-2 mb-2">
            <input
              type="checkbox"
              className="custom-control-input"
              id="sd-time" 
              name="terms"
              onChange={handleChange}
              value={values.terms}


            />
          
            <label
              className="custom-control-label"
              htmlFor="sd-time"
            >
              <div style={{width:"80%"}}>{pickup?"Have you collected ID cards & Paperwork":"Have you delivered ID cards & Paperwork"}</div>
            </label>
            <FormHelperText error>{state && errors.terms}</FormHelperText>
          </div> 
          <div className="dp-con">
            <div>{pickup ? "Seller signature" : "Buyer signature"}</div>
            <div className="cd-input1">
              <Select
                native
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleChange}
                value={values.sign}
                name="sign"
              >
                  <option value="">Select</option>
                  <option value={"recived"}>Received</option>
                  
              </Select>
              {state?
              <FormHelperText error>{errors.sign}</FormHelperText>:""}
            </div>
          </div>
          <div className="dp-con">
            <div>{"Were any complaints raised by" +(pickup?" Seller ":" Buyer ")+ "at point of delivery?"}</div>
            <div>
                <RadioGroup
                    aria-label="truck-turn"
                    name="complaint"
                    className="fd-row" 
                    value={values.complaint||'no'}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes" 
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
            </div>
          </div> 
          <TextField
            id="notes"
            label="Notes"
            placeholder="Enter notes here"
            value={values.notes}
            name="notes"
            onChange={handleChange}
            error={state && !values.notes && values.complaint == 'yes'? true:false}
            helperText={state?errors.notes:""} 
            variant="outlined"
            multiline
            rows={4}
            fullWidth
          /> 

          <div className="upload-wrapper">
          
          <Upload
                  // src="/images/insert-photo.png"
                  header="UPLOAD DOCUMENT"
                  // subheader="You can upload multiple images"
                  id="upload-image"
                  title="Images displayed under each item"
                  accept="image/*"
                  // value={}
                  onchange={handleChangedoc}
                  multiple={true}
                  /* error={state}
                helperText = {state && errors.product_image} */
                />
              {state?
              <FormHelperText error>{errors.documet}</FormHelperText>:""}
              <div className="row">
                {console.log("dddddddddd",values.documet)}
                  {values.documet!==""?
                   
                      <div className="col-md-3 productPostThumbnails" style={{marginInlineStart:"275px"}}>
                        <label
                          onClick={e=>removeImage(e)}
                          // name={key}
                          className="pp-edit material-icons"
                        >
                          close
                        </label>
                        <img
                          style={{float:"center"}}
                          src={URL.createObjectURL(values.documet)}
                          className="pro-img rounded img-fluid"
                        />
                      </div>
                   :""}
                </div>
          </div>
      


          <div className="confirm-btn-wrap">
           
                       
            <PrimaryButton onsubmit={handleSubmit} label={pickup ? "CONFIRM PICKUP" : "CONFIRM DELIVERY"} />
          </div>
        </div>
      </div>
    </FFModal>

    <FFModal open={modal2} handleClose={toggleModal} modaltitle="Delivered">
        <div className="hdb-media" style={{fontWeight:'500'}}>
          <div className="hdb-media-left">
            <img
              src={IMAGE_URL + state1.pick.avatar}
              alt="Item title"
              className="dp-img"
            />
          </div>
          <div className="hdb-media-body">
            <div className="di-title-con">
              <div>{state1.pick.title}</div>
              <Link
                to={state1.pick.numberofitems==="1"?"/product-view/"+state1.pick.lotid:"/multi-items-view/"+state1.pick.lotid}
                className="dp-link"
              >
                View Item
              </Link>
            </div>
            <div className="di-details">
              <div>
                <div>Sex</div>
                <div>{state1.pick.sex}</div>
              </div>
              <div>
                <div>Age</div>
                <div>{state1.pick.dob} Months</div>
              </div>
              <div>
                <div>Section</div>
                <div>{state1.pick.category_id}</div>
              </div>
              <div>
                <div>Type</div>
                <div>{state1.pick.type}</div>
              </div>
              <div>
                <div>Breed</div>
                <div>{state1.pick.breed}</div>
              </div>
            </div>
            <div className="dp-con-head">Details before pickup</div>
            <div className="dp-con">
              <div>Number of livestocks</div>
              <div>{state1.pick.number_p}</div>
            </div>
            <div className="dp-con">
              <div>Number of livestocks collected</div>
              <div>{state1.pick.number_d}</div>
            </div>
            <div className="dp-con">
              <div>Tare weight before Loading</div>
              <div>{state1.pick.weight_b_p} kg</div>
            </div>
            <div className="dp-con">
              <div>Tare weight after Loading</div>
              <div>{state1.pick.weight_a_p} kg</div>
            </div>
            <div className="dp-con">
              <div>Expected weight</div>
              <div>
                {callNumber(Math.round(parseInt(state1.pick.weight_a_p) - parseInt(state1.pick.weight_b_p)))}{" "}
                kg
              </div>
            </div>
            <div className="dp-con">
              <div>Net weight</div>
              <div>
                {(state1.pick.weight_a_p == "" || state1.pick.weight_b_p == "") ? "0" : callNumber(Math.round(parseInt(state1.pick.weight_a_p) - parseInt(state1.pick.weight_b_p)))}{" "}
                kg
              </div>
            </div>
            <div className="dp-con">
              <div>Weight disparity</div>
              <div>
                {(state1.pick.weight_b_p == "" || state1.pick.weight_a_p == "") ? "0" : callNumber(Math.round(parseInt(state1.pick.totalw) - (parseInt(state1.pick.weight_b_p) - parseInt(state1.pick.weight_a_p))))}{" "}
                kg
              </div>
            </div>
            <div className="dp-con">
              <div>Average animal weight</div>
              <div>{callNumber(Math.round(state1.pick.average_weight))} kg</div>
            </div>
            <div className="dp-con">
              <div>Pickup time</div>
              <div>{moment(state1.pick.time_p).format("DD/MM/YYYY:hh:mm:ss")}</div>
            </div>
            <div className="dp-con">
              <div>
               Have you collected ID cards & Paperwork
              </div>
              <div>Yes</div>
            </div>
            <div className="dp-con">
              <div>
                Were any complaints raised by Seller at point of pickup ?
              </div>
              <div>No</div>
            </div>
            <div className="dp-con">
              <div>Seller signature</div>
              <div>
                <Button className="dp-link">Received</Button>
              </div>
            </div>
            <div className="dp-con">
              <div>Documents uploaded</div>
              <div>
              <a target={"_blank"} href={AvatarURL+state1.pick.document_p} className="dp-link">View</a>
              </div>
            </div>
            <div className="dp-con-head">Details after delivery</div>
            <div className="dp-con">
              <div>Number of livestocks</div>
              <div>{state1.pick.number_p}</div>
            </div>
            <div className="dp-con">
              <div>Number of livestocks collected</div>
              <div>{state1.pick.number_d}</div>
            </div>
            <div className="dp-con">
              <div>Tare weight before Loading</div>
              <div>{state1.pick.weight_b_d} kg</div>
            </div>
            <div className="dp-con">
              <div>Tare weight after Loading</div>
              <div>{state1.pick.weight_a_d} kg</div>
            </div>
            {/* <div className="dp-con">
              <div>Expected weight</div>
              <div>{state1.pick.e_weight} kg</div>
            </div> */}
            <div className="dp-con">
              <div>Expected weight</div>
              <div>
                {parseInt(state1.pick.weight_a_d) -
                  parseInt(state1.pick.weight_b_d)}{" "}
                kg
              </div>
            </div>
            <div className="dp-con">
              <div>Net weight</div>
              <div>
                {(state1.pick.weight_a_d == "" || state1.pick.weight_b_d == "") ? "0" : 
                parseInt(state1.pick.weight_a_d) -
                  parseInt(state1.pick.weight_b_d)}{" "}
                kg
              </div>
            </div>
            <div className="dp-con">
              <div>Weight disparity</div>
              <div>
                {(state1.pick.weight_b_d == "" || state1.pick.weight_a_d == "") ? "0" : 
                parseInt(state1.pick.totalw) -
                  (parseInt(state1.pick.weight_b_d) -
                    parseInt(state1.pick.weight_a_d))}{" "}
                kg
              </div>
            </div>
            <div className="dp-con">
              <div>Average animal weight</div>
              <div>{callNumber(Math.round(state1.pick.average_weight))} kg</div>
            </div>
            <div className="dp-con">
              <div>Delivery time</div>
              <div>{moment(state1.pick.time_d).format("DD/MM/yyyy:hh:mm:ss")}</div>
            </div>
            <div className="dp-con">
              <div>
                Have you delivered ID cards & Paperwork
              </div>
              <div>Yes</div>
            </div>
            <div className="dp-con">
              <div>
                Were any complaints raised by Seller at point of pickup ?
              </div>
              <div>No</div>
            </div>
            <div className="dp-con">
              <div>Buyer signature</div>
              <div>
              <Button className="dp-link">Received</Button>
              </div>
            </div>

            <div className="dp-con">
              <div>Documents uploaded</div>
              <div>
                <a target={"_blank"} href={AvatarURL+state1.pick.document_d} className="dp-link">View</a>
              </div>
            </div>
            
          </div>
        </div>
      </FFModal>
     </div>
      <FFModal
          open={modalopen5}
          handleClose={toggleModal7}
          modaltitle="Haulier Declaration"
        >
            <div className="seller-note" style={{fontWeight:'500'}}>  
             <p>
                {"if weight is >+/-10% outside range. Please call Farm Fayre for further instruction - +353 (0)56 7801 699"}
              </p>
              </div>
              <div className="jc-center">
              <CheckBox name="terms" onchange={e=>setTerms(e.target.checked)}  value={terms}   label="Did you contact Farm Fayre"/>
              <div className="pl-btns">
            <FormHelperText error>{values.ter?values.ter:""}</FormHelperText>
            <PrimaryButton label={"Continue"} btnSize={"smallsize"} onsubmit={toggleModal6} />
            </div>
            </div>
          </FFModal>
          <FFModal
          open={modalopen6}
          handleClose={toggleModal7}
          modaltitle="Haulier Declaration"
        >
            <div className="seller-note" style={{fontWeight:'500'}}>  
             <p>
                {"Please call Farm Fayre for further instruction - +353 (0)56 7801 699"}
              </p>
              </div>
              <div className="jc-center">
              <CheckBox name="terms" onchange={e=>setTerms(e.target.checked)}  value={terms}  label="Did you contact Farm Fayre"/>
              <div className="pl-btns">
            <FormHelperText error>{values.ter?values.ter:""}</FormHelperText>
            <PrimaryButton label={"Continue"} btnSize={"smallsize"} onsubmit={toggleModal6} />
            </div>
            </div>
          </FFModal>
          
          <FFModal
          open={modalopen7}
          handleClose={toggleModal8}
          modaltitle="Haulier Declaration"
        >
            <div className="seller-note" style={{fontWeight:'500'}}>  
             <p>
                  As the weight is outside the acceptable 10% range please contact Farm Fayre
              </p>
              </div>
              <div className="jc-center">
              <CheckBox name="terms" onchange={e=>setTerms(e.target.checked)}  value={terms}  label="Did you contact Farm Fayre?Are you authorised to proceed?"/>
              <div className="pl-btns">
            <FormHelperText error>{values.ter?values.ter:""}</FormHelperText>
            <PrimaryButton label={"Continue"} btnSize={"smallsize"} onsubmit={formSubmit} />
            </div>
            </div>
          </FFModal>
          

          <FFModal
          open={modalopen8}
         handleClose={toggleModal9}
          modaltitle="Haulier Declaration"
        >
            <div className="seller-note" style={{fontWeight:'500'}}>  
             <p>
                  Do not proceed. Contact Farm Fayre Immediately.
              </p>
              </div>
              <div className="jc-center">
              <CheckBox name="terms" onchange={e=>setTerms(e.target.checked)}  value={terms}  label="Did you contact Farm Fayre?Are you authorised to proceed?"/>
              <div className="pl-btns">
            <FormHelperText error>{values.ter?values.ter:""}</FormHelperText>
            <div className="modalCTA d-flex justify-content-end align-items-center">
              
            <Button onClick={toggleModal9}>No</Button>
            <PrimaryButton label={"Yes"} btnSize={"smallsize"} onsubmit={confirmNumberpd} />
            </div>
            </div>
            </div>
          </FFModal> 
          


    </div>


  );
};

export default Pickup;
