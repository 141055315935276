import React, { useState } from "react";
import { Link } from "react-router-dom";
import FFModal from "../Modal";
import CustomInput from "../Inputs/borderedInput";
import PrimaryButton from "../Inputs/PrimaryButton";
import UseForm from "../../Utils/UserForm";
import { validateCard } from "../../Utils/Validators";
import { request } from "../../Utils/Request";
import { Button } from "@material-ui/core";

import { Toast } from "../../Utils/Notifications";
import { Cd, NumberOnly } from "../../Utils/constant";
const AddNewCard = (props) => {
  const [values, setValues] = useState({
    card_name: "",
    card_number: "",
    cvv: "",
    date: "",
  });
  const [dis, setDis] = useState(false);
  const [errors, setErr] = useState([]);
  const [btnloading,setBtnloading]=useState(false)
  // const[errors,setError]=useState([])

  //  var { handleChange, handleSubmit, values, errors, state } = UseForm(submit, validateCard)

  const handleChange = (name) => async (e) => {
    var {value}=e.target;
    if(name==="date" && value.length=="2" && !values.date.toString().includes("/")){
               value=value+"/";        
    }
    setValues({ ...values, [name]:value });

    //  if(errors!="")
    //  {
    //   setErr(validateCard(values))
    //  }
  };
  const onPop = async (e) => {
    setDis(true);
  };

  async function submit() {
    var arr = [];
    arr.push(validateCard(values));

    console.log("+++", validateCard(values).condition);
    if (validateCard(values).condition) {
      setErr(validateCard(values));
    } else {
      setBtnloading(true)
      setErr(validateCard(values));
      var split = values.date.split('/')
      const data = {
        cardNumber: values.card_number.split(' ').join(''),
        card_name: values.card_name,
        expiryMonth: split[0],
        expiryYear:split[1].length=="4"?split[1]:20+split[1],
        cvv: values.cvv,
        amount: '0.50',
        isSave: true,
        capture: false,
        mode: process.env.REACT_APP_PAYMENT_MODE,
        currency: "eur",
      }
      

      try{
        const res = await request('post','/payAmountStripe', data, false, true)
        setBtnloading(false)
        if (!res.data.success) {
          setDis(false)
          Toast.fire({ type: 'error', title: res.data.message.error.message })
       }
       else{
        console.log("++++++++++++++++++",props.id)
        if(props.id==undefined || props.id==""){

         Toast.fire({ type: 'success', title: 'Your Card Added Successfully' })
         setDis(false)
         setValues({
           card_name: "",
           card_number: "",
           cvv: "",
           date: ""
          })
          props.calli()
       } 
       else{
         setDis(false)
        //  props.history.push()
         window.location.href="/multi-items-view/"+props.id
       }
      }
      } catch (error) {
        Toast.fire({ type: 'error', title: 'Try again later!' })
        console.log(error)
      }
       
    }
  }


  const onPopclose = async (e) => {
    setDis(false); 
    setValues({
      card_name: "",
      card_number: "",
      cvv: "",
      date: ""
     }) 
     setErr({})
  };

  return (
    <div className="address-card">
      <Button onClick={onPop} className="p-0">
        <div className="ac-radio text-center">
          <img src="/images/ccard.svg" alt="bank" className="mt-1 mb-1" />
          {/* <button onClick={onPop}>ADD A NEW CARD</button>  */}
          <h6>ADD A NEW CARD</h6>
          <img src="/images/cards.svg" alt="bank" className="mt-1 mb-1" />
        </div>
      </Button>

      <FFModal
        open={dis}
        handleClose={(e) => onPopclose(e)}
        modaltitle="Add a Card"
      >
        <div className="row" style={{fontWeight:'500'}}>
          <div className="col-12 form-group">
            
            <CustomInput
              label="Card Number"
              // error={state ? !values.address1 && true : false }
              placeholder="Enter your Card Number"
              tooltiptitle="Enter your Card Number"
              name="cardnumber"
              con={values.card_number.length===16?"dallow":true}
              // value= {values.address1}
              // onchange={handleChange}
              // helperText = {state && errors.address1}
              values={values.card_number}
              onchange={handleChange("card_number")}
              error={errors.card_number ? true : false}
              helperText={errors.card_number}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 form-group">
            <CustomInput
              label="Expiry Date"
              // error={state ? !values.address1 && true : false }
              placeholder="Enter your Expiry Date"
              tooltiptitle="Enter your Expiry Date"
              con={values.date.length===5 && "dallow"}
              // con="sn"
              InputProps={{
                onKeyPress:(Cd)
              }}
              name="date"
              value={values.date}
              onchange={handleChange("date")}
              error={errors.date ? true : false}
              helperText={errors.date}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 form-group">
            <CustomInput
              label="Card Name"
              // error={state ? !values.address1 && true : false }
              placeholder="Enter your card name"
              tooltiptitle="Enter your card name"
              con={false}
              name="cardname"
              values={values.card_name}
              onchange={handleChange("card_name")}
              error={errors.card_name}
              helperText={errors.card_name}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 form-group">
            <CustomInput
              label="CVV"
              // error={state ? !values.address1 && true : false }
              con={true}
              placeholder="Enter your cvv"
              tooltiptitle="Enter your cvv"
              con={values.cvv.length===3?"dallow":true}
              name="cvv"
              values={values.cvv}
              onchange={handleChange("cvv")}
              error={errors.cvv}
              helperText={errors.cvv}
            />
          </div>
        </div>
        <div className="pl-btn pt-4">
          <PrimaryButton label="SAVE" dis={btnloading} onsubmit={submit} />
        </div>
      </FFModal>
    </div>
  );
};

export default AddNewCard;
