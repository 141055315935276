import React, { useEffect, useState } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { GreenRadio } from "../../components/Inputs/RadioBox";
import { request } from "../../Utils/Request";
import { SentimentDissatisfied } from "@material-ui/icons";
import { callNumber, callNumber1 } from "../../Utils/constant";
import Popover from "@material-ui/core/Popover";
import { Link } from "@material-ui/core";

const CheckoutProduct = ({
  imgurl,
  aid,
  id,
  title,
  amount,
  tax,
  codPrice,
  total,
  amount1,
  amount2,
  wamount,
  wchange,
  wallet,
  haulierFee,
  loading,
  saleMethod,
  ...props
}) => {
  const [anchorE1, setAnchorE1] = useState(null);

  const [anchorE2, setAnchorE2] = useState(null);

  const [values, setValues] = useState({
    yard: "yes",
    setting: [],
  });

  const [dis, setDis] = useState(false);
  const handleChange = (e) => {
    setValues({ ...values, yard: e.target.value });
  };

  const popClick1 = (event) => {
    setAnchorE1(event.currentTarget);
  };

  const popClose1 = () => {
    setAnchorE1(null);
  };

  const popClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const popClose2 = () => {
    setAnchorE2(null);
  };

  const openPop1 = Boolean(anchorE1);
  const openPop2 = Boolean(anchorE2);

  // useEffect(()=>{
  // 	getDetails()

  // },[])

  return (
    <div className="card">
      <div className="card-body">
        <div className="media">
          <div className="media-left">
            <img src={imgurl} alt={title} className="cc-img" />
          </div>

          <div className="media-body">
            <div className="cp-item-id">Item ID: {id}</div>
            <div className="cp-item-id">Sale No: { aid}</div>
            <div className="cp-title">{title}</div>
            <div className="amt-label">
              Closing Bid Sale Amount:{" "}
              <span className="price-txt">{"€ " + callNumber1(amount)}</span>
            </div>
            {saleMethod && saleMethod != "Not Sold on Weight" ?
            <div className="amt-label" onClick={popClick1}>
              Adjustment Premium (10%):<span className="material-icons">help</span>
                <span className="price-txt">{"€ " + callNumber1(codPrice)}</span>
            </div> : null
            }
            <div className="amt-label">
              Farm Fayre Fees (inc VAT @23%):{" "}
              <span className="price-txt">{"€ " + (wallet=="yes"?callNumber1(parseFloat(parseFloat(amount1)-parseFloat(((amount1 - wamount) < 0)?amount1:wamount)).toFixed(2)):callNumber1(parseFloat(amount1).toFixed(2)))}</span>
            </div>
            <div className="amt-label">
              Trusted Haulier Network fees (incl. VAT @ 23%):{" "}
              <span className="price-txt">{"€ " + callNumber1(parseFloat(haulierFee).toFixed(2))}</span>
            </div>
            <div className="amt-label">
              Trustap (payment provider) fees:{" "}
              <span className="price-txt">{"€ " + callNumber1(parseFloat(amount2).toFixed(2))}</span>
            </div>
            <div className="amt-label">
           <div className="custom-control custom-switch mt-4">
                  <input
                    type="checkbox"
                    className="custom-control-input"
					id="one-click" 
					name="one-click"
					value={wallet=="yes"?"no":"yes"}
					checked={wallet=="yes"?true:false}
					onChange={wchange} 
                  />

                  <label
                    className="custom-control-label"
                    htmlFor="one-click"
                  >
                    <span >
                      Use wallet credit?: (€ {callNumber1(wamount)})
                    </span>
                  </label>
                </div>

            </div>
            <div className="amt-label green-txt brother-regular" hidden={loading} onClick={popClick2}>
              Pre-settlement transfer amount:<span className="material-icons">help</span>
              <span className="price-txt">{"€ " + (wallet=="yes"?callNumber1(parseFloat(parseFloat(total)-parseFloat(((amount1 - wamount) < 0)?amount1:wamount)).toFixed(2)):callNumber1(parseFloat(total).toFixed(2)))}</span>
            </div>
          </div>
        </div>

        <Popover
          open={openPop1}
          anchorEl={anchorE1}
          onClose={popClose1}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <p className="pover-wrap">
            The Buyer deposits an adjustment premium of 10% to cover any recalculations of estimated weights. Upon delivery of livestock final estimated weight taken and 
            funds settled.Don't worry any overpayment will be returned to you at point of settlement. Please see <a href="/faq" target="_blank">FAQs</a> for further information.
           
          </p>
        </Popover>
        <Popover
          open={openPop2}
          anchorEl={anchorE2}
          onClose={popClose2}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <p className="pover-wrap">
            Any over-payment, or outstanding funds, shall be returned at settlement. Please see <a href="/faq" target="_blank">FAQs</a>.
           
          </p>
        </Popover>
        <hr />
        <div className="truck-turn d-flex">
          <label className="cp-label">
            Can a truck access and turn in your yard?
          </label>
          <RadioGroup
            aria-label="truck-turn"
            className="fd-row"
            value={values.yard}
          >
            <FormControlLabel
              value="yes"
              onChange={handleChange}
              control={<GreenRadio />}
              label="Yes"
            />
            <FormControlLabel
              value="no"
              onChange={handleChange}
              control={<GreenRadio />}
              label="No"
            />
          </RadioGroup>
        </div>
      </div>
    </div>
  );
};

export default CheckoutProduct;
