import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { request } from "../../Utils/Request";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Sticky from "../Sticky";
import SearchOptions from "../Inputs/SearchOptions";
import {
  Section,
  Type,
  Breed,
  Weight,
  Sex,
  Age,
  Provinces,
  Crossbreed,
} from "../../Utils/constant";
import "./SearchFilters.css";
import { id } from "date-fns/locale";
import { FlashOnOutlined, Search, TramRounded } from "@material-ui/icons";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import InputField from "../Inputs/InputField";
import PrimaryButton from "../Inputs/PrimaryButton";
import { Toast } from "../../Utils/Notifications";
import { Link } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormHelperText, TextField } from "@material-ui/core";
import FFModal from "../Modal";
import { isAuthenticated } from "../../Utils/Authenticated";
import CustomSelect from "../Inputs/borderedSelect";
import CustomInput from "../Inputs/InputField"
/* const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: "100px",
    maxWidth: 200,
    width: "100%",
    height: 45,
    marginRight:0,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: "var(--primColor)",
    "&$checked": {
      color: "var(--primColor)",
    },
    MuiFormControlLabelRoot: {
      marginBottom: 0,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
 */

const SearchFilters = (props) => {
  const [initialvalue1, setinitialvalue1] = useState({
    live: false,
    upcomming: false,
    All: true,
    search:false,
    searchbar:"",
    searchbarfilter:''
  });
  const [values,setValues]=useState({
    collection_breed:"",
    join_breed:"",
    bre:""
  })
  const [searc,setSearch]=useState([])
  const [searcfill,setSearchfill]=useState([])
  const [initialvalue, setinitialvalue] = useState({
    /* sh_limit: 8, */
    category: [], //Sections filter
    location: [], // Province search
    subcategory: [], //type is covered
    weight: [], //Weight needs to be mapped
    contenthead5: [], //Sex filter
    contenthead2: [], //DOB to be mapped as inventorydate or a new date
    contenthead1: [], //Breed filter
    contenthead3: [],
    orderby:"",
    fromprice: "",
    toprice: "",
    Qa:[], //??
    export: [], //??
    searchbar: "", //Keyword
    sh_limit:""
  });
  const [open, setOpen] = React.useState(false);
  const [openfilter, setOpenfilter] = React.useState(false);
  const [savesea,setSavesearch]=useState(false)
  const [savesearchname,setSavesearchname]=useState('')
  const[modalopen3,setModalopen3]=useState(false)
  const[modalopen4,setModalopen4]=useState(false)
  const handleClickOpen = () => {
    setOpen(true);
  };
  const toggleModal3=()=>{
    setModalopen3(!modalopen3)
  }
  const toggleModal4=()=>{
    setModalopen4(!modalopen4)
  }

  const handleClose = () => {
    setOpen(false);
  };
  const callcommand1=()=>{
    // console.log("++++++++++++",v)
    // Breed.push(values.collection_breed[0]+","+values.collection_breed[1])
    // initialvalue.contenthead1.splice(0,1,values.collection_breed[0]+","+values.collection_breed[1]);
    //  setinitialvalue({...initialvalue})
    //  setModalopen3(!modalopen3)

     if(!values.collection_breed){
      return setValues({...values,bre:"Please sellect Breed # 1"})
    }
   


      setValues({...values,join_breed:values.collection_breed,collection_breed:"",bre:""})
     setModalopen3(!modalopen3)
     setModalopen4(!modalopen4)

  }

  const finalsubmit=()=>{
    
    if(!values.collection_breed){
      return setValues({...values,bre:"Please sellect Breed # 2"})
    }
   

    Breed.push(values.join_breed+","+values.collection_breed)
    initialvalue.contenthead1.splice(0,1,values.join_breed+","+values.collection_breed);
    values.collection_breed="";
    values.bre="";
    setValues({...values})
    setinitialvalue({...initialvalue})
    setModalopen4(!modalopen4)
   
  }



  const breedchage=(e)=>{
    
  // if(e.target.value.length <= 2){
  
    setValues({...values,collection_breed:e.target.value})
  // }
  
  }

  const searchcall=(e)=>{
    setinitialvalue1({...initialvalue1,searchbar:e.target.value})
  }

  async function submitSearch() {
    try {
      props.setLoading(false)
      const res = await request("post", "/mobilesearch", initialvalue);
      if (res.data.success === "yes") {
        // var newval = res.data.results.filter((item)=>{
        //   return ((item.weight < "150" && item.weight > "85"))
        // })
        // console.log(newval)
        props.SearchResults(res.data);
        props.filterss(initialvalue1);
      }
    } catch (err) {
      console.log(err);
    }
  }
  
const getsearch=async()=>{
  var set_search=[]
   const res=await request("post","/recent_search",{},false,true)
   if(res.data && res.data.success=="yes"){
     var full_org_val=res.data.items;
    setSearchfill(res.data.items)
    full_org_val.map((data)=>{

      if(data.searchtext.includes("{")){
        if(JSON.parse(data.searchtext).keyword){
          //data.searchtext=JSON.parse(data.searchtext).keyword
          set_search.push({...data,searchtext:JSON.parse(data.searchtext).keyword})
        }
       
      }
      if(data.searchtext){
        set_search.push(data)
      }
      
    })
     setSearch(set_search)
   }
}

  useEffect(() => {
    // console.log("+++=",props.ord)
    submitSearch();
  }, [initialvalue]);

   useEffect(()=>{
    setinitialvalue({...initialvalue,orderby:props.ord,sh_limit:props.row})

   },[props.ord,props.row])


  useEffect(()=>{
    getsearch()
  },[]);
  const handleChange = (evt) => {
    var { name, value } = evt.target;
     console.log("------",name,value)
     if(name == 'location' && value.length > 0){
        if(value.includes('South East')){
           value=['South East']
        }else{
             value=[]
             return false;
        }
     }
    if(value[0] === "Crossbreed" && name === "contenthead1"){
     
      toggleModal3()
   }
   else{
    setinitialvalue({
      ...initialvalue,
      [name]: value,
    });
  }
  
     
   
   
  };
  const handleChange1=()=>{
    // console.log("+++",e.target.value)
    // if(initialvalue1.searchbar=="")
    // {
    //   setinitialvalue1({...initialvalue1,search:true})
    // }
    // else{
    //   setinitialvalue1({...initialvalue1,search:false})
     

      setOpen(false)
      setinitialvalue({
        ...initialvalue,
        searchbar:initialvalue1.searchbar,
      });
        
    // }
    
  }

  const handleChangefilltersubmit=()=>{

      setOpenfilter(false)
      var data_fillter=initialvalue1.searchbarfilter?JSON.parse(initialvalue1.searchbarfilter):initialvalue1.searchbarfilter;

      setinitialvalue({
        ...initialvalue,
        searchbar:data_fillter.keyword?data_fillter.keyword:'',
        category:data_fillter.section?data_fillter.section:[],
        subcategory:data_fillter.type?data_fillter.type:[],
        weight:data_fillter.weight?data_fillter.weight:[],
        contenthead5:data_fillter.sex?data_fillter.sex:[],
        contenthead2:data_fillter.age?data_fillter.age:[],
        contenthead1:data_fillter.breed?data_fillter.breed:[],
        Qa:data_fillter.qa?data_fillter.qa:[],
        export:data_fillter.export?data_fillter.export:[],
        location:data_fillter.location?data_fillter.location:[],
        live:data_fillter.live,
        upcomming:data_fillter.upcomming,
        all:data_fillter.all
      });


      setinitialvalue1({
        ...initialvalue1,
        live:data_fillter.live,
        upcomming:data_fillter.upcomming,
        All:data_fillter.all,
        searchbar:data_fillter.keyword
      })
    
  }

  const savesearch=async(e)=>{
    if(savesearchname==""){ 
      Toast.fire({type:"warning",title:"please enter something!!!"})
    }
  else{
     var filter_arr={
        keyword:initialvalue1.searchbar,
        section:initialvalue.category,
        type:initialvalue.subcategory,
        weight:initialvalue.weight,
        sex:initialvalue.contenthead5,
        age:initialvalue.contenthead2,
        breed:initialvalue.contenthead1,
        qa:initialvalue.Qa,
        export:initialvalue.export,
        location:initialvalue.location,
        live:initialvalue1.live,
        upcomming:initialvalue1.upcomming,
        all:initialvalue1.All,
        name:savesearchname
     }
     
    const res=await request("post","/add_save_search",{searchbar:JSON.stringify(filter_arr)},false,true)
    if(res.data.success=="no"){
      Toast.fire({type:"error",title:"search results already saved!!"})
    }
    else{
      setSavesearchname('')
      setSavesearch(false)
      getsearch()
      Toast.fire({type:"success",title:"search results successfully saved!!"})
    }
  }
  }

  const resetSearch = (e) => {
    setinitialvalue({
      category: [], 
      location: [],
      subcategory: [],
      weight: [],
      contenthead5: [],
      contenthead2: [],
      contenthead1: [],
      contenthead3: [],
      orderby:"",
      fromprice: "",
      toprice: "",
      Qa:[],
      export: [],
      searchbar: "",
      sh_limit:""
    });
    setinitialvalue1({
      live: false,
      upcomming: false,
      All: true,
      search:false,
      searchbar:""
    });
  }

  const firstfilter = (e) => {
    var { id } = e.target;
    console.log("++++++++++++", id);
    if (id == "live") {
      setinitialvalue1({
        ...initialvalue1,
        live: true,
        All: false,
        upcomming: false,
      });
      return props.filterss({ live: true, All: false, upcomming: false });
    } else if (id == "All") {
      setinitialvalue1({
        ...initialvalue1,
        live: false,
        All: true,
        upcomming: false,
      });
      return props.filterss({ live: false, All: true, upcomming: false });
    } else {
      setinitialvalue1({
        ...initialvalue1,
        live: false,
        All: false,
        upcomming: true,
      });
      return props.filterss({ live: false, All: false, upcomming: true });
    }
  };

  /* const classes = useStyles(); */

  console.log(initialvalue);
  return (
    <div className="searchFilters">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="searchTitle playFair">Search for lots</h1>
        <Button
          onClick={resetSearch}
          className="saveSearch d-flex justify-content-start align-items-center"
        >
          <span class="material-icons">search_off</span>Reset search
        </Button>
       {isAuthenticated() ?
        <>
        <Button
          onClick={()=>setSavesearch(true)}
          className="saveSearch d-flex justify-content-start align-items-center"
        >
          <span class="material-icons">bookmark_border</span>Save this search
        </Button> <Button
          onClick={()=>setOpenfilter(true)}
          className="saveSearch d-flex justify-content-start align-items-center"
        >
          <span class="material-icons">bookmark_border</span>View Saved Searches
        </Button>
        </>:""}
      </div>

      <div className="d-flex justify-content-start align-items-center">
        <div className="searchAuctions">
          <h4>Search in</h4>
          <div className="d-flex justify-content-start align-items-center">
            <span>
              <input
                type="checkbox"
                checked={initialvalue1.live}
                onChange={(e)=>firstfilter(e)}
                id="live"
                hidden
              ></input>
              <label
                for="live"
                style={
                  initialvalue1.live
                    ? { backgroundColor: "#1a1b1b", color: "rgb(245 248 253)" }
                    : {}
                }
              >
                Live Sales
              </label>
            </span>

            <span>
              <input
                type="checkbox"
                checked={initialvalue1.upcomming}
                onChange={(e)=>firstfilter(e)}
                id="upcoming"
                hidden
              ></input>
              <label
                for="upcoming"
                style={
                  initialvalue1.upcomming
                    ? { backgroundColor: "#1a1b1b", color: "rgb(245 248 253)" }
                    : {}
                }
              >
                Upcoming Sales
              </label>
            </span>

            <span>
              <input
                type="checkbox"
                checked={initialvalue1.All}
                id="All"
                onChange={(e)=>firstfilter(e)}
                hidden
              ></input>
              <label
                style={
                  initialvalue1.All
                    ? { backgroundColor: "#1a1b1b", color: "rgb(245 248 253)" }
                    : {}
                }
                for="All"
              >
                All
              </label>
            </span>
          </div>
          
        </div>
        
        <div className="searchLocations">
          <h4>Location</h4> 
          <SearchOptions
            label="Location"
            selection={Provinces}
            onchange={handleChange}
            name="location"
            value={initialvalue.location}
          />
        </div>
      </div>

      <Sticky>
        <div className="filterOptions">
          <h4>Add Filters</h4>
          <div className="d-flex justify-content-between align-items-center">
            <SearchOptions
              label="Section"
              selection={Section}
              onchange={handleChange}
              name="category"
              value={initialvalue.category}
            />
            <SearchOptions
              label="Type"
              selection={Type}
              onchange={handleChange}
              name="subcategory"
              value={initialvalue.subcategory}
            />
            <SearchOptions
              label="Weight"
              selection={Weight}
              onchange={handleChange}
              name="weight"
              value={initialvalue.weight}
            />
            <SearchOptions
              label="Sex"
              selection={Sex}
              onchange={handleChange}
              name="contenthead5"
              value={initialvalue.contenthead5}
            />
            <SearchOptions
              label="Age"
              selection={Age}
              onchange={handleChange}
              name="contenthead2"
              value={initialvalue.contenthead2}
            />
            <SearchOptions
              label="Breed"
              selection={Breed}
              onchange={handleChange}
              val={modalopen3?"hide":""}
              name="contenthead1"
              value={initialvalue.contenthead1}
            />
            <SearchOptions
              label="QA"
              selection={["Yes", "No"]}
              onchange={handleChange}
              name="Qa"
              value={initialvalue.Qa}
            />
            <SearchOptions
              label="Export"
              selection={["Yes", "No"]}
              onchange={handleChange}
              name="export"
              value={initialvalue.export}

            />
            {/* <SearchOptions
              label="Keyword Search"
              onchange={handleChange}
              name="searchbar"
              value={initialvalue.searchbar}
            /> */}
            <Button
              variant="outlined"
              className="keyBtn"
              
              color={initialvalue1.searchbar?"#000000":""}
              onClick={handleClickOpen}
            >
              {initialvalue1.searchbar?"showing  results for "+initialvalue1.searchbar:"keyword search"}
            </Button>
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            onBackdropClick={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div className="keySearch">
             
              {/* <InputField label="Keyword Search"
              placeholder="Enter keywords here"
              name={"searchbar"}
              value={initialvalue1.searchbar}
              onchange={searchcall}
              error={initialvalue1.search}
              helperText={initialvalue1.search?"Please enter title name":""}
            >
              
              </InputField> */}
  <Autocomplete
      id="combo-box-demo"
      options={searc}
      getOptionLabel={(option) => option.searchtext}
      style={{ width: 300 }}
      // openText={"hi"}
      onChange={(event, value) => setinitialvalue1({...initialvalue1,searchbar:value.searchtext})}
      inputValue={initialvalue1.searchbar}
      renderInput={(params) => <TextField  {...params} label="key word search" variant="outlined" name="searchbar" onChange={e=>{setinitialvalue1({...initialvalue1,searchbar:e.target.value})}}/>}
    />
    

            
              

            {/* <CustomInput
              label="Keyword Search"
              placeholder="Enter keywords here"
            /> */}
            <PrimaryButton value={initialvalue1.searchbar} name={"searchbar"} onsubmit={handleChange1} label="Search" btnSize="small" />
            {/* <div><FormHelperText error>{initialvalue1.search?"please select or enter a text":""}</FormHelperText></div> */}
            </div>
            
          </Dialog>

          <Dialog
            open={openfilter}
            onClose={()=>setOpenfilter(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onBackdropClick={()=>setOpenfilter(false)}
          >
            <div className="keySearch">
                <CustomSelect
                     id="search"
                     label="Select your search"
                     name="search"                 
                     value={initialvalue1.searchbarfilter}
                     pc_onchange={(e)=>setinitialvalue1({...initialvalue1,searchbarfilter:e.target.value})}
                     selectMenuHeader={"Select your search"}
                     //error={state ? !values.state && true : false}
                >
                  {searcfill.map((m) => (
                      <option key={m} value={m.searchtext}>
                        {m.searchtext.includes("{")?JSON.parse(m.searchtext).name:''}
                      </option>
                    ))}
              </CustomSelect>
               
            <PrimaryButton value={initialvalue1.searchbar} name={"searchbar"} onsubmit={handleChangefilltersubmit} label="Submit" btnSize="small" />

            </div>
            
          </Dialog>

          <Dialog
            open={savesea}
            onClose={()=>setSavesearch(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onBackdropClick={()=>setSavesearch(false)}
          >
            <div className="keySearch">
             
            <CustomInput
		        id="building-name"
						label="Search Name"
					
		        placeholder="Enter your Savesearch"
						name="save-search-name"
						value= {savesearchname}
						onchange={(e)=>{
                setSavesearchname(e.target.value)
            }}
		        >
              
            </CustomInput>

               
            <PrimaryButton value={savesearchname} onsubmit={savesearch} label="Submit" btnSize="small" />

            </div>
            
          </Dialog>
        </div>
      </Sticky>

      
      {/* <FFModal
           open={modalopen3}
           handleClose={toggleModal3}
           modaltitle="Crossbreed"
           >
          <div>
           
          <SearchOptions
              label="CrossBreed"
              selection={Crossbreed}
              onchange={breedchage}
              name="collection_breed"
              value={values.collection_breed}
            />
           
          {/* <textarea name="multiItems_breed" id={v} value={values.multiItems_breed[v]} onKeyPress={specialcharacter} onChange={handleChange}>Enter Breed here...</textarea> */}
           {/* <Button onClick={callcommand}>submit</Button> */}
           {/* <div className="pl-btn pt-4">
           <PrimaryButton label={"submit"} onsubmit={callcommand1} btnSize={"9"}></PrimaryButton>
           </div>
          </div>  */}
           {/* </FFModal> */}
           <FFModal
           open={modalopen3}
           handleClose={toggleModal3}
           modaltitle="Breed # 1"
           >
          <div style={{fontWeight:'500'}}>
            {/* {console.log("cross",values.collection_breed)}
          <SearchOptions
              label={<span style={{backgroundColor:"white"}}>&nbsp;CrossBreed&nbsp;</span>}
              selection={Crossbreed}
              onchange={breedchage}
              name="collection_breed"
              value={values.collection_breed}
            /> */}
                 
                 <div className="col-md-6" style={{left:"142px"}}>
                <CustomSelect
                  
                  label="Breed # 1" 
                  tooltiptitle="Breed # 1 of the animal"
                  name="collection_breed"
                  value={values.collection_breed}
                  selectMenuHeader={"Select Breed # 1"}
                  selectMenu={Crossbreed}
                  // error={state && !values.multiItems_breed[val]?true:false}
                  // helperText={state?errors.multiItems_breed:""}
                  pc_onchange={breedchage}
                >
                 
                </CustomSelect>
                
                  <FormHelperText error>

                    {!values.collection_breed?values.bre:""}
                  </FormHelperText>
              
              </div>





           
          {/* <textarea name="multiItems_breed" id={v} value={values.multiItems_breed[v]} onKeyPress={specialcharacter} onChange={handleChange}>Enter Breed here...</textarea> */}
           {/* <Button onClick={callcommand}>submit</Button> */}
           <div className="pl-btn pt-4">
           <PrimaryButton label={"submit"} onsubmit={callcommand1} btnSize={"9"}></PrimaryButton>
           </div>
          </div> 
           </FFModal>


           <FFModal
           open={modalopen4}
           handleClose={toggleModal4}
           modaltitle="Breed # 2"
           >
          <div style={{fontWeight:'500'}}>
            {/* {console.log("cross",values.collection_breed)}
          <SearchOptions
              label={<span style={{backgroundColor:"white"}}>&nbsp;CrossBreed&nbsp;</span>}
              selection={Crossbreed}
              onchange={breedchage}
              name="collection_breed"
              value={values.collection_breed}
            /> */}
                 
                 <div className="col-md-6" style={{left:"142px"}}>
                <CustomSelect
                  
                  label="Breed # 2" 
                  tooltiptitle="Breed # 2 of the animal"
                  name="collection_breed"
                  value={values.collection_breed}
                  selectMenuHeader={"Select Breed # 2"}
                  selectMenu={Crossbreed}
                  // error={state && !values.multiItems_breed[val]?true:false}
                  // helperText={state?errors.multiItems_breed:""}
                  pc_onchange={breedchage}
                >
                 
                </CustomSelect>
               
                  <FormHelperText error>

                       {!values.collection_breed?values.bre:""}
                  </FormHelperText>
               
              </div>





           
          {/* <textarea name="multiItems_breed" id={v} value={values.multiItems_breed[v]} onKeyPress={specialcharacter} onChange={handleChange}>Enter Breed here...</textarea> */}
           {/* <Button onClick={callcommand}>submit</Button> */}
           <div className="pl-btn pt-4">
           <PrimaryButton label={"submit"} onsubmit={finalsubmit} btnSize={"9"}></PrimaryButton>
           </div>
          </div> 
           </FFModal>

    </div>
  );
};

export default SearchFilters;
