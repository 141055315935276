import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { isAuthenticated } from "../../Utils/Authenticated";
import { request, requestGet } from "../../Utils/Request";
import { useHistory } from "react-router-dom";
import {
  TimetoDate,
  TimetoDay,
  SaleNumber,
  ItemNum,
} from "../../Utils/TimerProfile";
import Layout from "../../components/Layout";
import {
  AvatarURL,
  callNumber,
  IMAGE_URL,
  Sentencecase,
} from "../../Utils/constant";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import Drawer from "@material-ui/core/Drawer";
import MultiItem from "./MultiItem";
import Rating from "@material-ui/lab/Rating";
import FFModal from "../../components/Modal";
import "./product.css";
import { addTimer } from "../../redux/action/UserAction";
import { Toast } from "../../Utils/Notifications";
import JwtDecode from "jwt-decode";
import { bidAddtime, realClosedUpdates } from "../../Utils/Socket";
import Timer from "../../Utils/Timer";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Bidhistory from "./Bidhistory";
import Message from "../Message/Message";
import { Button, Tooltip } from "@material-ui/core";
import moment from "moment";
import FavoriteCheckbox from "../../components/FavouriteCheck/FavouriteCheckbox";
import Reviews from "../../components/Reviews";



const RecentAucton = (props) => {


  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const initialState = {
    prodImages: [],
    proddetails: {},
    addamount: "",
    itemid: "",
    proid: "",
  };

  const [features, setFeatures] = useState(initialState);
  const [values, setValues] = useState({
    next_bid: "",
    bid_amount: "",
    bidplaced: false,
    init_amount: "",
  });
  const [values2, setValues2] = useState({
    haulier: "Loading...",
    haulier_amount: 0,
  });
  const [s3_video, setS3video] = useState(true);
  const [state, setState] = useState({
    user: isAuthenticated() && JwtDecode(isAuthenticated()),
    bid_details: {},
    increment_amount: 1,
  });
  
  const [bid_or_not, setbid_or_not] = useState(0);

  const [mulitem, setItem] = useState({
    breed: "",
    sex: "",
    wei: "",
    own: "",
    age: "",
    qa: "",
    len: "",
    days: "",
    agemi: "",
    agema: "",
    testdate: "",
    cr: "",
    ex: "",
    wm: "",
  });
  const images = [];
  const [bidApi, setBidApi] = useState({
    data: [],
    bid_count: "",
    current_price: "",
    bid_count: "",
    top_message: "",
    bid_or_not: 0,
  });


  const addchange = async (e) => {

    if (!isAuthenticated()) {
      setModelopen7(true);
      return false;
    }
    var { id, checked } = e.target;

    if (checked) {
      const res = await request(
        "post",
        "/watchlist",
        { id: id, userid: JwtDecode(isAuthenticated()).id },
        false,
        true
      );

      if ((res.data.success = "yes")) {
        Toast.fire({ type: "success", title: "Lot added to Favourite" });
      } else {
        Toast.fire({ type: "error", title: "Something went wrong" });
      }
    } else {
      const res = await request(
        "post",
        "/removeFwatchlist",
        { id: id, userid: JwtDecode(isAuthenticated()).id },
        false,
        true
      );

      if ((res.data.success = "yes")) {
        Toast.fire({ type: "success", title: "Lot removed from Favourite" });
      } else {
        Toast.fire({ type: "error", title: "Something went wrong" });
      }
    }
  };

  const auctionedProduct = async (product_id) => {
    var amount = "";
    try {
      const res = await request(
        "post",
        "/getprodetails",
        { product_id },
        false,
        true
      );

      if (res.data.success === "yes") {
       
        res.data.attachments.map((imageValue) =>
          images.push({
            original: IMAGE_URL + imageValue.src,
            thumbnail: IMAGE_URL + imageValue.src,
          })
        );
       
        setState({ ...state, bid_details: res.data.biddetails });
        setValues({ ...values, next_bid: res.data.biddetails.next_bid });
        
        if (
          parseInt(res.data.results.latestbid) <= 500 ||
          res.data.results.latestbid === null
        ) {
          amount = 5;
        } else {
          amount = 10;
        }
      
        setFeatures({
          ...features,
          prodImages: images,
          proddetails: res.data.results,
          addamount: amount,
          itemid: res.data.results.auctionid,
          proid: res.data.results.id,
        });
      
        getprofData(res.data.results.user_id);
        getSellerFeedback(res.data.results.user_id);
      
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    if (multiitem) {
      getCalculation(product_id);
    }
    getHaulierPrice(product_id)
  };
  const getCalculation = async (id) => {
    
    var user_id = !isAuthenticated() ? 0 : JwtDecode(isAuthenticated()).id;
    const res = await request(
      "post",
      "/getprodetails_multiple",
      { product_id: id, userid: user_id },
      false,
      true
    );
    if (res.data && res.data.success == "yes") {
      var weight = 0,
        agemax = TimetoDate(res.data.results[1].content_head2),
        tdate = moment(res.data.results[1].inspectiontime).format("MM/DD/YYYY"),
        agemin = TimetoDate(res.data.results[1].content_head2),
        m = 0,
        owner = 0,
        day = TimetoDay(res.data.results[1].content_head4),
        sex1 = res.data.results[1].content_head5,
        breed1 = res.data.results[1].content_head1,
        qa1 = res.data.results[1].width,
        ex1 = res.data.results[1].finish,
        cre = res.data.results[1].manufacturer;

      res.data.results.map((value, key) => {
        weight = weight + value.weight;
       
        {
          res.data.attachments["product_image" + `${key + 2}`] &&
            res.data.attachments["product_image" + `${key + 2}`].map((data) => {
              images.push({
                original: AvatarURL + data,
                thumbnail: AvatarURL + data,
              });
            });
        }

        if (agemax < parseInt(TimetoDate(value.content_head2)) && key != "0") {
          agemax = parseInt(TimetoDate(value.content_head2));
          console.log("=======", agemax, key);
        }
        if (agemin > parseInt(TimetoDate(value.content_head2)) && key != "0") {
          agemin = parseInt(TimetoDate(value.content_head2));
        }

        if (owner < parseInt(value.height) && key != "0") {
          owner = parseInt(value.height);
        }
        if (sex1 !== value.content_head5 && key != "0") {
          sex1 = "0";
        }
        if (breed1 !== value.content_head1 && key != "0") {
          breed1 = "0";
        }

        if (day > TimetoDay(value.content_head4) && key != "0") {
          day = TimetoDay(value.content_head4);
        }
        if (
          tdate > moment(value.inspectiontime).format("MM/DD/YYYY") &&
          key != "0"
        ) {
          tdate = moment(value.inspectiontime).format("MM/DD/YYYY");
        }
        if (qa1 !== value.width && key != "0") {
          qa1 = "m";
        }
        if (ex1 !== value.finish && key != "0") {
          ex1 = "m";
        }
        if (cre !== value.manufacturer && key != "0") {
          cre = "m";
        }
      });
      console.log("kkkkkkkkkkk", agemin, agemax);
      setItem({
        ...mulitem,
        wei: Math.round(
          parseInt(weight) / parseInt(res.data.results.length - 1)
        ),
        agemi: agemin,
        agema: agemax,
        own: owner,
        sex: sex1 === "0" ? "Mixed" : res.data.results[1].content_head5,
        breed: breed1 === "0" ? "Mixed" : res.data.results[1].content_head1,
        len: res.data.results.length,
        days: day,
        testdate: tdate,
        cr: cre === "m" ? "Mixed" : res.data.results[1].manufacturer,
        ex: ex1 === "m" ? "Mixed" : res.data.results[1].finish,
        qa: res.data.results[0].width == "1" ? "Yes" : "No",
        wm: res.data.results[1].content_head3,
      });
    }
  };

  const getHaulierPrice = async (id) => {
   
    if (!isAuthenticated()) {
      setLoading(false);
      setValues2({
        ...values2,
        haulier: "We don't have enough details to estimate haulage cost",
        haulier_amount: 0,
      });
    
    } else {

    const res = await request(
      "post",
      "/getHaulierPrice",
      { product_id: id, userid: JwtDecode(isAuthenticated()).id },
      false,
      true
    );
    setLoading(false);
    if (res.data && res.data.success == "yes") {
      setValues2({
        ...values2,
        haulier: res.data.haulier,
        haulier_amount: res.data.haulier_amount,
      });
    }
    }
  };
  const getBidDetails = async (product_id) => {
    try {
      const res = await request(
        "post",
        "/getbiddetails",
        { product_id },
        false,
        true
      );

      if (res.data.success === "yes") {
        setBidApi({
          ...bidApi,
          data: res.data.results,
          bid_count: res.data.results.length,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const UserProfile = async () => {
    const res = await request(
      "post",
      "/getUserDetails",
      {
        user_id: JwtDecode(isAuthenticated()).id,
      },
      false,
      true
    );
    
  };

  useEffect(() => {

    if (isAuthenticated()) {
      UserProfile()
    }

    auctionedProduct(props.match.params.id);
    getBidDetails(props.match.params.id);

  }, []);

  useEffect(() => {
    realClosedUpdates((data) => {
      const project_id = props.match.params.id;
      if (data.pid === parseInt(project_id)) {
        if (data.usr !== "") {
          if (parseInt(state.user.id) === data.usr) {
            setState((prevState) => ({
              ...prevState,
              bid_details: {
                ...prevState.bid_details,
                bidtopstatus: `Congratulations! You won this item.`,
              },
            }));
          } else if (parseInt(bid_or_not) > 0) {
            setState((prevState) => ({
              ...prevState,
              bid_details: {
                ...prevState.bid_details,
                bidtopstatus: `Unfortunately, you lost this item.`,
              },
            }));
          }
        }
      }
    });

    
  }, []);
  
  const [open, setOpen] = React.useState(false);

  const [reviewModalOpen, setReviewModalOpen] = React.useState(false);
  const [modalopen7, setModelopen7] = React.useState(false);
  const [profData, setProfdata] = useState([]);
  const [sellerFeedback, setSellerFeedback] = useState([]);
  const [display, seDis] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const multiitem = true;
  

  const getprofData = async (id) => {
    try {
      const res = await request(
        "post",
        "/getUserProfileDetails",
        {
          user_id: id,
        },
        false,
        true
      );
      if (res.data.success === "yes") {
        setProfdata(res.data.data);
        
        seDis(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSellerFeedback = async (sellerId) => {
    const res = await request("post", "/getSellerFeedbacks", {
      seller_id: sellerId,
    });
    if (res.data && res.data.status === "yes") {
      var feedback = res.data.data.filter((item)=>{
        return ((item.subject != 'review_1' && item.subject != 'review_2' && item.subject != 'review_3' && item.subject != 'review_4' && item.subject != 'review_5'))
      })
      setSellerFeedback(feedback);
    }
  };

  const getSellerRatings = () => {
    var ratingSum = 0;
    sellerFeedback.forEach((feedback) => {
      ratingSum += feedback.rating;
    });

    return ratingSum / sellerFeedback.length;
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };
  
  const toggleModal2 = () => {
    setModelopen7(false);
  };
  
  const viewReviewsClickHanlder = () => {
    setReviewModalOpen(true);
  };
  const closeReviewsModal = () => {
    setReviewModalOpen(false);
  };
  
  const moveone = (name) => (e) => {
    if (name == "log") {
      window.location.href = "/login";
    } else {
      if (localStorage.getItem("userRole") === "tier1") {
        window.location.href = "/Reg_tier_2";
      } else {
        window.location.href = "/Reg_tier_1";
      }
    }
  };
  const moveon = (e) => {
    return props.history.push({
      pathname: "/Reg_tier_2",
      state: {
        path: props.history.location.pathname,
      },
    });
  };

  
  let currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

  let windowWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  useEffect(() => {
    window.scrollTo({ top: 130, left: 0, behavior: "smooth" });
  }, []);


  return (
    <Layout>
     
      <div className="timerHead">
        <span className="material-icons">timer</span>
        {features.proddetails.date_added === null ||
        features.proddetails.date_closed === null ? (
          <span>Pending Approval</span>
        ) : (
          <Timer
            date_closed={features.proddetails.date_closed}
            date_added={features.proddetails.date_added}
            withText={1}
          />
        )}
      </div>
      <div className="product-wrapper">
        <div className="container">
          {isAuthenticated ? (
            <div
              className={`biddingMessages ${
                state.bid_details.bidtopstatus
                  ? state.bid_details.bidtopstatus.includes("lost") ||
                    state.bid_details.bidtopstatus.includes("losing")
                    ? "lost"
                    : state.bid_details.bidtopstatus.includes("winning")
                    ? "winning"
                    : "won"
                  : null
              } d-flex justify-content-center align-items-center`}
            >
              <h3>
                {state.bid_details.bidtopstatus
                  ? state.bid_details.bidtopstatus.includes("lost") ||
                    state.bid_details.bidtopstatus.includes("losing")
                    ? Sentencecase(
                        state.bid_details.bidtopstatus
                          .split("autoBid")
                          .join("bid")
                      )
                    : state.bid_details.bidtopstatus.includes("winning") ||
                      state.bid_details.bidtopstatus.includes("won")
                    ? Sentencecase(
                        state.bid_details.bidtopstatus
                          .toString()
                          .split("$")
                          .join("€ ")
                      )
                    : ""
                  : ""}
              </h3>
            </div>
          ) : (
            ""
          )}

          <div className="auction-num1">
            <div>
              {features.proddetails.wlistpop !== undefined && (
                <FavoriteCheckbox
                  favIdChild={features.proddetails.id}
                  onCall={addchange}
                  watchlisted={features.proddetails.wlistpop}
                />
              )}
            </div>
            Sale No:
            {features.proddetails.location
              ? " " +
                SaleNumber(
                  features.proddetails.location,
                  features.proddetails.date_added,
                  features.proddetails.date_closed
                ) +
                features.itemid
                  .toString()
                  .slice(features.itemid.toString().length - 4)
              : ""}
          </div>

          <div className="item-title">{features.proddetails.title}</div>
          <div className="row">
            <div className="col-lg-7 mb-3">
              <ImageGallery
                items={features.prodImages}
                thumbnailPosition={`${windowWidth <= 500 ? "bottom" : "left"}`}
                showNav={true}
                showBullets={false}
                showFullscreenButton={true}
                showPlayButton={false}
              />
            </div>

        
            <div className="col-lg-5" hidden={loading}>
              
                <h3 className="auctionNotStarted">
                  Sale Closed
                </h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className="row">
                <div className="col-md-7 d-flex justify-content-between align-items-center pvItemInfo">
                  <div className="item-id">
                    Item ID:
                    {" " +
                      ItemNum(
                        features.proddetails.location,
                        features.proddetails.date_added
                      ) +
                      features.proid
                        .toString()
                        .slice(features.proid.toString().length - 4)}
                  </div>
                  <Button
                    className="pl-2"
                    onClick={() => setVideoModalOpen(true)}
                  >
                    <span className="material-icons">play_circle_filled</span>
                    Watch video
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              {multiitem && (
                <div className="total-items">
                  <div>Total items in this lot: {(mulitem.len && mulitem.len != 0) ? mulitem.len - 1 : ""}</div>
                  <div>
                    <a className="light-grn-btn" onClick={toggleDrawer}>
                      View all
                    </a>
                    <Drawer anchor="right" open={open} onClose={toggleDrawer}>
                      <span
                        onClick={toggleDrawer}
                        className="material-icons drawer-close"
                      >
                        close
                      </span>
                      <MultiItem id={features.proddetails.id} />
                    </Drawer>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">
                    {multiitem ? "Avg. Weight (Kgs)" : "Weight(Kgs)"}
                  </div>
                  <div className="pcon-txt">
                    {multiitem
                      ? (Math.round(mulitem.wei) == 0 ? "N/A" : callNumber(Math.round(mulitem.wei)) + " kg")  
                      : features.proddetails.weight + " kg"}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">Sex</div>
                  <div className="pcon-txt">
                    {multiitem
                      ? mulitem.sex
                      : features.proddetails.content_head5}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">
                    {multiitem ? "Age Range (Mths)" : "Age (M & D)"}
                  </div>
                  <div className="pcon-txt">
                    {multiitem
                      ? mulitem.agemi + "-" + mulitem.agema
                      : TimetoDate(features.proddetails.content_head2)}{" "}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">Breed</div>
                  <div className="pcon-txt">
                    {multiitem
                      ? mulitem.breed
                      : features.proddetails.content_head1}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">
                    {multiitem ? "Oldest Test Date" : "TB Test Date"}
                  </div>
                  <div className="pcon-txt">
                    {multiitem
                      ? (mulitem.testdate ? moment(mulitem.testdate).format("DD/MM/YY") : "")
                      : moment(features.proddetails.inspectiontime).format(
                          "DD/MM/YY"
                        )}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">QA</div>
                  <div className="pcon-txt">
                    {mulitem
                      ? mulitem.qa
                      : features.proddetails.width === 1
                      ? "Yes"
                      : "No"}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">Export</div>
                  <div className="pcon-txt">
                    {multiitem
                      ? mulitem.ex
                      : features.proddetails.future === "1"
                      ? "Yes"
                      : "No"}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">
                    {multiitem ? "Least days in Herd" : "Days in Herd"}
                  </div>
                  <div className="pcon-txt">
                    {multiitem
                      ? mulitem.days
                      : TimetoDay(features.proddetails.content_head4)}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">Calf Reg</div>
                  <div className="pcon-txt">
                    {multiitem ? mulitem.cr : features.proddetails.manufacturer}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">Previous Owners</div>
                  <div className="pcon-txt">
                    {multiitem ? mulitem.own : features.proddetails.height}
                  </div>
                </div>

                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">Section</div>
                  <div className="pcon-txt">
                    {features.proddetails.category_id}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">Type</div>
                  <div className="pcon-txt">
                    {features.proddetails.subcategory}
                  </div>
                </div>
              </div>
              <div className="pcon-head mt-2">Description</div>
              <p className="pcon-descp mb-4 mt-2">
                {features.proddetails.description
                  ? features.proddetails.description
                  : "No Description"}
              </p>
              <div className="row">
                {/* <div className="col-6 col-md-6 form-group">
                  <div className="pcon-head">Disclaimer/Declaration</div>
                  <p className="pcon-descp">
                    {features.proddetails.custom_field_4 ?
                      features.proddetails.custom_field_4 != "undefined"
                      ? features.proddetails.custom_field_4
                      : "No Disclaimers & No Declarations Submitted" 
                    : "No Disclaimers & No Declarations Submitted"}
                    <br></br>
                  </p>
                </div> */}

                {features.proddetails.content_head3 == "Not Sold on Weight" ? (
                <div className="col-6 col-md-6 form-group">
                  <div className="pcon-head">Sale Method</div>
                  <div className="pcon-txt">
                    Not Sold on Weight
                  </div>
                </div>) : (
                <div className="col-6 col-md-6 form-group">
                  <div className="pcon-head">Weighing Method</div>
                  <div className="pcon-txt">
                    {multiitem
                      ? mulitem.wm
                      : features.proddetails.content_head3}
                  </div>
                </div>)}
              </div>
              <p></p>
              <div className="row">
                {display ? (
                  <div className="col-12 col-md-5 form-group">
                    <div class="pcon-head pvSellerMisc">
                      Member:{" "}
                      {features.proddetails["length"] == 1 ? (
                        <Link
                          to={{
                            pathname: "/seller-profile",
                            state: { profData },
                          }}
                        >
                          {profData.username ? profData.username : "Hi"}
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="pcon-txt">
                      {features.proddetails["length"] == 0 ? (
                        <Link
                          to={{
                            pathname: "/seller-profile",
                            state: { profData },
                          }}
                        >
                          {profData.username}
                        </Link>
                      ) : (
                        profData.first_name + " " + profData.last_name + ", " + profData.city
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-12 col-md-3 form-group">
                  <div className="pcon-head pvSellerMisc">Seller rating</div>
                 
                  <Rating
                    name="simple-controlled"
                    value={getSellerRatings()}
                    readOnly={true}
                  />
                </div>
                <div className="col-12 col-md-4 form-group">
                  <div className="pcon-head pvSellerMisc">
                    Seller reviews
                    <a
                      className="review-link"
                      onClick={viewReviewsClickHanlder}
                    >
                      <span className="material-icons">visibility</span>
                    </a>
                  </div>
                  <div className="pcon-txt">{sellerFeedback.length}</div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-7">
                  <div className="pcd-label">
                    <span className="material-icons">location_on</span>
                    {features.proddetails.state},{" "}
                    {features.proddetails.location}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="pcd-label">
                    <span className="material-icons">local_shipping</span>{" "}
                    {values2.haulier}
                  </div>
                </div>
              </div> */}
             
            </div>
            <div className="col-lg-5 bidHistoryCarousel">
              {moment(features.proddetails.date_added).format(
                "YYYY-MM-DD HH:mm:ss"
              ) < currentDate &&
                features.proddetails.market_status == "open" && (
                  <Bidhistory bidHistory={bidApi} />
                )}
            </div>
          </div>

        </div>


        <FFModal
          open={videoModalOpen}
          handleClose={() => setVideoModalOpen(false)}
          modaltitle="Video"
        >
          <div style={{fontWeight:'500'}}>
            {features.proddetails.videos ? 
            (<>
              <video controls width="100%" height="100%" hidden={!s3_video}>
                <source
                  src={
                    process.env.REACT_APP_S3_BASE_URL +
                    "/farmfayre/" +
                    features.proddetails.videos
                  }
                  type="video/mp4"
                  onError={(e) => setS3video(false)}
                ></source>
              </video>
              <video controls width="100%" height="100%" hidden={s3_video}>
                <source
                  src={
                    process.env.REACT_APP_BASE_URL + "/uploads/" + features.proddetails.videos
                  }
                  type="video/mp4"
                ></source>
              </video></>
            ) : (
              <div>
                <p style={{ paddingLeft: "174px", fontSize: "18px" }}>
                  No video available.
                </p>
              </div>
            )}
          </div>
        </FFModal>

        <FFModal
          open={reviewModalOpen}
          handleClose={closeReviewsModal}
          modaltitle="Reviews"
        >
          <div className="text-right sort-by-sp" style={{fontWeight:'500'}}>
            <div className="sort-by mt-2 mb-2">
              <label>Sort By</label>
              <select>
                <option>Newest first</option>
              </select>
            </div>
          </div>

          <div className="reviews-wrapper">
            {sellerFeedback &&
              sellerFeedback.map((val) => <Reviews review={val} />)}
          </div>
        </FFModal>
       
        <FFModal
          open={modalopen7}
          handleClose={toggleModal2}
          modaltitle="Message"
        >
          <div style={{fontWeight:'500'}}>
            <p className="card-text">
              If you want to add Lot in favourite, please register. Already
              Registered? Login here.
            </p>

            <div className="modalCTA d-flex justify-content-end align-items-center">
              <Button onClick={moveone("log")}>Login</Button>

              <PrimaryButton label={"Register"} onsubmit={moveone("Reg")} />
            </div>
          </div>
        </FFModal>
      </div>
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    timer: state.UserReducer.timer || localStorage.getItem("prodtimer"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTimer: (value) => dispatch(addTimer(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentAucton);
