import React from "react";
import { IMAGE_URL } from "../../Utils/constant";
import FavoriteCheckbox from "../FavouriteCheck/FavouriteCheckbox";
import "./RecentAuctions.css";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";

const RecentAuctions = (props) => {


  let history = useHistory();
  const moveon = (id, count, mn) => (e) => {
    return history.push({ pathname: "/recent-items-view/" + id, state: mn });
  };

  return (
    <div className="auctionCard recentAuctions">
      <div className="acImg">

        {/* <Button
          onClick={moveon(
            props.data.id,
            props.data.lotcount,
            props.data.modelnumber
          )}
        > */}
          <img src={IMAGE_URL+props.data.file_name}></img>
        {/* </Button> */}
        
      </div>
      <div className="acTitle">


      {/* <Button
          onClick={moveon(
            props.data.id,
            props.data.lotcount,
            props.data.modelnumber
          )}
        >
        {" "} */}
        <h1 className="playFair">{props.data.title}</h1>{" "}
      {/* </Button> */}

  <p>Sale number: #{props.data.id}</p>
      </div>
      {/* <div className="soldTo d-flex justify-content-start align-items-center">
        <i class="fas fa-crown"></i>
        {props.data.winner}
      </div> */}
      <div className="acPrice">
        <p>
      Sold at <span>€ {props.data.cronjob ?props.data.wprice:props.data.bprice}</span>
        </p>
      </div>
<p className="soldTime">{props.data.paid_date ? moment(props.data.paid_date).format("MM/DD/YY") : moment(props.data.date_closed).format("MM/DD/YY")}</p>
      <object className="soldIcon" data="./svg/soldIcon.svg" type="image/svg+xml"/>
    </div>
  );
};

export default RecentAuctions;
