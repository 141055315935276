import JwtDecode from "jwt-decode";
import React from "react";
import AuctionCard from "../../components/AuctionCard";
import { isAuthenticated } from "../../Utils/Authenticated";
import {request} from "../../Utils/Request"
import {Toast} from "../../Utils/Notifications"

const GridView = (props) => {

  const handlechange=async(e)=>{

    var {id,checked}=e.target;
    console.log("+++++++",id,checked)

  if(checked)
  {


    const res = await request (
      "post",
      "/watchlist",
       {id:id, userid: JwtDecode(isAuthenticated()).id},
       false,
       true
    )
    


    // console.log("+++++",res)
    if(res.data.success="yes"){
      Toast.fire({type:"success",title:"Lot added to Favourite"})
    }
    else
    {
     Toast.fire({type:"error",title:"Something went wrong"})
    }
  }
  else{
    const res = await request (
      "post",
      "/removeFwatchlist",
       {id:id, userid: JwtDecode(isAuthenticated()).id},
       false,
       true
    )
    
    // console.log("+++++",res)
    if(res.data.success="yes"){
      Toast.fire({ type: "success", title: "Lot removed from Favourite" });
    }
    else
    {
     Toast.fire({type:"error",title:"Something went wrong"})
    }

  }


  }
  return (
    <div className="searchGrid d-flex justify-content-start align-items-center flex-wrap">
      {props.searchData &&
        props.searchData.map((data) => <AuctionCard data={data} onchange={handlechange} />)}
    </div>
  );
};

export default GridView;
