import React, { useEffect, useRef, useState} from "react";  
import Layout from '../../components/Layout';
import CheckoutProduct from './CheckoutProduct';
import SelectHerd from './SelectHerd';
import CheckoutAddress from './CheckoutAddress';
import PaymentCard from './PaymentCard';
import './checkout.css'
import { IMAGE_URL} from "../../Utils/constant";
import { Toast } from "../../Utils/Notifications";
import { request} from "../../Utils/Request";
import { eDate, sDate } from "../../Utils/TimerProfile";
import { isAuthenticated } from "../../Utils/Authenticated";
import {CardElement,useStripe,useElements,IbanElement} from '@stripe/react-stripe-js';
import JwtDecode from "jwt-decode";
import {SaleNumber,ItemNum} from "../../Utils/TimerProfile"
import { socket } from "../../Utils/Socket";



const Checkout  = (props) => { 

    const stripe=useStripe();
    const elements = useElements();
   
    const [product, setProduct] = useState({})
    const [referData, setReferdata] = useState({
        referDetail:[],
        amount:0,
      })
	  const [savedCard, setSavedCard] = useState([])
    const [getSaved, setGetSaved] = useState('')
    const productRef = useRef('')
    const userRef = useRef(localStorage.userId)
  

 
  

    const [value,setValue]=useState({
      qty: 0,
      sale_amount: 0,
      product_amount: 0,
      adjustment_amount:0,
      platform_amount:0,
      seller_platform_amount:0,
      weight:0,
      haulier_amount:0,
      buyer_haulier_amount:0,
      tax_amount:0,
      product_total:0,
    })
    const [fee,setFees]=useState({
        platformfees:0,
        providerfees:0,
        providerfees_card:0,
        additionalfees:0,
        tax:0,
        wallet:"no"
    })
    const [isCard, setIsCard] = useState(false)
    const [map,setMap]=useState({
        seller_location: "",
        buyer_location: "",
        distance: -1,
    })
    const [sepaValue,setSepaValue]=useState({
      name:localStorage.userFirstName + ' ' + localStorage.userLastName,
      email:localStorage.userEmail,
      company_name: localStorage.userCompanyName,
    })

  const sepaValueChange = (evt)=>{
    var { name, value } = evt.target;
    setSepaValue({
      ...sepaValue,
      [name]: value
    })
  }

   const [numbers,setNumbers]=useState({
        proid:"",
        itemid:""
   })
   
   const[loading,setLoading] = useState(false);
   const[priceLoading,setPriceLoading] = useState(true);

    // trustap.
  const search = props.location.search;
  const params = new URLSearchParams(search);
  var authority = params.get("trustap_code");

  const pathname = props.location.pathname;
  if(pathname.includes('trustap_code=')){
    authority = pathname.split('trustap_code=')[1];
  }

  console.log("trustap_code", authority);
  SendCode(authority);

  async function SendCode(code) {
    
    if (code) {
      try {
        console.log(code);
        const res = await request("post", "/codetobackend", {
          code: code,
          userid: localStorage.userId,
          url: process.env.REACT_APP_FRONT_URL,
        });

        if(res.data.success){
          localStorage.setItem("trustapID", res.data.code);
          localStorage.setItem("trustapIDToken", '1');
          console.log("============trustapID",res.data.code);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
	
	const getAllCards = async (e) => {
        const res = await request('post','/viewAllCard', {mode: process.env.REACT_APP_PAYMENT_MODE},false, true)
        if (res.data && res.data.success) {
          setSavedCard(res.data.data)
        }
	}
	const selectSavedCard =(e)=>{
		setGetSaved(e.target.value)
  }

  const selectPaymentType =(isCard)=>{
    console.log('selectPaymentType',isCard)
		setIsCard(isCard)
  }

    const getMyrefer = async()=>{
        const res = await request(
          "post",
          "/getUserWalletDetails",
          { user_id:localStorage.userId}, false, true
        )
        console.log(res.data)
        if(res.data.success==="yes"){
          setReferdata({
            ...referData, referDetail :res.data.referral, amount:res.data.data.amount
          })
    
        }
      }

      const walletchange=(e)=>{
          console.log("++++++++++++++++++++++++",e.target.value)
          setFees({...fee,wallet:e.target.value})
      }


      const getCheckoutData = async (id, amount) => {
        console.log('getCheckoutData',id, amount)
        const res = await request(
          "post",
          "/getHaulierPrice",
          { product_id: id, userid: JwtDecode(isAuthenticated()).id, amount: amount },
          false,
          true
        );
        if (res.data && res.data.success == "yes") {
          console.log('getHaulierPrice',res.data)

          setFees({...fee,
            platformfees:parseFloat(res.data.results.platformfees),
            providerfees:parseFloat(res.data.results.providerfees),
            providerfees_card:parseFloat(res.data.results.providerfees_card),
            additionalfees:parseFloat(res.data.results.additionalfees),
            tax:parseFloat(res.data.results.tax),
          })
          setMap({
            ...map,
            distance: parseFloat(res.data.results.distance)
          });
          setValue({
            ...value,
            sale_amount: parseFloat(res.data.results.sale_amount),
            qty: parseInt(res.data.results.qty),
            product_amount: parseFloat(res.data.results.product_amount),
            adjustment_amount: parseFloat(res.data.results.adjustment_amount),
            platform_amount: parseFloat(res.data.results.platform_amount),
            seller_platform_amount: parseFloat(res.data.results.seller_platform_amount),
            weight: parseFloat(res.data.results.weight),
            haulier_amount: parseFloat(res.data.results.haulier_amount),
            buyer_haulier_amount: parseFloat(res.data.results.buyer_haulier_amount),
            tax_amount: parseFloat(res.data.results.tax_amount),
            product_total: parseFloat(res.data.results.product_total),
          });
        }
        setPriceLoading(false);
       
      };
  

	const getProductDetails = async (product_id) => {
        try {
            const res = await request('post','/getprodetails', { product_id })
            if (res.data.success) {
                  
                if(parseInt(res.data.results.bprice) < parseInt(res.data.results.latestbid))
                {
                    props.history.push("/multi-items-view/"+product_id)
                }
                setNumbers({...numbers,proid:res.data.results.id,itemid:res.data.results.auctionid})
                setProduct(res.data.results)
                
                let buynow_price = res.data.results.bprice;
                if (res.data.results.subcategory == "Cow & Calf"){
                  buynow_price = parseFloat((parseFloat(buynow_price) / 2).toFixed(2));
                }
                getCheckoutData(product_id, buynow_price)
                
            } else {
                setPriceLoading(false);
            }
                
        } catch (error) {
            console.log(error)
            setPriceLoading(false);
        }
        
    }


    // const getHaulierDistance = async (proddetails, userProfile) => {
    //     try {
    //       let sellerdata = {
    //         CountryRegion: 'IE',
    //         adminDistrict: proddetails.state,
    //         locality: proddetails.city,
    //         postalCode: '',
    //         addressLine: proddetails.content_head6,
    //         key: 'AgiEZjc4Lo0wIDPaIsFacnGb_VVMuTvB8J2qY_nbIOh3S2jPKcyg2bG_-12sn4zx',
    //       }
    //       const sellerres = await requestGet(
    //         "https://dev.virtualearth.net/REST/v1/Locations",sellerdata
    //       );
    //       var sellerlocations = sellerres['data']['resourceSets'][0]['resources'];
    //       sellerlocations = sellerlocations.filter((location)=>{
    //           return (location["address"]["countryRegion"] == "Ireland")
    //       })
    //       let sellerlocation = sellerlocations[0]['point']['coordinates'].join(',');
    //       let buyerdata = {
    //         CountryRegion: 'IE',
    //         adminDistrict: userProfile.state,
    //         locality: userProfile.city,
    //         postalCode: '',
    //         addressLine: userProfile.address1,
    //         key: 'AgiEZjc4Lo0wIDPaIsFacnGb_VVMuTvB8J2qY_nbIOh3S2jPKcyg2bG_-12sn4zx',
    //       }
    //       const buyerres = await requestGet(
    //         "https://dev.virtualearth.net/REST/v1/Locations", buyerdata
    //       );
    //       var buyerlocations = buyerres['data']['resourceSets'][0]['resources'];
    //       buyerlocations = buyerlocations.filter((location)=>{
    //           return (location["address"]["countryRegion"] == "Ireland")
    //       })
    //       let buyerlocation = buyerlocations[0]['point']['coordinates'].join(',');
    //       if (sellerlocation && buyerlocation){
    //         const distanceres = await requestGet(
    //           "https://dev.virtualearth.net/REST/v1/Routes/DistanceMatrix",
    //           {
    //             origins: sellerlocation,
    //             destinations: buyerlocation,
    //             travelMode: 'Driving',
    //             key: 'AgiEZjc4Lo0wIDPaIsFacnGb_VVMuTvB8J2qY_nbIOh3S2jPKcyg2bG_-12sn4zx',
    //           }
    //         );
    //         let distance = distanceres['data']['resourceSets'][0]['resources'][0]['results'][0]['travelDistance']
    //         console.log('getHaulierDistance=========distance',distance,"==sellerdata", sellerdata,"==buyerdata", buyerdata,"==sellerlocation", sellerlocation,"==buyerlocation", buyerlocation)
    //         if (distance) {
    //             setMap({
    //             ...values2,
    //             seller_location: sellerlocation,
    //             buyer_location: buyerlocation,
    //             distance: distance,
    //           });
    //         } 
    //       }
    //       setPriceLoading(false);
    //     } catch (error) {
    //       console.log(error);
    //       setPriceLoading(false);
    //     }
    // };


    const viewInvoice = async (val) => {
        try {
            const res = await request('post', '/view-invoice', {id:val}, false, true)
            console.log('viewInvoice',res.data )
            if (res.data.success === 'yes') {

              setNumbers({...numbers,proid:res.data.results.arr_temp.SlibuyPayments[0].id,itemid:res.data.results.arr_temp.SlibuyPayments[0].auctionid})
              setProduct(res.data.results.arr_temp.SlibuyPayments[0])
              getCheckoutData(res.data.results.arr_temp.SlibuyPayments[0].project_id, res.data.results.arr_temp.SlibuyPayments[0].amount)
               
            } else {
              setPriceLoading(false);

            }
        } catch (error) {
            console.log(error)
        }
}
    
useEffect(()=>{
  productRef.current = product;
  userRef.current = localStorage.userId;
})


  useEffect(()=>{
    socket.on('realclosedupdates', (data) => {
      
      if (parseInt(productRef.current.id) == parseInt(data.pid)) {
        
        if (parseInt(userRef.current) != parseInt(data.usr)) {
          
          Toast.fire({ title: 'Sorry Someone Bought Your Item!', type: 'error' })
          window.location.href="/search"
        }
      }
    })
    return () => {
      socket.off('realclosedupdates', (data) => {
       
        if (parseInt(productRef.current.id) == parseInt(data.pid)) {
          
          if (parseInt(userRef.current) != parseInt(data.usr)) {
            
            Toast.fire({ title: 'Sorry Someone Bought Your Item!', type: 'error' })
            window.location.href="/search"
          }
        }
      })
    }
  },[])

  

	useEffect(() => {
        //  getDetails()

        if (!isAuthenticated()) {
          window.location.href = process.env.REACT_APP_FRONT_URL + '/login'
          return false
        }

        if (props.match.params.type === 'auction') {
            viewInvoice(props.match.params.id)
        }
        else{
            getProductDetails(props.match.params.id) 
        }  
            getAllCards() 
            getMyrefer()
         
	},[])

	const submit = async () => {
		
        try {


            var data = {}
            let sale_amount = value.sale_amount;
            let product_amount = value.product_amount;
            let adjustment_amount = value.adjustment_amount;
            let platform_amount = value.platform_amount;
            let haulier_amount = value.haulier_amount;
            let tax = value.tax;
            let seller_platform_amount = value.seller_platform_amount;
            
            var product_total = value.product_total;
            
            var walletamount = 0
            if (fee.wallet=="yes"){
              if ((parseFloat(platform_amount) - parseFloat(referData.amount)) < 0){
                walletamount = parseFloat(platform_amount).toFixed(2);
              } else {
                walletamount = parseFloat(referData.amount).toFixed(2);;
              }
            }
            console.log('paymentbreakdone: ',product_amount, adjustment_amount, platform_amount, haulier_amount, product_total, walletamount)
    
            setLoading(true);

            // Use your card Element with other Stripe.js APIs
            const cardElement = elements.getElement(CardElement);
            const {error2, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            });

            if (error2 || !paymentMethod) {
            console.log('[error]', error2);
            setLoading(false);
            return Toast.fire({ type: 'error', title: 'Please enter a vaild card.' })

            } else {
            console.log('[PaymentMethod]', paymentMethod);
            }

            if (props.match.params.type === 'auction') { // auction checkout

                var trustap_params={
                    user_id:JwtDecode(isAuthenticated()).id,
                    seller_id:product.seller_id,
                    project_id:product.id,
                    protitle:product.title, 
                    total:product_total,
                    wallet_amount:walletamount,
                    payment_method: 'card',
                    mode: process.env.REACT_APP_PAYMENT_MODE,
                }
                console.log("+++++++++++++++++trustap_pay_params",trustap_params)
                
                const res = await request("post","/trustap_pay",trustap_params, false, true)
                console.log("+++++++++++++++++trustap_pay res",res.data)
                if(res.data.success){
                    if (res.data.results.transaction_id && res.data.results.client_secret){
                       
                        const {paymentIntent,error}= await stripe.confirmCardPayment(res.data.results.client_secret,
                            {
                                payment_method: paymentMethod.id
                            }
                        )
                        if(error || !paymentIntent){
                            console.log("confirmCardPayment+++++++++++++++++e",error)
                            setLoading(false);
                            return Toast.fire({ type: 'error', title: error })
                        }
                        else{
                            console.log("confirmCardPayment+++++++++++++++++s",paymentIntent)
                            data['transid'] = paymentIntent.id
                            data['trustap_transid'] = res.data.results.transaction_id
                            data['sellerid'] = res.data.results.sellerid
                            data['wallet_amount'] = res.data.results.wallet_amount
                            data['final_amount'] = res.data.results.final_amount
                            data['trustap_charge'] = res.data.results.trustap_charge
                        }
                    } else {
                        setLoading(false);
                        return Toast.fire({ type: 'error', title: 'Trustap transanction creation error' })
                        
                    }
                        var placeorder_params = {
                            premium: 10,
                            buyerPremiumTotal: parseFloat(adjustment_amount).toFixed(2),
                            inv: product.common_invoice,
                            mode: process.env.REACT_APP_PAYMENT_MODE,
                            currency: "eur",
                            payamt: data['final_amount'],
                            totalInvoice: data['final_amount'],
                            qty_sold: value.qty,
                            trans_gateway: "Card Payment",
                            transaction_id: data['transid'],
                            shipAmt: parseFloat(haulier_amount).toFixed(2),
                            sales_tax: fee.tax,
                            salesAmt: parseFloat(tax).toFixed(2),
                            persale: product.orgsalesPercen,
                            wallet_amount: data['wallet_amount'],
                            is_auction: true,
                            sellerid: res.data.results.sellerid,
                            payment_method: 'card',
                            trustap_charge: data['trustap_charge'],
                            platform_amount: parseFloat(platform_amount).toFixed(2),
                            seller_platform_amount: parseFloat(seller_platform_amount).toFixed(2),
                            sale_amount: sale_amount,
                        }
      
                      console.log("placeOrderTrustap======placeorder_params", placeorder_params)
                        //const res2 = await request('post','/successGroupInvoice', value, false, true)
                        const res2 = await request('post','/placeOrderTrustap', placeorder_params, false, true)
                        console.log("placeOrderTrustap====== res",res2.data)
                        if (res2.data.success === 'yes') {
                            // accept_deposit 
                            request('post','/depositaccept', data, false, true);
                            if(!window.location.href.includes("?app_ios_and=1")){
                              props.history.push({pathname:'/my-purchases',state:{val:1}})
                            }
                            else{
                              window.location.href = '/my-purchases'
                            }
                            setLoading(false);
                            return Toast.fire({ title: 'Paid Successfully!', type: 'success' })
                            
                        } else {
                            setLoading(false);
                            return Toast.fire({ type: 'error', title: res2.data.message })
                            
                        }
                    
                }  else {
                    setLoading(false);
                    return Toast.fire({ type: 'error', title: res.data.message })
                    
                }
             
            }
            else{     // buynow checkout        

                const resCart = await request('get',`/addtocart?id=${product.id}&qty=${product.qty}`, {}, false, true)
                var trustap_params={
                    user_id:JwtDecode(isAuthenticated()).id,
                    seller_id:product.user_id,
                    project_id:product.id,
                    protitle:product.title, 
                    total:product_total,
                    wallet_amount:walletamount,
                    payment_method: 'card',
                    mode: process.env.REACT_APP_PAYMENT_MODE,
                }
                console.log("+++++++++++++++++trustap_params",trustap_params)

                const res = await request("post","/trustap_pay",trustap_params, false, true)
                console.log("+++++++++++++++++trustap_pay res",res.data)
                if(res.data.success){
                    if (res.data.results.transaction_id && res.data.results.client_secret){
                       
                        const {paymentIntent,error}= await stripe.confirmCardPayment(res.data.results.client_secret,
                            {
                                payment_method: paymentMethod.id
                            }
                        )
                        if(error || !paymentIntent){
                            console.log("confirmCardPayment+++++++++++++++++e",error)
                            await request('get',`/removefromcart?id=${product.id}`, {}, false, true)
                            setLoading(false);
                            return Toast.fire({ type: 'error', title: error })
                        } else{
                            console.log("confirmCardPayment+++++++++++++++++s",paymentIntent)
                            data['transid'] = paymentIntent.id
                            data['trustap_transid'] = res.data.results.transaction_id
                            data['sellerid'] = res.data.results.sellerid
                            data['wallet_amount'] = res.data.results.wallet_amount
                            data['final_amount'] = res.data.results.final_amount
                            data['trustap_charge'] = res.data.results.trustap_charge


                            var placeorder_params = {
                              isSave: false,
                              premium: 10,
                              premiumAmt: parseFloat(adjustment_amount).toFixed(2),
                              inv: product.common_invoice,
                              mode: process.env.REACT_APP_PAYMENT_MODE,
                              currency: "eur",
                              amount: data['final_amount'],
                              totalInvoice: data['final_amount'],
                              qty_sold: value.qty,
                              trans_gateway: "Card Payment",
                              transaction_id: data['transid'],
                              shipAmt: parseFloat(haulier_amount).toFixed(2),
                              shipCmb: "0.00",
                              shipOrg: "0.00",
                              sales_tax: fee.tax,
                              taxAmt: parseFloat(tax).toFixed(2),
                              persale: product.orgsalesPercen,
                              wallet_amount: data['wallet_amount'],
                              is_auction: false,
                              sellerid: res.data.results.sellerid,
                              payment_method: 'card',
                              trustap_charge: data['trustap_charge'],
                              platform_amount: parseFloat(platform_amount).toFixed(2),
                              seller_platform_amount: parseFloat(seller_platform_amount).toFixed(2),
                              cartCount: 1,
                              project_id:product.id,
                              sale_amount: sale_amount,
                          }
        
                          console.log("placeOrderTrustap======placeorder_params", placeorder_params)
                
                            const resCard = await request('post','/placeOrderTrustap', placeorder_params, false, true)
                            console.log("placeOrderTrustap+++++++++++++++++",resCard)
                            if (!resCard.data.success) {
                                await request('get',`/removefromcart?id=${product.id}`, {}, false, true)
                                setLoading(false);
                                return Toast.fire({ type: 'error', title: resCard.data.message })
                                
                            } else {
                                 // accept_deposit 
                                request('post','/depositaccept', data, false, true);
                            }
                            Toast.fire({ type: 'success', title: 'Payment Successful!' })
                        }
                    } else {
                        await request('get',`/removefromcart?id=${product.id}`, {}, false, true)
                        setLoading(false);
                        return Toast.fire({ type: 'error', title: 'Trustap transanction creation error' })
                        
                    }
                    
                }  else {
                    await request('get',`/removefromcart?id=${product.id}`, {}, false, true)
                    setLoading(false);
                    return Toast.fire({ type: 'error', title: res.data.message })
                }
                if(!window.location.href.includes("?app_ios_and=1")){
                  props.history.push({pathname:'/my-purchases',state:{val:3}})
                }
                else{
                  window.location.href = '/my-purchases'
                }
                setLoading(false);
            // }
           
                
            }
            
        } catch (error) {
            
            console.log(error)
        }
    }


    const submitSEPA = async (name, email) => {

      
      console.log('submitSEPA', name, email)
      try {

        var data = {}

        let sale_amount = value.sale_amount;
        let product_amount = value.product_amount;
        let adjustment_amount = value.adjustment_amount;
        let platform_amount = value.platform_amount;
        let haulier_amount = value.haulier_amount;
        let tax = value.tax;
        let seller_platform_amount = value.seller_platform_amount;
        
        var product_total = value.product_total;
            
        var walletamount = 0
        if (fee.wallet=="yes"){
          if ((parseFloat(platform_amount) - parseFloat(referData.amount)) < 0){
            walletamount = parseFloat(platform_amount).toFixed(2);
          } else {
            walletamount = parseFloat(referData.amount).toFixed(2);;
          }
        }
        console.log('paymentbreakdone: ',product_amount, adjustment_amount, platform_amount, haulier_amount, product_total, walletamount)
    
        setLoading(true);

        // // Use your Iban Element with other Stripe.js APIs
        // const iban = elements.getElement(IbanElement);
        // console.log('[iban]', iban);
        // if (name == '') {
        // setLoading(false);
        // return Toast.fire({ type: 'error', title: 'Please enter a vaild name.' })
      
        // } else if (name != sepaValue.company_name && name != sepaValue.name) {
        //   setLoading(false);
        //   return Toast.fire({
        //     type: "error",
        //     title: "Please enter a vaild name which match your profile.",
        //   });
        // } else if (email == "") {
        //   setLoading(false);
        //   return Toast.fire({ type: 'error', title: 'Please enter a vaild email.' })
  
        // } else if (!iban) {
        //   setLoading(false);
        //   return Toast.fire({ type: 'error', title: 'Please enter a vaild IBAN.' })
        // }

        // const PaymentMethod = await stripe.createPaymentMethod({
        //   type: 'sepa_debit',
        //   sepa_debit: iban,
        //   billing_details: {
        //     name: name,
        //     email: email,
        //   }
        // });
        // if (PaymentMethod.error) {
        // setLoading(false);
        // return Toast.fire({ type: 'error', title: PaymentMethod.error.message })

        // } else {
        //   console.log('paymentMethod', PaymentMethod.paymentMethod)
        // }

        if (props.match.params.type === 'auction') { // auction checkout

            var trustap_params={
                user_id:JwtDecode(isAuthenticated()).id,
                seller_id:product.seller_id,
                project_id:product.id,
                protitle:product.title, 
                total:product_total,
                wallet_amount:walletamount,
                payment_method: 'sepa',
                mode: process.env.REACT_APP_PAYMENT_MODE,
            }
            console.log("+++++++++++++++++trustap_pay_params",trustap_params)
            
            const res = await request("post","/bank_transfer",trustap_params, false, true)
            console.log("+++++++++++++++++bank_transfer res",res.data)
            if(res.data.success){

              window.open(res.data.results.hosted_instructions_url, "_blank")
                // if (res.data.results.transaction_id && res.data.results.client_secret){
                   
                //     const {paymentIntent,error}= await stripe.confirmSepaDebitPayment(res.data.results.client_secret,
                //       {
                //         payment_method: PaymentMethod.paymentMethod.id
                //       });
                //     if(error || !paymentIntent){
                //         console.log("confirmSepaDebitPayment+++++++++++++++++e",error)
                //         setLoading(false);
                //         return Toast.fire({ type: 'error', title: error })
                //     }
                //     else{
                        // console.log("confirmSepaDebitPayment+++++++++++++++++s",paymentIntent)
                        data['transid'] = ''
                        data['trustap_transid'] = res.data.results.transaction_id
                        data['sellerid'] = res.data.results.sellerid
                        data['wallet_amount'] = res.data.results.wallet_amount
                        data['final_amount'] = res.data.results.final_amount
                        data['trustap_charge'] = res.data.results.trustap_charge
                //     }
                // } else {
                //     setLoading(false);
                //     return Toast.fire({ type: 'error', title: 'Trustap transanction creation error' })
                    
                // }

                    var placeorder_params = {
                      premium: 10,
                      buyerPremiumTotal: parseFloat(adjustment_amount).toFixed(2),
                      inv: product.common_invoice,
                      mode: process.env.REACT_APP_PAYMENT_MODE,
                      currency: "eur",
                      payamt: data['final_amount'],
                      totalInvoice: data['final_amount'],
                      qty_sold: value.qty,
                      trans_gateway: "Card Payment",
                      transaction_id: data['transid'],
                      shipAmt: parseFloat(haulier_amount).toFixed(2),
                      sales_tax: fee.tax,
                      salesAmt: parseFloat(tax).toFixed(2),
                      persale: product.orgsalesPercen,
                      wallet_amount: data['wallet_amount'],
                      is_auction: true,
                      sellerid: res.data.results.sellerid,
                      payment_method: 'sepa',
                      trustap_charge: data['trustap_charge'],
                      platform_amount: parseFloat(platform_amount).toFixed(2),
                      seller_platform_amount: parseFloat(seller_platform_amount).toFixed(2),
                      sale_amount: sale_amount,
                  }
  
                  console.log("placeOrderTrustap======placeorder_params", placeorder_params)
                    //const res2 = await request('post','/successGroupInvoice', value, false, true)
                    const res2 = await request('post','/placeOrderTrustap', placeorder_params, false, true)
                    console.log("placeOrderTrustap====== res",res2.data)
                    if (res2.data.success === 'yes') {
                        // accept_deposit 
                        // request('post','/depositaccept', data, false, true);
                        if(!window.location.href.includes("?app_ios_and=1")){
                          props.history.push({pathname:'/my-purchases',state:{val:1}})
                        }
                        else{
                          window.location.href = '/my-purchases'
                        }
                        setLoading(false);
                        return Toast.fire({ title: 'Purchase Successfully!', type: 'success' })
                        
                    } else {
                        setLoading(false);
                        return Toast.fire({ type: 'error', title: res2.data.message })
                        
                    }
                
            }  else {
                setLoading(false);
                return Toast.fire({ type: 'error', title: res.data.message })
                
            }
         
        }
        else{     // buynow checkout        

            console.log("+++++++++++++++++data",data)
            const resCart = await request('get',`/addtocart?id=${product.id}&qty=${product.qty}`, {}, false, true)
            var trustap_params={
                user_id:JwtDecode(isAuthenticated()).id,
                seller_id:product.user_id,
                project_id:product.id,
                protitle:product.title, 
                total:product_total,
                wallet_amount:walletamount,
                payment_method: 'sepa',
                mode: process.env.REACT_APP_PAYMENT_MODE,
            }
            console.log("+++++++++++++++++trustap_params",trustap_params)

            const res = await request("post","/bank_transfer",trustap_params, false, true)
            console.log("+++++++++++++++++bank_transfer res",res.data)
            if(res.data.success){

              window.open(res.data.results.hosted_instructions_url, "_blank")
              
            
              data['transid'] = ''
              data['trustap_transid'] = res.data.results.transaction_id
              data['sellerid'] = res.data.results.sellerid
              data['wallet_amount'] = res.data.results.wallet_amount
              data['final_amount'] = res.data.results.final_amount
              data['trustap_charge'] = res.data.results.trustap_charge


              var placeorder_params = {
                isSave: false,
                premium: 10,
                premiumAmt: parseFloat(adjustment_amount).toFixed(2),
                inv: product.common_invoice,
                mode: process.env.REACT_APP_PAYMENT_MODE,
                currency: "eur",
                amount: data['final_amount'],
                totalInvoice: data['final_amount'],
                qty_sold: value.qty,
                trans_gateway: "Card Payment",
                transaction_id: data['transid'],
                shipAmt: parseFloat(haulier_amount).toFixed(2),
                shipCmb: "0.00",
                shipOrg: "0.00",
                sales_tax: fee.tax,
                taxAmt: parseFloat(tax).toFixed(2),
                persale: product.orgsalesPercen,
                wallet_amount: data['wallet_amount'],
                is_auction: false,
                sellerid: res.data.results.sellerid,
                payment_method: 'sepa',
                trustap_charge: data['trustap_charge'],
                platform_amount: parseFloat(platform_amount).toFixed(2),
                seller_platform_amount: parseFloat(seller_platform_amount).toFixed(2),
                cartCount: 1,
                project_id:product.id,
                sale_amount: sale_amount,
              }

              console.log("placeOrderTrustap======placeorder_params", placeorder_params)
  
              const resCard = await request('post','/placeOrderTrustap', placeorder_params, false, true)
              console.log("placeOrderTrustap+++++++++++++++++",resCard)
              if (!resCard.data.success) {
                  await request('get',`/removefromcart?id=${product.id}`, {}, false, true)
                  setLoading(false);
                if (window.location.href.includes("?app_ios_and=1")) {
                  Toast.fire({ type: 'error', title: resCard.data.message })
                  window.location.href = '/search'
                  return false;
                }
                else {
                    return Toast.fire({ type: 'error', title: resCard.data.message })
                }
                  
                  
              } else {
                    // accept_deposit 
                  // request('post','/depositaccept', data, false, true);
              }
              Toast.fire({ type: 'success', title: 'Purchase Successfully!' })
             
                
            }  else {
                await request('get',`/removefromcart?id=${product.id}`, {}, false, true)
                setLoading(false);
                return Toast.fire({ type: 'error', title: res.data.message })
            }
            if(!window.location.href.includes("?app_ios_and=1")){
              props.history.push({pathname:'/my-purchases',state:{val:3}})
            }
            else{
              window.location.href = '/my-purchases'
            }
            setLoading(false);
        // }
       
            
        }
        
      } catch (error) {
        
        console.log(error)
      }
    }
	
  return ( 

    <Layout> 
        <div className={!window.location.href.includes("?app_ios_and=1")?"main-container checkout-container":"main-container checkout-container nohdr"}>
	        <div className="container">
            {!window.location.href.includes("?app_ios_and=1")?
	       	  <div className="cp-breadcrumb">
	       	    <a style={{color:"rgba(58, 59, 60, 0.52)"}} href={"/multi-items-view/"+product.id}><span className="material-icons">arrow_back</span></a>Sale name / Checkout
	       	  </div>:""} 
	       	  <div className="row">
	       	  	<div className="col-md-8">
	       	  		<CheckoutProduct 
			       	    imgurl={IMAGE_URL+product.avatar}
			       	    id={ItemNum(product.location,product.date_added,product.date_closed)+numbers.proid.toString().slice(numbers.proid.toString().length-4)}
			       	    title={product.title}
			       	    amount={value.product_amount}
                        codPrice={value.adjustment_amount}
                        aid={SaleNumber(product.location,product.date_added,product.date_closed)+numbers.itemid.toString().slice(numbers.itemid.toString().length-4)}
                        tax={product.tax}
                        amount1={value.platform_amount}
                        amount2={priceLoading ? 0 : isCard ? parseFloat(fee.providerfees_card)-5 : parseFloat(fee.providerfees)-5}
                        total={priceLoading ? 0 : (value.product_total+(isCard ? parseFloat(fee.providerfees_card) : parseFloat(fee.providerfees)))}
                        wamount={referData.amount}
                        wchange={walletchange} 
                        wallet={fee.wallet}  
                        haulierFee={value.buyer_haulier_amount}
                        loading={priceLoading}
                        saleMethod={product.content_head3}
			       	/>
			       	<SelectHerd  />
			       	<CheckoutAddress />
                    {/* <div className="edd-label">
                      <span className="material-icons">date_range</span>
                      Expected delivery dates: {product.date_closed?sDate(props.match.params.type === 'auction'?product.date_closed:new Date()):""} to {product.date_closed?eDate(sDate(props.match.params.type === 'auction'?product.date_closed:new Date())):""}
                    </div> */}
	       	  	</div>

            
	       	  	<div className="col-md-4">
                   <PaymentCard checking={getSaved} firstcall={selectSavedCard} acall={getAllCards} sub={submit} sub2={submitSEPA} handleChange={sepaValueChange} card={savedCard} paymentType={selectPaymentType} loading={loading} loading2={priceLoading}/>
	       	  	</div>
	       	  </div> 
	        </div>
        </div>
    </Layout>
  );
}

export default Checkout