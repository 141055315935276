import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

import  "../../SearchFilters/SearchFilters.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: "100px",
    maxWidth: 200,
    width: "100%",
    height: 45,
    marginRight:0,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: "var(--primColor)",
    "&$checked": {
      color: "var(--primColor)",
    },
    MuiFormControlLabelRoot: {
      marginBottom: 0,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const SearchFilters = (props) => {

  const classes = useStyles();
/*   const [selectedValue, setselectedValue] = React.useState([]);
  const handleChange = (event) => {
    setselectedValue(event.target.value);
  }; */

  return (
    
            <FormControl
              className={classes.formControl}
              variant="outlined"
              size="small"
              style={props.val?{display:"none"}:{}}
            >
              <InputLabel id="demo-mutiple-checkbox-label">
                {props.label}
              </InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                name={props.name}
                value={props.value}
                onChange={props.onchange}
                // input={<Input />}
               
                renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                
                  getContentAnchorEl: () => null,
                }} 
              >


                {props.selection && props.selection.map((menu) => (
                  <MenuItem key={menu} value={`${menu}`}  style={props.val?{display:"none"}:{}}>
                    {menu !=="Leinster North (Coming Soon)" && menu !=="Munster  (Coming Soon)" && menu !=="Connaught  (Coming Soon)" && menu !=="Ulster  (Coming Soon)"?
                    <GreenCheckbox checked={props.value.indexOf(menu) > -1}/>:""}
                    <ListItemText primary={menu}  />
                  </MenuItem>
                ))}
                
              </Select>
            </FormControl>
           

  );
};

export default SearchFilters;
