import React, {useEffect, useState}  from "react";
import InputField from "../../components/Inputs/InputField";
import InputFieldAuto from "../../components/Inputs/InputFieldAuto";
import {ValidateLogin} from "../../Utils/Validators"
import { isAuthenticated } from "../../Utils/Authenticated";
import JwtDecode from "jwt-decode";
import {Popup, Toast} from "../../Utils/Notifications"
import UseForm from "../../Utils/UserForm";
import { request } from "../../Utils/Request"
import "./Login.css";
import CheckBox from "../../components/Inputs/CheckBox";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import { Button, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import FFModal from "../../components/Modal";
import Cookies from 'universal-cookie'
const publicIp = require('public-ip');
const cookies = new Cookies();
const Login = ({props,history})=> {

  const search = history.location.search;
  const params = new URLSearchParams(search);  
  const[btnloading,setBtnloading]=useState(false)
  const [modalopen, setModelopen] = React.useState(false);
  const toggleModal = () => {
    setModelopen(!modalopen);
  };

  async function resend() {
    let userip = await publicIp.v4();
    const login_data = {
      username:values.email,
      password:values.password,
      ipaddress:userip,
      resend: true,
      redirectURL: process.env.REACT_APP_FRONT_URL + '/login'
    }
      try {
        const res = await request('post','/login', login_data, false, true)
        if (res.data.success === "yes") {
          toggleModal()
          Toast.fire({type:"success",title:res.data.message})
        }
      } catch (err) {
        console.log(err);
      }
    
  }

  async function activeUser(id) {
    if (id && id != "") {
      try {
        const res = await request('post','/activateUser', {userid: id}, false, true)
        if(res.data.success){
          Toast.fire({type:"success",title:res.data.message})
          history.push('/login')
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  useEffect(() => {
    if (isAuthenticated()) {
      history.push('/')
    }
    if (params.get('userId') && params.get('userId') != "") {
      activeUser(params.get('userId'));
    }
    setValues({...values,email:(cookies.get('userdata')?cookies.get('userdata').username:''),password:(cookies.get('userdata')?cookies.get('userdata').password:"")})
  }, [])
 const [rem,setRem]=useState(true);
  var {
    handleSubmit,
    handleChange,
    values,
    setValues,
    errors,
    state,
    setState
  } = UseForm(loginSubmit,ValidateLogin )
  const [loader, setLoader] = useState(false)
  
   

  
   const rememberMe=(e)=>{
   
      if(rem){
        
         cookies.set('userdata',{"username":values.email,"password":values.password})
         setRem(!rem)
          console.log("++++++++++++++++++")
      }else
      {
          cookies.remove('userdata')
          setRem(!rem)
       }
       
}
const reme=(e)=>{
  if(e.target.value){
        
   
    setRem(!rem)
    
 }else
 {
     
     setRem(!rem)
  }
}
  
   async function loginSubmit (e){
    try{
      setBtnloading(true)
      setLoader(true)
      let userip = await publicIp.v4();
      const login_data = {
        username:values.email,
        password:values.password,
        ipaddress:userip
      }
      const res = await request (
        "post",
        "/login",
        login_data
      )
      setBtnloading(false)
   if (res.data.status === "yes"){
     rememberMe(e)
    const userData = JwtDecode(res.data.data);
    localStorage.setItem("usertoken", res.data.data);
    localStorage.setItem("userRole", userData.role);
    localStorage.setItem("userId", userData.id);
    localStorage.setItem("userName", userData.first_name);
    localStorage.setItem("trustapID", userData.taxfile);
    localStorage.setItem("trustapGuestID", userData.taxfile_org);
    localStorage.setItem("userCounty", userData.state);
    localStorage.setItem("userFirstName", userData.first_name);
    localStorage.setItem("userLastName", userData.last_name);
    localStorage.setItem("userEmail", userData.email);
    localStorage.setItem("userCompanyName", userData.company_name);
    localStorage.setItem("mailchimpID", userData.otptoken);
    localStorage.setItem("trustapIDToken", userData.oauth_token);
    localStorage.setItem("newspopup", 0);
    

    // link to mailchimp if not

    if ((!userData.otptoken || userData.otptoken == "") && !window.location.hostname.includes("localhost")){
      const res = await request("post", "/mailchimp", {
        type: "mailchimp",
        userid: userData.id,
        email: userData.email,
        role: userData.role
      });
      if(res.data.success){
        localStorage.setItem("mailchimpID", res.data.id);
      }
    }

    // link to trustap if not
    // visiter - tier1  no need
    // buyer - tier2 guest user login
    // seller - tier3 full account login

    if (userData.role =="tier2" && (userData.taxfile_org == "" || userData.taxfile_org == "{}")){

        // guest login
        console.log('guest login====================')
        let userip = await publicIp.v4();
        const res = await request("post", "/codetobackend", {
          guest_user: true,
          userid: localStorage.userId,
          ip: userip
        });
        if(res.data.success){
          localStorage.setItem("trustapGuestID", res.data.code);
        }
    } 
    
    if (userData.role =="tier3" && (userData.oauth_token == "" || userData.taxfile == "" || userData.taxfile_org == "" || userData.taxfile_org == "{}")) {

      if (userData.taxfile_org == "" || userData.taxfile_org == "{}"){

        // guest login
        console.log('guest login====================')
        let userip = await publicIp.v4();
        const res = await request("post", "/codetobackend", {
          guest_user: true,
          userid: localStorage.userId,
          ip: userip
        });
        if(res.data.success){
          localStorage.setItem("trustapGuestID", res.data.code);
        }
  
      } 

      if (userData.oauth_token == "" && userData.taxfile == ""){

        history.push("/trustap-redirect")
        return false
        //let r = 'web_request'
        //window.location.href = process.env.REACT_APP_TRUSTAP_URL + "/auth?response_type=code&client_id=ae44855d-6b59-499b-bd4e-d1e048be70f3&redirect_uri="+process.env.REACT_APP_FRONT_URL+"&scope=openid%20p2p_ls:offline_create%20p2p_ls:offline_update%20p2p_tx:offline_accept_deposit%20p2p_tx:offline_set_price%20p2p_tx:offline_confirm_handover&state=web_request"
      } else if (userData.oauth_token == "" && userData.taxfile != ""){

        history.push("/trustap-redirect-new")
        return false
        //let r = 'web_request'
        //window.location.href = process.env.REACT_APP_TRUSTAP_URL + "/auth?response_type=code&client_id=ae44855d-6b59-499b-bd4e-d1e048be70f3&redirect_uri="+process.env.REACT_APP_FRONT_URL+"&scope=openid%20p2p_ls:offline_create%20p2p_ls:offline_update%20p2p_tx:offline_accept_deposit%20p2p_tx:offline_set_price%20p2p_tx:offline_confirm_handover&state=web_request"
      }
      
    }
    
    if(userData.role==="haulier"){
      await history.push("/haulier-dashboard")
    } else {
      await history.push('/')
    }
 
    // if(userData.role==="tier2"){
      
    //   const res = await request('post','/viewAllCard', {mode: process.env.REACT_APP_PAYMENT_MODE},false, true)

    //   if(res.data.message==="No data"){
    //       await history.push('/saved-details')
    //   }
    //   else{
    //     await history.push('/')
    //   }
      
    // }
    // else if(userData.role==="tier3" || userData.role==="haulier"){
    //   const res=await request('post',"/getBankDetails",{user_id:JwtDecode(isAuthenticated()).id})
    //   console.log("++++++++",res.data.data.length)
    //   if(res.data.data.length !== 0 ){
    //     if(userData.role==="tier3"){
    //       await history.push('/')
    //     }
    //     else{
    //       await history.push("/haulier-dashboard")
    //     }
    //   }
    //   else{
    //     if(userData.role==="tier3"){
    //       await history.push('/saved-details')
    //     }
    //     else{
    //       await history.push("/haulier-dashboard")
    //     }
        
    //   }
    // }
    // else{
    //  await history.push('/')
    // }
     return Toast.fire({ type: 'success', title: `You have Successfully Logged In as ${userData.first_name}` })
   }else{
    setLoader(false)


    if (res.data.message == "Your account is not verified"){
      toggleModal()
    } else {
      return Toast.fire({ type:"warning", title: res.data.message})
    }
    
   }
  
    }
    catch(error){
      console.log(error)
    }

  }


  return (
    <div className="ffLogin">
      <div className="ffLoginCnt">
        <Link to="/"><img src="/svg/logo.svg" alt="Farm Fayre" height="75" /></Link>
        <div className="greetMsg">
          <h2 className="playFair">Login</h2>
          <p className="playFair">Welcome back</p>
          {/* <p className="playFair">Continue logging into your account.</p> */}
        </div>
        <form onSubmit = {handleSubmit}>
          <div>
          <InputFieldAuto
            label="Username/Email Address"
            placeholder="Enter your username/email address"
            type = "text"
            name="email"

            value={values.email}
            onchange= {handleChange}
            helperText={state && errors.email}
          />
          </div>
          <div>
          <InputFieldAuto
            label="Password"
            placeholder="Enter your password"
            type="password"
            name="password"
            value={values.password}
            onchange= {handleChange}
            helperText={state && errors.password}
          />
          </div>
          <div className="fpBox d-flex justify-content-between align-items-center">
            <CheckBox value={rem} onchange={reme} label="Remember Me"/>
            <Link to="/forgotPassword">Forgot Password?</Link>
          </div>
          <div className="actBtn">
            <PrimaryButton label="Login"
              type="submit"
              dis={btnloading}
            />
          </div>
        </form>

      <div className="accHelp">
        <p>Don't have an account?</p>
        <span>Register</span> <Link to="/Reg_tier_1">here</Link>
      </div>
      </div>
      <p className="footerText">© 2023 Farm Fayre. All rights reserved.</p>


      <FFModal
          open={modalopen}
          handleClose={toggleModal}
          modaltitle="Message"
        >
          <div className="regpopup" style={{fontWeight:'500'}}>
            <p>
              Your account is not verified.
           </p>
           <p>Please check your email and verify your account.</p>
        </div>
        <div style={{marginTop:"30px"}}>
        <p>Do you want us to resend the verification email? </p></div>
            <div className="modalCTA d-flex justify-content-end align-items-center">
              <Button className="cancelMe" onClick={toggleModal}>Cancel</Button>

              <PrimaryButton label={"Resend"} onsubmit={resend} />
            </div>
        </FFModal>
    </div>
  );
}
export default Login;
