import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const UseForm = (callback, validate) => {
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    heard_from: "",
    refer_address: "",
    refer_email: "",
    refer_phone: "",
    company_name: "",
    username: "",
    checkedA: true,
    checkedB: true,
    mailing_address: "",
    herd_number: [],
    herd_num1: [],
    secondary_mailing_address: "",
    product_image: [],
    present_country: "Ireland",
    delivery_country: "Ireland",
    collection_country: "Ireland",
    documet: "",
    bank: "",
    branch_identifier: "",
    account_number: "",
    // date:"/",
    phone_number: "+353",
    phone: "+353",
    type: "debit card",
    enablebid: false,
    payment_type: "bankDetails",
    singleitemlot_pickup_location: "present_address",
    multiItems_pickup_location: "present_address",
    singleitemlot_truck_turn: "no",
    singleitemlot_pref_times: "no",
    singleitemlot_qualityAssuranceFarm: "no",
    singleitemlot_auction: true,
    singleitemlot_buynow: true,
    singleitemlot_respricetype_checked: "private",
    singleitemlot_linklots: "no",
    multiItems_assurance: "no",
    multiItems_truck_turn: "no",
    multiItems_pref_times: "no",
    eir_code: "",
    zipcode: "",
    present_eir_code: "",
    collection_eir_code: "",
    delivery_eir_code: "",
    multiItems_auction_checked: true,
    multiItems_buynow_checked: true,
    multiItems_resprice_checked: "private",
    multiItems_linklots: "no",
    cvv: "",
    card_name: "",
    date: "",
    card_number: "",
    sellertime: [],
    sellertime_end: [],
    buyertime: [],
    buyertime_end: [],
    present_buildingName: "",
    present_street_name: "",
    present_town: "",
    present_county: "",
    init_amount:""
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, setState] = useState(false);
  /* const [selectedDate, setSelectedDate] = useState(new Date()); */

  const handleSubmit = (event) => {
    event.preventDefault();
    setState(true);
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const handleChange = (evt) => {
    var { name, value } = evt.target;
    //console.log("wwwwwwwwwwwwwwwwwwwwwwww",name,value)
    if (
      name === "checkedA" ||
      name === "terms" ||
      name === "checkedB" ||
      name === "singleitemlot_auction" ||
      name === "singleitemlot_buynow" ||
      name === "multiItems_buynow_checked" ||
      name === "multiItems_auction_checked" ||
      name === "password_toggle"
    ) {
      value = evt.target.checked;
    }
    if (
      name === "date" &&
      value.length == 2 &&
      !values.date.toString().includes("/")
    ) {
      //   evt.target =evt.target+"/";
      value = value + "/";
      console.log("sett", value.length);
    }

    if (name === "refer_phone") {
      value = value.replace(/[^0-9]/g, "");
    }

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleDateChange = (date, name) => {
    setValues({
      ...values,
      [name]: date,
    });
  };

  // For change values

  useEffect(() => {
    setIsSubmitting(false);
    setErrors(validate(values));
    // eslint-disable-next-line
  }, [values]);

  // For errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
    // eslint-disable-next-line
  }, [errors]);

  return {
    handleSubmit,
    handleChange,
    handleDateChange,
    values,
    errors,
    state,
    setValues,
    setState,
    setErrors
  };
};

export default UseForm;
