import { parse } from "date-fns";

export function ValidateRegisterationtier_1(values) {
  let errors = {};

  if (!values.first_name) {
    errors.first_name = "First Name is required!";
  }
  if (!values.username) {
    errors.username = "User Name is required!";
  }else if (/[^A-Za-z0-9 ]/.test(values.username)) {
    errors.username = "Username is invalid!";
  }
  if (!values.heard_from) {
    errors.heard_from = "It is required field";
  }

  if (!values.last_name) {
    errors.last_name = "Last Name is required!";
  }
  // if (!values.company_name) {
  //   errors.company_name = "Company Name is required!";
  // }
  if (values.heard_from == "Where did you hear about us?") {
    errors.heard_from = "It is required field!";
  }
  if (!values.email) {
    errors.email = "Email address is required!";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid!";
  }

  if (!values.password) {
    errors.password = "Password is required!";
  } else if (values.password.length < 6) {
    errors.password = "Password needs to be more than 6 characters!";
  }

  if (!values.confirm_password) {
    errors.confirm_password = "Confirm password is required!";
  } else if (values.confirm_password !== values.password) {
    errors.confirm_password = "Password and confirm password didn't match!";
  }

  if (!values.heard_from) {
    errors.heard_from = "Where did you hear about us is required!";
  }
  if (!values.terms) {
    errors.terms = "Please accept Privacy Policy and the Terms & Conditions";
  }

  return errors;
}
export function contactus(values) {
  let errors = {};

  if (!values.first_name) {
    errors.first_name = "First Name is required!";
  }

  if (!values.last_name) {
    errors.last_name = "Last Name is required!";
  }
  // if (!values.company_name) {
  //   errors.company_name = "Company Name is required!";
  // }

  if (!values.email) {
    errors.email = "Email address is required!";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid!";
  }

  if (!values.Message) {
    errors.msg = "Please Enter message";
  }

  return errors;
}

export function ValidateRegisterationtier_2(values) {
  let errors = {};

  if (!values.phone_number) {
    errors.phone_number = "Phone number is required!";
  } else if (
    !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{5,8}$/im.test(
      values.phone_number
    )
  ) {
    errors.phone_number = "Phone number is not valid!";
  }
  console.log("+++++++++++++m", values.herd_number.length);
  // if (values.herd_number[0] == "") {
  //   errors.herd_number_1 =
  //     "Minimum One Herd number is required.And it should have be started with first row.";
  // }
  // if (!values.present_buildingName) {
  //   errors.present_buildingName = "Building Name is required";
  // }
  if (!values.present_street_name) {
    errors.present_street_name = "Address Line 2 is required";
  }
  if(!values.registered_number){
    errors.registered_number="Herd number is required"

  }
  if (!values.present_town) {
    errors.present_town = "Town Name is required";
  }
  if (!values.present_county) {
    errors.present_county = "County is required";
  }
  // if (!values.present_eir_code) {
  //   errors.present_eir_code = "Eir Code is required!";
  // } else
  if (
    values.present_eir_code &&
    !/^([A-Z\-\s]){1}(([0-9]){2}|([0-9]{1}[W]))(([ ]([A-Z0-9]){4})|([A-Z0-9]){4})$/i.test(
      values.present_eir_code
    )
  ) {
    errors.present_eir_code = "Eir Code is Invalid!";
  }
  if (!values.present_country) {
    errors.present_country = "Country is required!";
  }

  if (!values.checkedA) {
    // if (!values.collection_buildingName) {
    //   errors.collection_buildingName = "Collection Building Name is required";
    // }
    if (!values.collection_street_name) {
      errors.collection_street_name = "Collection Address Line 2 is required";
    }
    if (!values.collection_town) {
      errors.collection_town = "Collection Town Name is required";
    }
    if (!values.collection_county) {
      errors.collection_county = "Collection County is required";
    }
    // if (!values.collection_eir_code) {
    //   errors.collection_eir_code = "Collection Eir Code is required!";
    // } else if (!/^([A-Z\-\s]){1}(([0-9]){2}|([0-9]{1}[W]))[ ]([A-Z0-9]){4}$/i.test(values.collection_eir_code)) {
    //   errors.collection_eir_code = "Collection Eir Code is Invalid!";
    // }
    if (
      values.collection_eir_code &&
      !/^([A-Z\-\s]){1}(([0-9]){2}|([0-9]{1}[W]))(([ ]([A-Z0-9]){4})|([A-Z0-9]){4})$/i.test(
        values.collection_eir_code
      )
    ) {
      errors.collection_eir_code = "Eir Code is Invalid!";
    }
    if (!values.collection_country) {
      errors.collection_country = "Collection Country is required!";
    }
  }
  if (!values.terms) {
    errors.terms = "Please accept Privacy Policy and the Terms & Conditions";
  }
  if (!values.checkedB) {
    // if (!values.delivery_buildingName) {
    //   errors.delivery_buildingName = "Delivery Building Name is required";
    // }
    if (!values.delivery_street_name) {
      errors.delivery_street_name = "Delivery Adress Line 2 is required";
    }
    if (!values.delivery_town) {
      errors.delivery_town = "Delivery Town Name is required";
    }
    if (!values.delivery_county) {
      errors.delivery_county = "Delivery County is required";
    }
    // if (!values.delivery_eir_code) {
    //   errors.delivery_eir_code = "Delivery Eir Code is required!";
    // } else if (!/^([A-Z\-\s]){1}(([0-9]){2}|([0-9]{1}[W]))[ ]([A-Z0-9]){4}$/i.test(values.delivery_eir_code)) {
    //   errors.delivery_eir_code = "Delivery Eir Code is Invalid!";
    // }
    if (
      values.delivery_eir_code &&
      !/^([A-Z\-\s]){1}(([0-9]){2}|([0-9]{1}[W]))(([ ]([A-Z0-9]){4})|([A-Z0-9]){4})$/i.test(
        values.delivery_eir_code
      )
    ) {
      errors.delivery_eir_code = "Eir Code is Invalid!";
    }
    if (!values.delivery_country) {
      errors.delivery_country = "Delivery Country is required!";
    }
  }
  return errors;
}

export function ValidateRegisterationtier_3(values) {
  let errors = {};

  return errors;
}

export function ValidateLogin(values) {
  let errors = {};
  // also add username for login
  if (!values.email) {
    errors.email = "Email address is required!";
    // } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    //   errors.email = "Email address is invalid!";
  }

  if (!values.password) {
    errors.password = "Password is required!";
  } else if (values.password.length < 6) {
    errors.password = "Password needs to be more than 6 characters!";
  }
  return errors;
}

export function ValidateForgotPassword(values) {
  let errors = {};
  if (!values.email) {
    errors.email = "Email address is required!";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid!";
  }
  return errors;
}

export function ResetPassword(values) {
  let errors = {};
  if (!values.password) {
    errors.password = "Password  is required!";
  } else {
    if (values.password.length < 6) {
      errors.password = "Password needs to be more than 6 characters!";
    }
  }
  if (!values.cpassword) {
    errors.cpassword = "confirm password  is required!";
  } else {
    if (values.cpassword.length < 6) {
      errors.cpassword = "Confirmpassword needs to be more than 6 characters!";
    }
  }

  return errors;
}

export function ValidatePasswordChange(values) {
  let errors = {};
  if (!values.password) {
    errors.password = "New Password is required!";
  } else if (values.password.length < 6) {
    errors.password = "New Password needs to be more than 6 characters!";
  }

  if (!values.confirm_password) {
    errors.confirm_password = "Confirm password is required!";
  } else if (values.confirm_password !== values.password) {
    errors.confirm_password = "Password and confirm password didn't match!";
  }

  return errors;
}

export function ValidatePostSingleLot(values) {
  let errors = {};
  if (!values.singleitemlot_title) {
    errors.singleitemlot_title = "Please add your lot title!";
  }
  if (!values.singleitemlot_tbStatus) {
    errors.singleitemlot_tbStatus = "Please select Tbstatus";
  }
  if (!values.singleitemlot_export) {
    errors.singleitemlot_export = "Please select export";
  }

  if (!values.singleitemlot_calfReg) {
    errors.singleitemlot_calfReg = "Please select calfReg";
  }
  if (!values.singleitemlot_weighing_method) {
    errors.singleitemlot_weighing_method = "please select weighing method";
  }
  if (!values.singleitemlot_description) {
    errors.singleitemlot_description = "Please add description of your lot!";
  }
  if (!values.singleitemlot_section) {
    errors.singleitemlot_section = "Please select section!";
  }
  if (!values.singleitemlot_type) {
    errors.singleitemlot_type = "Please select type!";
  }
  if (!values.singleitemlot_breed) {
    errors.singleitemlot_breed = "Please select breed!";
  }
  if (!values.singleitemlot_sex) {
    errors.singleitemlot_sex = "Please select sex!";
  }
  if (!values.singleitemlot_weight) {
    errors.singleitemlot_weight = "Please add weight!";
  }
  if (!values.singleitemlot_dob) {
    errors.singleitemlot_dob = "Please add Date of birth!";
  }
  if (!values.start_date) {
    errors.start_date = "Please Select Auction start Date";
  }
  if (!values.end_date) {
    errors.end_date = "Please Select Auction end Date";
  }
  if (!values.singleitemlot_tagnumber) {
    errors.singleitemlot_tagnumber = "Please add tag number!";
  } /* else if (!/^([0-9\-\s]){14,18}$/i.test(values.singleitemlot_tagnumber)){
        errors.singleitemlot_tagnumber = 'Invalid tag number!'
    } */

  if (!values.singleitemlot_cardissued) {
    errors.singleitemlot_cardissued = "Please add card issued date!";
  }
  if (!values.singleitemlot_testdate) {
    errors.singleitemlot_testdate = "Please add last test date!";
  }
  if (!values.singleitemlot_prevowner) {
    errors.singleitemlot_prevowner = "Please add 0 if no previous owner!";
  }
  // if (!values.singleitemlot_premiums) {
  //   errors.singleitemlot_premiums = "Please add 0 if no premiums!";
  // }
  if (!values.singleitemlot_truck_turn) {
    errors.singleitemlot_truck_turn = "Please select one!";
  }
  if (!values.product_image.length) {
    errors.product_image = "Minimum one image needed!";
  }

  if (values.singleitemlot_pickup_location === "different_address") {
    if (!values.singleitemlot_bldgname) {
      errors.singleitemlot_bldgname = "Building Name is required";
    }
    if (!values.singleitemlot_stname) {
      errors.singleitemlot_stname = "Street Name is required";
    }
    if (!values.singleitemlot_town) {
      errors.singleitemlot_town = "Town Name is required";
    }
    if (!values.singleitemlot_county) {
      errors.singleitemlot_county = "County is required";
    }
    if (!values.singleitemlot_eircode) {
      errors.singleitemlot_eircode = "Eir Code is required!";
    } else if (
      !/^([A-Z\-\s]){1}(([0-9]){2}|([0-9]{1}[W]))(([ ]([A-Z0-9]){4})|([A-Z0-9]){4})$/i.test(
        values.zipcode
      )
    ) {
      errors.singleitemlot_eircode = "Eir Code is Invalid!";
    }
    if (!values.singleitemlot_country) {
      errors.singleitemlot_country = "Country is required!";
    }
  }

  if (values.singleitemlot_auction) {
    if (values.singleitemlot_startprice > values.singlemlot_resprice) {
      errors.singleitemlot_resprice =
        "Resereve price is must be greater then or equal to start price";
    }
  }

  return errors;
}

export function multipleadrees(values) {
  let errors = {};
  if (!values.address1) {
    errors.address1 = "Address Line 1 is required";
  }
  if (!values.address2) {
    errors.address2 = "Address Line 2 is required";
  }
  if (!values.city) {
    errors.city = "Town Name is required";
  }
  if (!values.state) {
    errors.state = "County is required";
  }
  if (!values.zipcode) {
    errors.zipcode = "Eir Code is required!";
  } else if (
    !/^([A-Z\-\s]){1}(([0-9]){2}|([0-9]{1}[W]))(([ ]([A-Z0-9]){4})|([A-Z0-9]){4})$/i.test(
      values.zipcode
    )
  ) {
    errors.zipcode = "Eir Code is Invalid!";
  }
  if (!values.country) {
    errors.country = "Country is required!";
  }

  return errors;
}

export function ValidateMultipleLot(values) {
  let errors = {};

  // if (!values.multiItems_section) {
  //   errors.multiItems_section = "Please select section!";
  // }
  if (!values.multiItems_breed) {
    errors.multiItems_breed = "Please select breed!";
  }
  if (!values.multiItems_tbStatus) {
    errors.multiItems_tbStatus = "Please select tbstatus!";
  }
  if (!values.multiItems_export) {
    errors.multiItems_export = "Please select export!";
  }
  if (!values.multiItems_calfReg) {
    errors.multiItems_calfReg = "Please select calfreg!";
  }
  // if (!values.multiItems_notes) {
  //   errors.multiItems_notes = "Please enter notes!";
  // }

  if (!values.multiItems_sex) {
    errors.multiItems_sex = "Please select sex!";
  }
  if (!values.multiItems_weight) {
    errors.multiItems_weight = "Please add weight!";
  }
  if (!values.multiItems_dob) {
    errors.multiItems_dob = "Please add Date of birth!";
  }
  // if (!values.start_date) {
  //   errors.start_date = "Please Select Auction start Date";
  // }
  // if (!values.end_date) {
  //   errors.end_date = "Please Select Auction end Date";
  // }
  if (!values.multiItems_tagnumber) {
    errors.multiItems_tagnumber = "Please add tag number!";
  } /* else if (!/^([0-9\-\s]){14,18}$/i.test(values.multiItems_tagnumber)){
        errors.multiItems_tagnumber = 'Invalid tag number!'
    } */

  if (!values.multiItems_cardissued) {
    errors.multiItems_cardissued = "Please add card issued date!";
  }
  if (!values.multiItems_testdate) {
    errors.multiItems_testdate = "Please add last test date!";
  }
  if (!values.product_image.length) {
    errors.product_image = "Minimum one image needed!";
  }
  if (!values.multiItems_prevowner) {
    errors.multiItems_prevowner = "Please add 0 if no previous owner!";
  }
  // if (!values.multiItems_premiums) {
  //   errors.multiItems_premiums = "Please add 0 if no premiums!";
  // }
  // if (!values.multiItems_truck_turn) {
  //   errors.multiItems_truck_turn = "Please select one!";
  // }

  return errors;
}
export function Multiple(values) {
  let errors = {};
  //console.log("+++++++++++++++++++++++++++++",values)
  if (!values.multiItems_title) {
    errors.multiItems_title = "Please add your lot title!";
  }
  if (!values.multiItems_section) {
    errors.multiItems_section = "Please select section!";
  }
  if (!values.sale_method) {
    errors.sale_method = "please select sale method";
  }
  if (!values.multiItems_weighing_method && values.sale_method == "Weighing Method") {
    errors.multiItems_weighing_method = "please select weighing method";
  }
  if (!values.multiItems_description) {
    errors.multiItems_description = "Please add description of your lot!";
  }
  if (!values.multiItems_type) {
    errors.multiItems_type = "Please select type!";
  }
  //  if (!values.multiItems_breed) {
  //    errors.multiItems_breed = "Please select breed!";
  //  }
  if (!values.product_image.length) {
    errors.product_image = "Minimum one image needed!";
  }

  if (values.multiItems_pickup_location === "different_address") {
    if (!values.multiItems_bldgname) {
      errors.multiItems_bldgname = "Address Line 1 is required";
    }
    if (!values.multiItems_st) {
      errors.multiItems_stname = "Address Line 2 is required";
    }
    if (!values.multiItems_town) {
      errors.multiItems_town = "Town Name is required";
    }
    if (!values.multiItems_county) {
      errors.multiItems_county = "County is required";
    }
    if (!values.multiItems_eircode) {
      errors.multiItems_eircode = "Eir Code is required!";
    } else if (
      !/^([A-Z\-\s]){1}(([0-9]){2}|([0-9]{1}[W]))(([ ]([A-Z0-9]){4})|([A-Z0-9]){4})$/i.test(
        values.multiItems_eircode
      )
    ) {
      errors.multiItems_eircode = "Eir Code is Invalid!";
    }
    if (!values.multiItems_country) {
      errors.multiItems_country = "Country is required!";
    }
  }
  //  if (!values.start_date) {
  //   errors.start_date = "Please Select Auction start Date";
  // }
  // if (!values.end_date) {
  //   errors.end_date = "Please Select Auction end Date";
  // }
  if (values.multiItems_auction_checked) {
    if (!values.multiItems_startprice) {
      errors.multiItems_startprice = "Start price is required";
    }

    if (!values.multiItems_resprice) {
      errors.multiItems_resprice = "Reserve price is required";
    } else {
      if (
        parseInt(values.multiItems_resprice) <=
        parseInt(values.multiItems_startprice)
      ) {
        console.log(
          "++++++++++++++",
          values.multiItems_resprice,
          values.multiItems_startprice
        );
        errors.multiItems_resprice =
          "Reserve price needs to be greater than start price";
      }
    }
  }
  if (values.multiItems_buynow_checked) {
    if (!values.multiItems_buynowprice) {
      errors.multiItems_buynowprice = "buynow price is required";
    }
    if (
      parseInt(values.multiItems_buynowprice) <
      parseInt(values.multiItems_startprice)
    ) {
      console.log(
        "++++++++",
        values.multiItems_buynowprice,
        values.multiItems_startprice
      );
      errors.multiItems_buynowprice =
        "Buynow price needs to be greater than start price";
    }
    if (
      parseInt(values.multiItems_resprice) >
      parseInt(values.multiItems_buynowprice)
    ) {
      errors.multiItems_resprice =
        "Reserve price needs to be less than buynow price";
    }
  }

  if(!values.product_videos){
    errors.product_videos="Please Upload Video!"
  }

  return errors;
}
export function VaildateUpdateProfile(values) {
  let errors = {};
  if (!values.first_name) {
    errors.first_name = "First Name can not be empty!";
  }
  if (!values.last_name) {
    errors.last_name = "Last Name can not be empty!";
  }
  if (!values.username) {
    errors.username = "User Name can not be empty!";
  }else if (/[^A-Za-z0-9 ]/.test(values.username)) {
    errors.username = "Username is invalid!";
  }
  // if (!values.company_name) {
  //   errors.company_name = "Company Name can not be empty!";
  // }
  if (!values.phone) {
    errors.phone = "Phone number can not be empty!!";
  } else if (
    !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{5,8}$/im.test(
      values.phone
    )
  ) {
    errors.phone = "Phone number is not valid!";
  }
  if (!values.address1) {
    errors.address1 = "Address Line 1 can not be empty!";
  }
  if (!values.address2) {
    errors.address2 = "Address Line 2 can not be empty!";
  }
  if (!values.town) {
    errors.town = "Town Name can not be empty!";
  }
  if (!values.county) {
    errors.county = "County can not be empty!";
  }
  if (!values.country) {
    errors.country = "Country can not be empty!";
  }
  if (!values.eir_code) {
    errors.eir_code = "EIR Code can not be empty!";
  } else if (
    !/^([A-Z\-\s]){1}(([0-9]){2}|([0-9]{1}[W]))(([ ]([A-Z0-9]){4})|([A-Z0-9]){4})$/i.test(
      values.eir_code
    )
  ) {
    errors.eir_code = "EIR Code is invalid!";
  }

  if (values.password_toggle) {
    if (!values.current_password) {
      errors.current_password = "Current Password is required!";
    }
    if (!values.new_password) {
      errors.new_pwd = true;
      errors.new_password = "New password is required!";
    } else if (values.new_password.length < 6) {
      errors.new_pwd = true;
      errors.new_password = "New Password need to be more than 6 characters!";
    }

    if (!values.confirm_password) {
      errors.cfm_pwd = true;
      errors.confirm_password = "Confirm password is required!";
    } else if (values.confirm_password !== values.new_password) {
      errors.cfm_pwd = true;
      errors.confirm_password = "Password and confirm password didn't match!";
    }
  }

  return errors;
}
// export function regcarddetails(values) {
//   let errors = {};

//   if (!values.card_name) {
//     errors.card_name = "Card Name is required!";
//   } else if (
//     values.card_name.match(/\d+/) ||
//     values.card_name.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)
//   ) {
//     errors.card_name = "Card Name Should Contain Only Letters";
//   }
//   if (!values.card_number) {
//     errors.card_number = "Card Number is required!";
//   } else if (values.card_number.split(" ").join("").length != 16) {
//     errors.card_number = "Card Number should be minmum of 16 Digits";
//   }
//   if (!values.date) {
//     errors.date = "Date is required!";
//   }
//   // else if(values.date.length!=4){
//   //   errors.date = "Please give this date format MMYY";
//   // }
//    else {
//     let date = new Date();
//     let year = date.getFullYear().toString().substr(-2);
//     let cmonth = date.getMonth();
//     let year1;
//     const [valueMonth,valueYear] = values.date.toString().slice('/');
//     // const year1=values.date.toString().substr(-1);

//     console.log("++++++",valueMonth,year1)
//     if (values.date.length == 7) {
//       year1 = valueYear.toString().substr(-2);
//     } else {
//       year1 = valueYear;
//     }
//     // console.log("year",Number(cmonth))
//     if (
//       Number(valueMonth) === 0 ||
//       Number(valueMonth) > 12 ||
//       Number(year1) < Number(year) ||

//       (Number(valueMonth) < Number(cmonth) && Number(year1) == Number(year))
//     ) {
//       errors.date = "Expiry date should be future date!";
//     }
//   }
//   if (!values.cvv) {
//     errors.cvv = "CVV is required!";
//   } else if (values.cvv.length > 4 || values.cvv.length < 3) {
//     console.log("cvv", values.cvv.length);
//     errors.cvv = "CVV should be 3-4 digits";
//   }

//   return errors;
// }
export function bankdetails(values) {
  let errors = {};
  // console.log("++++++++++++",values.bank)
  // if(!values.bank){
  //   errors.bank="Please select bank name"
  // }
  // if(!values.iso_country_code){
  //   errors.iso_country_code="Please select iso code"
  // }
  // if(!values.branch_identifier){
  //   errors.branch_identifier="Please enter bank branch"
  // }
  // if(!values.account_number){
  //   errors.account_number="Please enter account number"
  // }
  if (!values.bank) {
    errors.bank = "Please select bank name";
  }
  if (!values.iso_country_code) {
    errors.iso_country_code = "Please enter IBAN";
  }
  if (!values.branch_identifier) {
    errors.branch_identifier = "Please enter account holder name";
  }
  return errors;
}

export function regdocdetails(values) {
  let errors = {};
  console.log("dis++", values.terms);

  if (!values.banner_image) {
    errors.banner_image = "Please upload profile proof images";
  }
  // if (!values.herd_image) {
  //   errors.herd_image = "Please upload herd proof images";
  // }
  // if (!values.address_image) {
  //   errors.address_image = "Please upload address proof images";
  // }
  if (!values.terms) {
    errors.terms = "Please accept Privacy Policy and the Terms & Conditions";
  }
  return errors;
}

export function regcarddetails(values) {
  let errors = {};
  console.log("dis++", values.terms);
  if (values.payment_type === "debitCard") {
    if (!values.card_name) {
      errors.card_name = "Card Name is required!";
    }

    if (!values.card_number) {
      errors.card_number = "Card Number is required!";
    } else if (values.card_number.split(" ").join("").length < 16) {
      errors.card_number = "Card Number is invalid!";
    }
    if (!values.date) {
      errors.date = "Date is required!";
    } else {
      let date = new Date();
      let year = date.getFullYear().toString().substr(-2);
      let month = date.getMonth() + 1;
      const [valueMonth, valueYear] = values.date.split("/");
      if (!values.date.includes("/")) {
        errors.date = "Date is invalid!";
      }
      if (
        Number(valueMonth) === 0 ||
        Number(valueMonth) > 12 ||
        Number(valueYear) < Number(year)
      ) {
        errors.date = "Date is invalid!";
      }
      if (
        Number(month) > Number(valueMonth) &&
        Number(valueYear) === Number(year)
      ) {
        errors.date = "Date is invalid!";
      }
    }
    if (!values.cvv) {
      errors.cvv = "CVV is required!";
    } else if (values.cvv.length < 3) {
      errors.cvv = "CVV is invalid!";
    }
  } else {
    if (!values.bank) {
      errors.bank = "Please select bank name";
    }
    if (!values.iso_country_code) {
      errors.iso_country_code = "Please enter IBAN";
    }
    if (!values.branch_identifier) {
      errors.branch_identifier = "Please enter account holder name";
    }
    // if(!values.account_number){
    //   errors.account_number="Please enter account number"
    // }
  }

  if (!values.banner_image) {
    errors.banner_image = "Please upload profile proof images";
  }
  if (!values.herd_image) {
    errors.herd_image = "Please upload herd proof images";
  }
  if (!values.address_image) {
    errors.address_image = "Please upload address proof images";
  }
  if (!values.terms) {
    errors.terms = "Please accept Privacy Policy and the Terms & Conditions";
  }
  return errors;
}
export function cardvalidate(values) {
  let errors = {};

  if (values.type === "debit card") {
    if (!values.card_name) {
      errors.card_name = "Card Name is required!";
    }

    if (!values.card_number) {
      errors.card_number = "Card Number is required!";
    } else if (values.card_number.split(" ").join("").length < 16) {
      errors.card_number = "Card Number is invalid!";
    }
    if (!values.date) {
      errors.date = "Date is required!";
    } else {
      let date = new Date();
      let year = date.getFullYear().toString().substr(-2);
      let month = date.getMonth() + 1;
      const [valueMonth, valueYear] = values.date.split("/");
      if (!values.date.includes("/")) {
        errors.date = "Date is invalid!";
      }
      if (
        Number(valueMonth) === 0 ||
        Number(valueMonth) > 12 ||
        Number(valueYear) < Number(year)
      ) {
        errors.date = "Date is invalid!";
      }
      if (
        Number(month) > Number(valueMonth) &&
        Number(valueYear) === Number(year)
      ) {
        errors.date = "Date is invalid!";
      }
    }
    if (!values.cvv) {
      errors.cvv = "CVV is required!";
    } else if (values.cvv.length < 3) {
      errors.cvv = "CVV is invalid!";
    }
  } else {
    if (!values.bank) {
      errors.bank = "Please select bank name";
    }
    if (!values.iso_country_code) {
      errors.iso_country_code = "Please enter IBAN";
    }
    if (!values.branch_identifier) {
      errors.branch_identifier = "Please enter account holder name";
    }
  }

  return errors;
}

export function validatePickup(values) {
  let errors = {};
  if (!values.documet) {
    errors.documet = "Document is required";
  }
  if (!values.cnumber) {
    errors.cnumber = "confirm number delivered is required";
  }
  if (!values.weight_b && values.saleMethod != "Not Sold on Weight") {
    errors.weight_b = "weight before unloading is required";
  }
  if (!values.weight_a && values.saleMethod != "Not Sold on Weight") {
    errors.weight_a = "weight after unloading is required";
  }
  if (!values.sign) {
    errors.sign = "sign is required";
  }
  if (!values.notes && values.complaint == "yes") {
    errors.notes = "notes is required";
  }
  if (!values.terms) {
    errors.terms = "Please accept the condition";
  }
  return errors;
}

export function validateCard(values) {
  let errors = {};

  if (!values.card_name) {
    errors.card_name = "Card Name is required!";
    errors.condition = true;
  } else if (
    values.card_name.match(/\d+/) ||
    values.card_name.match(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)
  ) {
    errors.card_name = "Card Name Should Contain Only Letters";
    errors.condition = true;
  }
  if (!values.card_number) {
    errors.card_number = "Card Number is required!";
    errors.condition = true;
  } else if (values.card_number.split(" ").join("").length != 16) {
    errors.card_number = "Card Number should be minmum of 16 Digits";
    errors.condition = true;
  }
  if (!values.date) {
    errors.date = "Date is required!";
    errors.condition = true;
  } else {
    let date = new Date();
    let year = date.getFullYear().toString().substr(-2);
    let cmonth = date.getMonth();
    let year1;
    const [valueMonth, valueYear] = values.date.split("/");
    if (values.date.length == 7) {
      year1 = valueYear.toString().substr(-2);
    } else {
      year1 = valueYear;
    }
    // console.log("year",Number(cmonth))
    if (
      Number(valueMonth) === 0 ||
      Number(valueMonth) > 12 ||
      Number(year1) < Number(year) ||
      values.date.length == 6 ||
      (Number(valueMonth) < Number(cmonth) && Number(year1) == Number(year))
    ) {
      errors.date = "Expiry date should be future date!";
      errors.condition = true;
    }
  }
  if (!values.cvv) {
    errors.cvv = "CVV is required!";
    errors.condition = true;
  } else if (values.cvv.length > 4 || values.cvv.length < 3) {
    console.log("cvv", values.cvv.length);
    errors.cvv = "CVV should be 3-4 digits";
    errors.condition = true;
  }
  if (errors.condition) {
    errors.condition = true;
  } else {
    errors.condition = false;
  }

  return errors;
}
