import React, { useState, useEffect } from 'react';
import './Header.css';
import { NavLink } from 'react-router-dom';
import PrimaryButton from '../Inputs/PrimaryButton';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AvatarURL } from '../../Utils/constant';
import { Button, Divider, ListItem } from '@material-ui/core';
import { request } from '../../Utils/Request';
import FFModal from '../Modal';
import FFModalPopup from '../Modal/Popup';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ReactHtmlParser from 'react-html-parser';
import JwtDecode from "jwt-decode";
import { isAuthenticated } from "../../Utils/Authenticated";
import { Helmet } from 'react-helmet-async'
const Header = (props, history) => {
  history = useHistory();
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [modalopen, setModalopen] = useState(false);
  const [modalopen2, setModalopen2] = useState(false);
  const [profData, setProfdata] = useState({
    name: '',
    image: '',
  });
  const [open_call,setOpenCall]=useState(true)
  const [state, setState] = React.useState({
    left: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const [content_web, setContentWeb] = useState('');
  const [content_mobile, setContentMobile] = useState('');

  const getContent = async (e) => {
    console.log('getContent');
    const res = await request('post', '/show_static_content', {
      page_id: 'Homepage Popup (Web)',
    });
    if (res && res.data.status === 'true') {
      setContentWeb(res.data.data[0].content);
    }
    const res2 = await request('post', '/show_static_content', {
      page_id: 'Homepage Popup (Mobile)',
    });
    if (res2 && res2.data.status === 'true') {
      setContentMobile(res2.data.data[0].content);
    }
    console.log('getContent res', res, res2);
    if (
      res &&
      res.data.status == 'true' &&
      res2 &&
      res2.data.status == 'true' &&
      res.data.data[0].content != '' &&
      res2.data.data[0].content != ''
    ) {
      setModalopen2(true);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const handleLogout = () => {
    localStorage.clear();
    localStorage.setItem('newspopup', 1);
    window.location.href = '/';
  };
  const handleRegister = () => {
    localStorage.clear();
    localStorage.setItem('newspopup', 1);
    window.location.href = '/Reg_tier_1';
  };
  const moveon = () => {
    if (localStorage.getItem('userRole') == 'tier3') {
      // window.location.href = "/post-project-select-lot-size";
      if (!localStorage.getItem("trustapIDToken") && localStorage.getItem("trustapID") != ""){
        window.location.href = "/trustap-redirect-new";
      } else {
        if (localStorage.getItem("trustapID") != "") {
          window.location.href = "/multiple-items-step1";
        } else if (localStorage.getItem("trustapIDToken") != ""){
          window.location.href = "/trustap-redirect-new";
        } else {
          window.location.href = "/trustap-redirect";
        }
      }
    } else if (localStorage.getItem('userRole') == 'tier2') {
      window.location.href = '/Reg_tier_3';
    } else {
      window.location.href = '/Reg_tier_2';
    }

    // else {
    //   setModalopen(!modalopen);
    // }
  };
  const move = () => {
    if (localStorage.getItem('userRole') == 'tier1') {
      window.location.href = '/Reg_tier_2';
    } else {
      window.location.href = '/Reg_tier_3';
    }
  };
  const toggleModal = () => {
    setModalopen(!modalopen);
  };
  const toggleModal2 = () => {
    setModalopen2(!modalopen2);
  };

  const getprofData = async () => {
    try {
      const res = await request(
        'post',
        '/getUserProfileDetails',
        {
          user_id: localStorage.userId,
        },
        false,
        true
      );
      if (res.data.success === 'yes') {
        setProfdata({
          name: res.data.data.first_name,
          image: res.data.data.avatar,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      !localStorage.getItem('newspopup') ||
      localStorage.getItem('newspopup') == 0
    ) {
      getContent();
      localStorage.setItem('newspopup', 1);
    }
    getprofData();
  }, []);

  const list = (anchor) => (
    <div
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className='ffLogoHead d-flex justify-content-between align-items-center'>
        <Link to='/'>
          <img src='/svg/logo.svg' alt='Farm Fayre' height='50' />
        </Link>
        <Button
          className='headDrawerClose'
          onClick={toggleDrawer('left', false)}
        >
          <span className='material-icons'>clear</span>
        </Button>
      </div>
      {/* {console.log(document.body.clientWidth, window.innerWidth, 'Width')} */}
      <Divider />
      <div className='headerDrawerContainer'>
        {localStorage.userId ? (
          <div className='hdcAvatar'>
            <img
              className='user-img'
              src={
                profData.image !== ''
                  ? AvatarURL + profData.image
                  : '/images/user-img.svg'
              }
              alt='profile.jpg'
            />
            <a className='noLink'>Hi {localStorage.userName}</a>
          </div>
        ) : (
          <div className='headerDrawerLinks'>
            <NavLink to='/login' activeClassName='active'>
              <ListItem button>
                <span className='material-icons'>login</span>Login
              </ListItem>
            </NavLink>

            <NavLink to='/Reg_tier_1' activeClassName='active'>
              <ListItem button>
                <span className='material-icons'>person_add</span>Register
              </ListItem>
            </NavLink>
          </div>
        )}
      </div>

      <div className='headerDrawerLinks'>
        {(localStorage.getItem('userRole') == 'tier2' ||
          localStorage.getItem('userRole') == 'tier3' ||
          localStorage.getItem('userRole') == 'tier1') && (
            <Link onClick={moveon} activeClassName='active'>
              {localStorage.getItem('userRole') == 'tier3' ? (
                <ListItem button>
                  <span className='material-icons'>markunread_mailbox</span>Post A
                  Listing
                </ListItem>
              ) : localStorage.getItem('userRole') == 'tier2' ? (
                <ListItem button>
                  <span className='material-icons'>markunread_mailbox</span>
                  Post A
                  Listing
                </ListItem>
              ) : (
                <ListItem>
                  <span className='material-icons'>markunread_mailbox</span>
                  Register As Buyer/Seller
                </ListItem>
              )}
            </Link>
          )}

        <NavLink to='/search' activeClassName='active'>
          <ListItem button>
            <span className='material-icons'>search</span>
            Search
          </ListItem>
        </NavLink>
        {isAuthenticated() &&
          JwtDecode(localStorage.getItem("usertoken")).role === "tier3" ? (
          <NavLink to='/sellerfees' activeClassName='active'>
            <ListItem button>
              <span className='material-icons'>shop</span>
              Buyer/Seller Fees
            </ListItem>
          </NavLink>) : ""}
        {isAuthenticated() &&
          JwtDecode(localStorage.getItem("usertoken")).role === "tier2" ? (
          <NavLink to='/buyerfees' activeClassName='active'>
            <ListItem button>
              <span className='material-icons'>shop</span>
              Buyer Fees
            </ListItem>
          </NavLink>) : ""}
        {localStorage.userId && (
          <NavLink to='/profile' activeClassName='active'>
            <ListItem button>
              <span className='material-icons'>dashboard</span>
              My Farm Fayre
            </ListItem>
          </NavLink>
        )}

        <NavLink to='/howItWorks' activeClassName='active'>
          <ListItem button>
            <span className='material-icons'>help</span>
            How It Works
          </ListItem>
        </NavLink>

        <NavLink to='/refer-and-earn' activeClassName='active'>
          <ListItem button>
            <span className='material-icons'>paid</span>
            Refer & Earn
          </ListItem>
        </NavLink>

        <NavLink to='/support' activeClassName='active'>
          <ListItem button>
            <span className='material-icons'>support</span>
            Support
          </ListItem>
        </NavLink>

        {localStorage.userId && (
          <Link>
            <ListItem button onClick={handleLogout}>
              <span className='material-icons'>power_settings_new</span>
              Logout
            </ListItem>
          </Link>
        )}

        <div className='hstatic-wrap'>
          <NavLink to='/contact'>Contact</NavLink>
          <NavLink to='/aboutus'>About Us</NavLink>
          <NavLink to='/privacypolicy'>Privacy Policy</NavLink>
          <NavLink to='/terms'>Terms & Conditions</NavLink>
          <NavLink to='/faq'>FAQ's</NavLink>
          <a target="_blank" href="https://farmfayre.com/resources/"> Resources </a>
        </div>
      </div>
    </div>
  );


  useEffect(() => {
    if(!window.location.href.includes("localhost") && !window.location.href.includes("moblearn")) {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtm.js?id=G-Y9R8RJWEV8`; // Replace with your GTM ID
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        const dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-Y9R8RJWEV8'); // Replace with your GTM ID
      };

      return () => {
        document.body.removeChild(script);
      };
    }
   
  }, [window.location.href])

  return (
    <>
      {/* DESKTOP HEADER */}
      <header
        className={`ffHeader deskHeader d-flex justify-content-between align-items-center customContainer`}
      >
        <div className='ffLogoHead'>
          <Link to='/'>
            <img src='/svg/logo.svg' alt='Farm Fayre' height='50' />
          </Link>
        </div>

        <div className='ffnavLinks d-flex justify-content-start align-items-center'>
          {localStorage.getItem('userRole') == 'tier2' ||
          localStorage.getItem('userRole') == 'tier3' ||
          localStorage.getItem('userRole') == 'tier1' ? (
            <Link onClick={moveon} activeClassName='active'>
              {localStorage.getItem('userRole') == 'tier3'
                ? 'Post A Listing'
                : localStorage.getItem('userRole') == 'tier2'
                ? 'Post A Listing'
                : 'Register As Buyer/Seller'}
            </Link>
          ) : (
            ''
          )}
          <NavLink to='/search' activeClassName='active'>
            Search
          </NavLink>
          {localStorage.userId && (
            <NavLink to='/profile' activeClassName='active'>
              My Farm Fayre
            </NavLink>
          )}
          <NavLink to='/howItWorks' activeClassName='active'>
            How It Works
          </NavLink>
          <NavLink to='/refer-and-earn' activeClassName='active'>
             Refer & Earn
          </NavLink>
          <NavLink to='/support' activeClassName='active'>
            Support
          </NavLink>
          {localStorage.userId ? (
            <a className='noLink'>
              {/* {localStorage.userName} + {localStorage.userRole}  */}
              Hi {localStorage.userName}
            </a>
          ) : (
            <NavLink to='/login' activeClassName='active'>
              Login
            </NavLink>
          )}
          {localStorage.userId ? (
            <>
              <Button
                aria-controls='simple-menu'
                aria-haspopup='true'
                className='headerAvatar'
                onClick={handleClick}
              >
                <img
                  className='user-img'
                  src={
                    profData.image !== ''
                      ? AvatarURL + profData.image
                      : '/images/user-img.svg'
                  }
                  alt='profile.jpg'
                />
              </Button>

              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="main_pop"
                onClick={handleLogout}
              >
                <MenuItem onClick={handleLogout}>
                  <a
                    className='d-flex align-items-center noLink ffHeaderMenu'
                    style={{ cursor: 'pointer' }}
                    onClick={handleLogout}
                  >
                    <span
                      className='material-icons'
                      style={{ paddingRight: '10px' }}
                    >
                      power_settings_new
                    </span>
                    Logout
                  </a>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <PrimaryButton
              btnSize='smallBtn'
              label='Register'
              onsubmit={handleRegister}
            />
          )}
        </div>
      </header>

      {/* MOBILE / TAB HEADER */}
      <header
        className={`ffHeader respHeader d-flex justify-content-between align-items-center customContainer ${
          history.location.pathname == '/' ? null : 'inner-header'
        }`}
      >
        <div className='ffLogoHead'>
          <Link to='/'>
            <img src='/svg/logo.svg' alt='Farm Fayre' height='50' />
          </Link>
        </div>
        {console.log('content_web', content_web, content_mobile)}
        <React.Fragment key={'left'}>
          <Button onClick={toggleDrawer('left', true)}>
            <span className='material-icons'>menu</span>
          </Button>
          <SwipeableDrawer
            className='headerDrawer'
            anchor={'left'}
            open={state['left']}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            disableSwipeToOpen={false}
            onClose={toggleDrawer('left', false)}
            onOpen={toggleDrawer('left', true)}
          >
            {list('left')}
          </SwipeableDrawer>
        </React.Fragment>
      </header>

      <FFModal open={modalopen} handleClose={toggleModal} modaltitle='Message'>
        <div style={{fontWeight:'500'}}>
          <p className='card-text'>
            {localStorage.getItem('userRole') !== 'tier1'
              ? 'Please register as a Seller to post a listing.'
              : 'Are you sure Register As Buyer?'}
          </p>

          <div className='modalCTA d-flex justify-content-end align-items-center'>
            <Button onClick={toggleModal}>Cancel</Button>

            <PrimaryButton label={'Continue'} onsubmit={move} />
          </div>
        </div>
      </FFModal>

      <FFModalPopup
        customClassName='newsPopupHome'
        open={modalopen2}
        handleClose={toggleModal2}
        modaltitle=''
      >
        <div className='news_image' style={{fontWeight:'500'}}>
          {ReactHtmlParser(content_web)}
        </div>
        <div className='news_image_mobile'>
          {ReactHtmlParser(content_mobile)}
        </div>
      </FFModalPopup>
    </>
  );
};

export default Header;
