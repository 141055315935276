import axios from "axios";
import { isAuthenticated } from "./Authenticated";
import jwtDecode from 'jwt-decode'

export var test =""

export const request = async (
  method,
  url,
  data = {},
  id = false,
  userToken = true
) => {
  const token = isAuthenticated() ? isAuthenticated() : '';

  let user
  if (isAuthenticated()) {
    user = jwtDecode(isAuthenticated())
  }
  const checkToken =  userToken ?  `Bearer ${token}` : ''
  var headers = {
    Authorization: checkToken,
    domain: process.env.REACT_APP_FRONT_URL,
  };
  var urlId = "";
  var BASE_URL;
  if (url === "/add_new_creditcard" || url === '/get_all_creditcard' || url === '/zift_card_paymentapi' || url === '/viewAllCard' || url === '/payAmountStripe' || url ==="/deleteStripeCard" || url === '/placeOrderStripe' || url === '/validateCardStripe' || url === '/insertextracard') {
     BASE_URL = process.env.REACT_APP_BASE_URL + "/auctionpay";
  }
  else if(url==="/updateaddress" || url==="/depositaccept" || url==="/updatehauliertime" || url==="/getsellerwatchlist" || url==="/addsellerwatchlist" || 
          url==="/updateaccept" ||  url==="/trustap_pay" || url==="/codetobackend" || url==="/createlisting" || url==="/deleteaddress" || url==="/deletebank"  || 
          url==="/updateBankDetails" || url==="/addnewbank" || url === "/getprice" || url==="/user_images" || url==="/getBankDetails" || url==="/getherdnumber"  || 
          url==="/getAddressDetails" || url === "/getHaulierDetails" || url ==="/addnewaddress"  || url === '/getprodetails_multiple' || url === "/updatepickup" || 
          url ==="/updatedelivery" || url ==="/sellerpostprojectlivemultiple" || url ==="/referral_email" || url === "/placeOrderTrustap" || url === "/getAuctionDetails" || 
          url === "/confirm_handover" || url === "/getReferDetails" || url === "/getReviewDetails" || url === "/setReviews" || url === "/getUserDeposit" ||
          url === "/TrustapDeposit" || url === "/updateUserDeposit" || url === "/activateUser" || url === "/removesellerwatchlist" || url === "/mailchimp" || url === "/getHaulierPrice" || url === '/terms_check_update_farm' ||  url==="/bank_transfer" || url==="/image_upload"
          ){
     BASE_URL = process.env.REACT_APP_BASE_URL + "/farmfayre";
  }
   else {
     BASE_URL = process.env.REACT_APP_BASE_URL + "/mobileapi";
  }
  if (id) {
    urlId = url + user.id
  } else {
    urlId = url;
  }
  try {
    const res = await axios(
      { 
        method, 
        url: BASE_URL + urlId, 
        data, 
        headers,  
        maxContentLength: 1000 * 1024 * 1024, 
        maxBodyLength: 1000 * 1024 * 1024,  
        onUploadProgress: progressEvent => console.log('Upload Progress :'+Math.round(progressEvent.loaded / progressEvent.total * 100) + '%') 
      });
 /*     console.log(res); */
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const requestGet = async (
  url,
  data = {},
) => {
  try {
    const myUrlWithParams = new URL(url);

    for (const [key, value] of Object.entries(data)) {
      myUrlWithParams.searchParams.append(key, value);
    }
    console.log('requestGet',url,myUrlWithParams.href);
    const res = await axios.get(myUrlWithParams.href)
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};
