import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import ScrollToTop from '../../Router/ScrollToTop';

const useStyles = makeStyles((theme, maxWidth) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    width: '100%',
    maxWidth: '46rem'
  }
}));

const FFModal = ({ open, handleClose, children, modaltitle,noHeader }) => {
  const classes = useStyles();

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={open}
      className={classes.modal}
    >
      <div className={classes.paper}>
        {/*<ScrollToTop />*/}
        <div className="modal fade show custom-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              {noHeader ?"":
                <div className="modal-header">
                  <h4 className="modal-title">{modaltitle}</h4>
                  <button type="button" className="close" onClick={handleClose}>
                    <i className="fa fa-times"></i>
                  </button>
                </div>}
              <div className="modal-body cm-body">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FFModal;
