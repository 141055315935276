//Image URLs
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL + "/uploads/product/";  //Product Image
export const AvatarURL = process.env.REACT_APP_IMAGE_URL + "/uploads/"; //Avatar / profile images

export const callNumber=(num)=>{
   return new Intl.NumberFormat().format(num);
}
export const callNumber1=(num)=>{
  return new Intl.NumberFormat('en',{minimumFractionDigits:2}).format(num);
}
// export const callNumber=(num)=>{
//   return  new DecimalFormat().format(new Intl.NumberFormat().format(num)); }

export const NumberOnly = (e) => {
  console.log("+++++++++++++++",e.which)
    if ((e.which !== 8 && e.which !== 0 && e.which < 48 ) || e.which > 57)
      e.preventDefault()
}
export const Cd = (e) => {
  console.log("+++++++++++++++",e.which)
    if ((e.which !== 8 && e.which !== 0 && e.which < 48 && e.which !==47 ) || e.which > 57)
      e.preventDefault()
}
export const Phone = (e) => {
  console.log("+++++++++++++++",e.which)
    if ((e.which !== 8 && e.which !== 0 && e.which < 48 && e.which !==43 ) || e.which > 57)
      e.preventDefault()
}
export const TextOnly=(e)=>{
  console.log("+++++++++++++++++++++++++++k",e.which)
    if( (e.which <= 64 && e.which!==32) || e.which===91 || e.which===93 || e.which===92 || e.which===94 || e.which===95 || e.which===96 || e.which > 122)
    {
      e.preventDefault()
    }
}
export const dallow=(e)=>{
  e.preventDefault()
}
export const specialcharacter=(e)=>{
  console.log("++====================",e.which)
   if((e.which >= 33 && e.which <=47 && e.which!==35 && e.which!==45 && e.which!==47) || (e.which >= 58 && e.which <=64) || (e.which >= 91 && e.which <=96) || (e.which >= 123 && e.which <=126)){
     e.preventDefault()
   }
}
export const HerdNum=(e)=>{
  console.log("++====================",e.which)
   if((e.which >= 32 && e.which <=47) || (e.which >= 58 && e.which <=64) || (e.which >= 91 && e.which <=96) || (e.which >= 123 && e.which <=126)){
     e.preventDefault()
   }
}

export const Sentencecase=(val)=>{
   var a="";
   a=val.toString();
   var b=a.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g,function(c){return c.toUpperCase()});
   console.log("++====================",b)
   return b;
}

export const Section = ["Beef", "Dairy","Pedigree","Specialist"];
export const HeardFrom = ["Facebook", "Twitter", "Instagram", "Farmers Journal", "Haulier","Existing Member","Other"];
export const Type = [
  "Bull",
  "Bullock",
  "Calf",
  "Cow",
  "Heifer",
  "Suckler",
  "Weanling",
  "Specialist",
  "Cow & Calf",
];
export const Owners=["0","1","2","3","4",">5"]
export const Crossbreed=[
  "Angus",
  "Aubrac",
  "Belgian Blue",
  "Blonde D'Aquitaine",
  "Charolais",
  "Dexter",
  "Friesian - British",
  "Friesian - Holstein",
  "Hereford",
  "Jersey",
  "kerry",
  "Limousin",
  "Norwegian Red",
  "Parthenaise",
  "Piedmontese",
  "Meuse Rhine Issel",
  "Montbeliarde",
  "Salers",
  "Shorthorn",
  "Simmental",
  "Speckle Park",
];
export const Breed = [
  "Angus",
  "Aubrac",
  "Belgian Blue",
  "Blonde D'Aquitaine",
  "Charolais",
  "Dexter",
  "Friesian - British",
  "Friesian - Holstein",
  "Hereford",
  "Jersey",
  "kerry",
  "Limousin",
  "Norwegian Red",
  "Parthenaise",
  "Piedmontese",
  "Meuse Rhine Issel",
  "Montbeliarde",
  "Salers",
  "Shorthorn",
  "Simmental",
  "Speckle Park",
  "Other",
  "Crossbreed",
];
export const Weight = [
  "150 or less",
  "151 - 300",
  "301 - 450",
  "451 - 600",
  "601 - 750",
  "751 - 900",
  "901 or more",
];
export const Sex = ["Male", "Female"];
export const Age = [
  "< 6 Months",
  "7 - 12 Months",
  "13 - 18 Months",
  "19 -24 Months",
  "25 - 30 Months",
  " > 31 Months",
];

export const Provinces = [
  "South East",
  "Leinster North (Coming Soon)",
  "Munster  (Coming Soon)",
  "Connaught  (Coming Soon)",
  "Ulster  (Coming Soon)"
  
];
export const weimethod=[
  "Weighing Scales",
  "Guesstimate",
  "Weighing Tape"
]

export const salemethod=[
  "Weighing Method",
  "Not Sold on Weight"
]

export const NewCounties=[
  "Carlow",
  "Kilkenny",
  "Kildare",
  "Laois",
  "Wexford",
  "Wicklow",
  "Tipperary",
  "Waterford"
]

export const IrishCounties = [
  "Antrim",
  "Armagh",
  "Carlow",
  "Cavan",
  "Clare",
  "Cork",
  "Derry",
  "Donegal",
  "Down",
  "Dublin",
  "Fermanagh",
  "Galway",
  "Kerry",
  "Kildare",
  "Kilkenny",
  "Laois",
  "Leitrim",
  "Limerick",
  "Longford",
  "Louth",
  "Mayo",
  "Meath",
  "Monaghan",
  "Offaly",
  "Roscommon",
  "Sligo",
  "Tipperary",
  "Tyrone",
  "Waterford",
  "Westmeath",
  "Wexford",
  "Wicklow",
];

export const Countries = [
  "Ireland",
  "Austria",
  "Belgium",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Poland",
  "Portugal",
  "Romania",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
];


export const HaulierMatrix = [ // distance array length 7
[ // animalCount array length 11 
  [100.0, 100.0, 100.0, 100.0],  // averageWeight array length 4
  [50.0, 50.0, 50.0, 50.0],
  [33.0, 33.0, 33.0, 33.0],
  [25.0, 25.0, 25.0, 25.0],
  [20.0, 20.0, 20.0, 25.0],
  [17.0, 17.0, 20.0, 25.0],
  [14.0, 17.0, 20.0, 25.0],
  [14.0, 17.0, 20.0, 25.0],
  [14.0, 17.0, 20.0, 25.0],
  [14.0, 17.0, 20.0, 25.0],
  [14.0, 17.0, 20.0, 25.0]
],
[
  [225.0, 225.0, 225.0, 225.0],
  [112.5, 112.5, 112.5, 112.5],
  [75.0, 75.0, 75.0, 75.0],
  [56.3, 56.3, 56.3, 56.3],
  [45.0, 45.0, 45.0, 56.3],
  [37.5, 37.5, 45.0, 56.3],
  [32.1, 37.5, 45.0, 56.3],
  [32.1, 37.5, 45.0, 56.3],
  [32.1, 37.5, 45.0, 56.3],
  [32.1, 37.5, 45.0, 56.3],
  [32.1, 37.5, 45.0, 56.3]
],
[
  [275.0, 275.0, 275.0, 275.0],
  [137.5, 137.5, 137.5, 137.5],
  [91.7, 91.7, 91.7, 91.7],
  [68.8, 68.8, 68.8, 68.8],
  [55.0, 55.0, 55.0, 68.8],
  [45.8, 45.8, 55.0, 68.8],
  [39.3, 45.8, 55.0, 68.8],
  [39.3, 45.8, 55.0, 68.8],
  [39.3, 45.8, 55.0, 68.8],
  [39.3, 45.8, 55.0, 68.8],
  [39.3, 45.8, 55.0, 68.8]
],
[
  [325.0, 325.0, 325.0, 325.0],
  [162.5, 162.5, 162.5, 162.5],
  [108.3, 108.3, 108.3, 108.3],
  [81.3, 81.3, 81.3, 81.3],
  [65.0, 65.0, 65.0, 81.3],
  [54.2, 54.2, 65.0, 81.3],
  [46.4, 54.2, 65.0, 81.3],
  [46.4, 54.2, 65.0, 81.3],
  [46.4, 54.2, 65.0, 81.3],
  [46.4, 54.2, 65.0, 81.3],
  [46.4, 54.2, 65.0, 81.3]
],
[
  [375.0, 375.0, 375.0, 375.0],
  [187.5, 187.5, 187.5, 187.5],
  [125.0, 125.0, 125.0, 125.0],
  [93.8, 93.8, 93.8, 93.8],
  [75.0, 75.0, 75.0, 93.8],
  [62.5, 62.5, 75.0, 93.8],
  [53.6, 62.5, 75.0, 93.8],
  [53.6, 62.5, 75.0, 93.8],
  [53.6, 62.5, 75.0, 93.8],
  [53.6, 62.5, 75.0, 93.8],
  [53.6, 62.5, 75.0, 93.8]
],
[
  [425.0, 425.0, 425.0, 425.0],
  [212.5, 212.5, 212.5, 212.5],
  [141.7, 141.7, 141.7, 141.7],
  [106.3, 106.3, 106.3, 106.3],
  [85.0, 85.0, 85.0, 106.3],
  [70.8, 70.8, 85.0, 106.3],
  [60.7, 70.8, 85.0, 106.3],
  [60.7, 70.8, 85.0, 106.3],
  [60.7, 70.8, 85.0, 106.3],
  [60.7, 70.8, 85.0, 106.3],
  [60.7, 70.8, 85.0, 106.3]
]
]

export const getHaulierPrice = (distance, animalCount, averageWeight, type, loading)=>{

  if (loading){
    return type == 0 ? "Loading..." : type == 1 ? 0 : 0;
  }

  console.log('getHaulierPrice=========distance',distance,"==animalCount", animalCount,"==averageWeight", averageWeight,"==loading",loading)
  if (!distance || !animalCount || !averageWeight || animalCount < 0 || averageWeight < 0){
    return type == 0 ? "We don't have enough details to estimate haulage cost" : type == 1 ? 0 : 0;
  }
  var ratePerHead = 0;
  var distanceIndex = 0;
  var countIndex = 0;
  var weightIndex = 0;

  // distance ->  0-100, 100-150, 150-200, 200-250, 250-300, 300-350, 350-400
  if (distance < 0) {
    return type == 0 ? "We don't have enough details to estimate haulage cost" : type == 1 ? 0 : 0;
  } else if (0 <= distance && distance < 150) {
    distanceIndex = 0;
  } else if (150 <= distance && distance < 200) {
    distanceIndex = 1;
  } else if (200 <= distance && distance < 250) {
    distanceIndex = 2;
  } else if (250 <= distance && distance < 300) {
    distanceIndex = 3;
  } else if (300 <= distance && distance < 350) {
    distanceIndex = 4;
  } else if (350 <= distance && distance < 400) {
    distanceIndex = 5;
  } else {
    distanceIndex = 5;
  }

  // animalCount => 1,2,3,4,5,6,7,8,9,10,>10
  countIndex = animalCount <= 10 ? (animalCount - 1) : 10
    
  // averageWeight ->  0-300, 300-450, 450-600, >600
  if (parseFloat(averageWeight) < 300) {
    weightIndex = 0;
  } else if (300 <= parseFloat(averageWeight) && parseFloat(averageWeight) < 450) {
    weightIndex = 1;
  } else if (450 <= parseFloat(averageWeight) && parseFloat(averageWeight) < 600) {
    weightIndex = 2;
  } else if (600 <= parseFloat(averageWeight)) {
    weightIndex = 3;
  } 

  ratePerHead = HaulierMatrix[distanceIndex][countIndex][weightIndex];
  console.log('getHaulierPrice=========result',ratePerHead, animalCount)
  return type == 0 ? ("€" + parseFloat(ratePerHead/2).toFixed(2) + ' per head') : type == 1 ? parseFloat(ratePerHead * animalCount) : type == 2 ? (ratePerHead * animalCount) : parseFloat(ratePerHead * animalCount / 2);
}


